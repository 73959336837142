<template>
   <div class="card">
          <div class="card-content has-text-centered">
            <h1 class="title is-3">Eliminar documento</h1>
            <h2 class="subtitle is-5">¿Está seguro que desea eliminar {{getName}}?</h2>
            <p class="is-size-6 has-text-info" v-if="file"><i class="far fa-fw fa-lg" :class="getIcon"></i>&nbsp;{{file.nombre}}</p>
            <div class="block">
              <p class="has-text-danger animated flash" v-if="errorMessage">¡Error! {{errorMessage}}</p>
            </div>
          </div>
          <div class="card-footer">
            <a class="card-footer-item" type="button" @click="$parent.close()">Cancelar</a>
        <a class="card-footer-item has-text-danger has-text-weight-bold is-uppercase" @click.prevent="deleteDocumento">Eliminar</a>
          </div>
        </div>
</template>

<script>
export default {
  data () {
    return {
      errorMessage: null
    }
  },
  methods: {
    deleteDocumento: function () {
      this.errorMessage = null
      const fileType = this.file.tipo_documento_id
      this.$http
        .delete('/documento/' + this.file.id)
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'El archivo ha sido eliminado exitosamente.',
            type: 'is-success'
          })
          this.$parent.close()
          if (fileType === 'carpeta') {
            this.$emit('refreshFolders')
          } else {
            this.$emit('refreshDocuments')
          }
        })
        .catch(error => {
          this.errorMessage = error.mensaje
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error inesperado.',
            type: 'is-danger'
          })
        })
    }
  },
  computed: {
    getIcon: function () {
      if (!this.file) return 'fa-file'
      return (this.file.tipo_documento_id === 'carpeta' ? 'fa-folder' : 'fa-file')
    },
    getName: function () {
      if (!this.file) return 'documento'
      return (this.file.tipo_documento_id === 'carpeta' ? 'carpeta' : 'documento')
    },
    file () {
      return this.$store.state.repository.selectedDocument
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
