<template>
  <div class="mb-0 p-2 pr-5 has-shadow has-background-info is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center floating-actions-container">
    <div class="bg-overlay" v-if="isDropdownOpen"></div>
    <div class="has-text-white"><b>{{selectedDocumentsIndex.length}}</b> archivos seleccionados<a class="has-text-white ml-3" @click="unselectDocuments"><i class="fas fa-times-circle fa-fw"></i>&nbsp;Limpiar</a></div>
    <div v-if="selectedDocumentsIndex.length > 0">
    <b-dropdown aria-role="list" position="is-bottom-left" @active-change="(b) => isDropdownOpen = b">
      <a
        class="has-text-white has-text-weight-bold"
        slot="trigger"
        slot-scope="{ active }"
        role="button">
          Acciones&nbsp;&nbsp;<i class="fas" :class="{'fa-angle-down': !active, 'fa-angle-up': active }"></i>
      </a>
      <b-dropdown-item aria-role="listitem" v-if="everyIsSignable"><i class="fas fa-plus fa-fw fa-lg"></i>
      <i class="fas fa-inbox fa-fw"></i>&nbsp;Agregar para firmar</b-dropdown-item>
      <b-dropdown-item aria-role="listitem" @click="moveDocuments"> <i class="fas fa-dolly fa-fw fa-lg"></i>&nbsp;Mover</b-dropdown-item>
      <b-dropdown-item aria-role="listitem" @click="deleteDocuments"><i class="fas fa-trash fa-fw fa-lg"></i>&nbsp;Eliminar</b-dropdown-item>
    </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isDropdownOpen: false
    }
  },
  computed: {
    selectedDocuments () {
      return this.$store.state.repository.selectedDocuments
    },
    selectedDocumentsIndex () {
      return this.$store.state.repository.selectedDocumentsIndex
    },
    everyIsSignable () {
      return this.selectedDocuments.every(file => file.mime === 'application/pdf')
    }
  },
  methods: {
    moveDocuments () {
      this.$emit('move-multiple-documents')
    },
    deleteDocuments () {
      this.$emit('delete-multiple-documents')
    },
    unselectDocuments () {
      this.$emit('unselect-documents')
    }
  }
}
</script>

<style lang="scss" scoped>
.floating-actions-container{
  display: block;
  font-size: 0.8rem;
  // box-shadow: 0 -0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  // border-radius: 0.25rem;
  // box-shadow: 0 -0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  @media screen and (max-width: $desktop){
    position: fixed;
    width: 100%;
    z-index: 20;
    bottom: 0px;
    left: 0;
    box-shadow: none;
  }
}
.button-floater{
  display: block;
  position: fixed;
  z-index: 40;
  bottom: 15px;
  right: 15px;
  &.push-higher{
    bottom: 50px;
  }
}
.action-button-general {
  padding: 22px 5px;
  border-radius: 100px;
}
.bg-overlay{
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: #00000050;
  z-index: 20;
  left: 0;
  top: 0;
}
</style>
