
// Panel
import DemoVerificador from './DemoVerificador.vue'

const BASE_PATH = '/demo-verificador'

const routes = [
  {
    path: BASE_PATH,
    name: 'demo-verificador',
    component: DemoVerificador,
    props: true
  }
]
export default routes
