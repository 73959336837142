<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Subir documentos</p>
    </header>
    <section class="card-content">
      <div class="content">
        <p class="">Seleccione los documentos que desea cargar</p>
        <p class="is-size-7 has-text-primary " v-if="forceHerencia"><i class="fas fa-exclamation-triangle"></i>&nbsp;<b>Nota:</b> Los documentos heredarán por defecto los permisos de la carpeta actual</p>
      </div>
      <b-notification
        type="is-warning"
        :active.sync="isActive"
        @click="isActive = !isActive"
      >{{error}}</b-notification>
      <div class="columns">
        <div class="column" v-if="files.length == 0">
          <b-field position="is-centered">
            <b-upload v-model="files" drag-drop multiple expanded>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <i class="fas fa-copy fa-2x"></i>
                    <i class="fas fa-plus fa-2x"></i>
                  </p>
                  <p class="is-size-7">Arrastre uno o más archivos, o haga clic</p>
                </div>
              </section>
            </b-upload>
          </b-field>
        </div>
        <div class="column" v-if="files.length > 0">
          <table class="table is-narrow is-fullwidth">
            <thead>
              <th width="35" class="has-text-centered" v-if="!isUploading && !isDone">
                <b-tooltip label="Quitar de la lista" position="is-right">
                  <i class="fas fa-trash"></i>
                </b-tooltip>
              </th>
              <th>Nombre</th>
              <th width="65">
                <b-tooltip label="Heredar permisos de carpeta padre" position="is-left">
                  <i class="fas fa-users cog"></i><i class="fas fa-level-down-alt fa-fw"></i>
                </b-tooltip>
              </th>
              <th width="35">
                <b-tooltip label="Documento confidencial" position="is-left">
                  <i class="fas fa-lock fa-fw"></i>
                </b-tooltip>
              </th>
            </thead>
            <tbody>
              <tr v-for="(file,i) in files" :key="`file-${i}`">
                <td class="has-text-centered" v-if="!isUploading && !isDone">
                  <a @click="removeFromFiles(i)">
                    <i class="fas fa-times-circle"></i>
                  </a>
                </td>
                <td>
                  <p>
                    {{file.name}}
                    <b-tooltip v-if="filesSucceded.includes(file.name)"
                      type="is-success" label="Subido correctamente" position="is-right">
                      <span class="has-text-success">
                        &nbsp;<i class="fas fa-check-circle"></i>
                      </span>
                    </b-tooltip>
                    <b-tooltip v-if="filesFailed.includes(file.name)"
                      type="is-danger" :label="filesErrors[file.name]" position="is-right">
                      <span class="has-text-danger">
                        &nbsp;<i class="fas fa-times-circle"></i>
                      </span>
                    </b-tooltip>
                  </p>
                </td>
                <td class="has-text-centered">
                  <b-checkbox v-model="permisos_heredados" :native-value="file.name" :disabled="isUploading || isDone || forceHerencia"></b-checkbox>
                </td>
                <td>
                  <b-checkbox v-model="confidenciales" :native-value="file.name" :disabled="isUploading || isDone"></b-checkbox>
                </td>
              </tr>
              <tr v-if="files.length == 0">
                <td colspan="3">
                  <i>- No hay archivos para subir -</i>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="(files.length > 0) && !isUploading && !isDone">
            <b-field position="is-centered">
              <b-upload v-model="files" drag-drop multiple expanded>
                <section class="small-padding has-text-centered">
                  <p class="is-size-7">
                    <i class="fas fa-plus"></i>&nbsp;Arrastre más archivos o haga clic
                  </p>
                </section>
              </b-upload>
            </b-field>
          </div>
        </div>
      </div>
      <div v-if="canAsociarAspectos">
        <div class="field" v-if="!isUploading && !isDone">
          <b-checkbox v-model="habilitarAspectos" size="is-medium" :native-value="true">
            <label class="label">Definir aspectos iniciales</label>
          </b-checkbox>
        </div>
        <div class="field aspectos-border" v-if="habilitarAspectos && !isUploading && !isDone">
          <div v-for="aspecto in aspectosDisponibles" :key="aspecto.id">
            <b-checkbox
              v-model="aspectosSeleccionados"
              size="is-small"
              :native-value="aspecto.id"
            >{{aspecto.nombre}}</b-checkbox>
          </div>
        </div>
      </div>
    </section>
      <footer class="card-footer" v-if="!isUploading && !isDone">
        <a class="card-footer-item" type="button" @click="$parent.close()">Cancelar</a>
        <a class="card-footer-item has-text-weight-bold is-uppercase" @click.prevent="submit" :disabled="files.length == 0"><i class="fas fa-upload"></i>&nbsp;Cargar</a>
    </footer>
      <footer class="card-footer" v-else-if="!isUploading && isDone">
        <a class="card-footer-item" type="button" @click="$parent.close()">Cerrar</a>
    </footer>
      <footer class="card-footer" v-else-if="isUploading">
        <span class="card-footer-item animated flash slow" type="button"><i class="fas fa-sync fa-spin"></i>&nbsp;Subiendo archivos</span>
    </footer>
  </div>
</template>

<script>
import ModalReemplazarDocumentosExistentes from '@/components/repositorio/ModalReemplazarDocumentosExistentes'

export default {
  props: ['droppedFiles'],
  data () {
    return {
      files: [],
      isActive: false,
      error: '',
      permisos_heredados: [],
      // todos_heredan_permisos: true,
      confidenciales: [],
      isUploading: false,
      isDone: false,
      // -----------
      habilitarAspectos: false,
      fetchedAspectos: false,
      fetchingAspectos: false,
      aspectosSeleccionados: [],
      aspectosDisponibles: [],
      filesSucceded: [],
      filesFailed: [],
      filesErrors: {},
      existingFiles: []
    }
  },
  beforeMount: function () {
    if (this.droppedFiles) {
      // this.files = [...this.droppedFiles]
      this.droppedFiles.forEach(f => this.files.push(f))
    }
  },
  computed: {
    currentFolder: function () {
      return this.$store.state.repository.currentFolder
    },
    fileSelected: function () {
      return this.files && this.files.length
    },
    organizacion: function () {
      return this.$store.getters.getUser.organization
    },
    forceHerencia: function () {
      return this.currentFolder.permisos_bloqueados
    },
    canAsociarAspectos: function () {
      return this.$store.getters.hasAnyRole(['aspec-user', 'lyris'])
    }
  },
  methods: {
    fetchAspectos: function () {
      this.fetchingAspectos = true
      this.startLoading()
      Promise.all([
        this.$http.get('/aspecto'),
        this.$http.get(`/organizacion/${this.organizacion}/aspecto`)
      ])
        .then(responses => {
          this.stopLoading()
          this.fetchedAspectos = true
          this.prepareData(responses[0].data, responses[1].data)
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio con un error.',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    prepareData: function (aspectos, aspectosOrganizacion) {
      this.aspectosDisponibles = aspectos.concat(aspectosOrganizacion)
    },
    submit: function () {
      if (this.files.length === 0) return
      const dataArr = []
      this.files.map((f) => dataArr.push(this.createFormData(f)))
      this.isUploading = true
      this.send(dataArr.reverse(), 0, 0)
    },
    createFormData: function (file) {
      const data = new FormData()
      data.append('nombre', file.name)
      data.append('origen_id', this.currentFolder.id)
      data.append('tipo_documento_id', 'documento')
      data.append(
        'permisos_heredados',
        this.permisos_heredados.includes(file.name)
      )
      data.append('confidencial', this.confidenciales.includes(file.name))
      data.append('aspectos', JSON.stringify(this.aspectosSeleccionados))
      data.append('archivo', file)
      return data
    },
    send: function (dataArr, successCount, errorCount) {
      if (!dataArr.length) {
        this.isUploading = false
        this.isDone = true
        if (this.existingFiles.length) this.openReemplazarDocs()
        else {
          this.$buefy.snackbar.open({
            message: `Se han subido ${successCount} de ${this.files.length} documentos correctamente`,
            type: successCount === this.files.length ? 'is-success' : 'is-warning'
          })
          if (successCount === this.files.length) this.$parent.close()
        }
        this.$emit('refresh')
        return
      }
      const fileToSend = dataArr.pop()
      this.$http.request({
        method: 'post',
        url: '/documento-simple',
        data: fileToSend
      }).then((response) => {
        successCount++
        this.filesSucceded.push(fileToSend.get('nombre'))
        this.send(dataArr, successCount, errorCount)
      }).catch((err) => {
        console.error(err)
        errorCount++
        if (err.codigo === 'entityAlreadyExists') {
          this.existingFiles.push(fileToSend.get('nombre'))
        } else {
          this.filesErrors[fileToSend.get('nombre')] = err.mensaje ? err.mensaje : 'Error al subir el archivo'
          this.filesFailed.push(fileToSend.get('nombre'))
        }
        this.send(dataArr, successCount, errorCount)
      })
    },
    removeFromFiles: function (index) {
      const fileName = this.files[index].name
      this.files = this.files.filter((f, i) => {
        return f.name !== fileName
      })
      this.permisos_heredados = this.permisos_heredados.filter((f, i) => {
        return f !== fileName
      })
      this.confidenciales = this.confidenciales.filter((f, i) => {
        return f !== fileName
      })
    },
    openReemplazarDocs: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalReemplazarDocumentosExistentes,
        props: { existingFiles: this.existingFiles },
        canCancel: false,
        events: {
          replace: (names) => {
            this.existingFiles.filter(file => !names.includes(file)).forEach((file) => {
              this.filesFailed.push(file)
              this.filesErrors[file] = 'Ya existe un documento con el mismo nombre'
            })
            this.isUploading = true
            this.replaceDocs(names)
          },
          cancel: () => this.cancelReplace()
        }
      })
    },
    replaceDocs: function (names, toDelete = []) {
      if (!names.length) {
        this.deleteDocs(toDelete)
        return
      }
      const name = names.pop()
      this.$http
        .get(`/documento?origen_id=${this.currentFolder.id}&nombre=${name}`)
        .then(res => {
          const doc = res.data.data[0]
          toDelete.push({ id: doc.id, nombre: name })
          this.replaceDocs(names, toDelete)
        })
        .catch(err => {
          console.error(err)
          this.filesErrors[name] = err.mensaje ? err.mensaje : 'Error al obtener el documento'
          this.filesFailed.push(name)
          this.replaceDocs(names, toDelete)
        })
    },
    deleteDocs: function (toDelete, toSend = []) {
      if (!toDelete.length) {
        const dataArr = []
        toSend.map((f) => dataArr.push(this.createFormData(f)))
        this.existingFiles = []
        this.send(dataArr.reverse(), this.filesSucceded.length, 0)
        return
      }
      const doc = toDelete.pop()
      this.$http
        .delete('/documento/' + doc.id)
        .then(res => {
          toSend.push(this.files.filter(f => f.name === doc.nombre)[0])
          this.deleteDocs(toDelete, toSend)
        })
        .catch(err => {
          console.error(err)
          this.filesErrors[doc.nombre] = err.mensaje ? err.mensaje : 'Error al reemplazar el documento'
          this.filesFailed.push(doc.nombre)
          this.deleteDocs(toDelete, toSend)
        })
    },
    cancelReplace: function () {
      this.existingFiles.forEach((file) => {
        this.filesFailed.push(file)
        this.filesErrors[file] = 'Ya existe un documento con el mismo nombre'
      })
      this.existingFiles = []
      this.$buefy.snackbar.open({
        message: `Se han subido ${this.filesSucceded.length} de ${this.files.length} documentos correctamente`,
        type: 'is-warning'
      })
      this.$emit('refresh')
    }
  },
  watch: {
    files: function (oldVal, newVal) {
      if (!newVal) return
      this.permisos_heredados = []
      newVal.forEach((f, i) => {
        this.permisos_heredados.push(f.name)
      })
    },
    habilitarAspectos: function (val) {
      if (val === true && !this.fetchedAspectos) {
        this.fetchAspectos()
      }
    }
  }
}
</script>

<style lang="scss">
.upload .upload-draggable {
  width: 100%;
}
.small-padding {
  padding: 5px 10px;
}
.aspectos-border {
  max-height: 80px;
  overflow-y: scroll;
  border: 1px solid#CACACA;
  padding: 9px;
}
</style>
