<template>
  <section>
    <div class="bg-overlay" v-if="isDropdownOpen"></div>
    <!--<b-dropdown aria-role="list" class="is-hidden-desktop" @active-change="(b) => isDropdownOpen = b">
        <div
          class="plus-button mobile-position"
          slot="trigger"
          role="button">
          <div class="has-text-centered is-size-3">
            <i class="fas fa-plus has-text-white"></i>
          </div>
        </div>
        <b-dropdown-item aria-role="listitem" @click="uploadDocuments"><i class="far fa-copy fa-fw fa-lg"></i>&nbsp;Documentos</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="createFolder"><i class="far fa-folder fa-fw fa-lg"></i>&nbsp;Carpeta</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="createCompositeUnit" v-if="$store.getters.hasAnyRole(['lyris','serie-user'])"><i class="far fa-file-archive fa-fw fa-lg"></i>&nbsp;Unidad compuesta</b-dropdown-item>
      </b-dropdown> -->
    <div class="button-floater animated bounceIn" :class="{'push-higher': selectedDocumentsIndex.length}">
    <b-dropdown aria-role="list" :mobile-modal="false" position="is-top-left" @active-change="(b) => isDropdownOpen = b" class="pt-2">
        <div
          class="plus-button"
          slot="trigger"
          role="button">
          <div class="has-text-centered is-size-3">
            <i class="fas fa-plus has-text-white"></i>
          </div>
        </div>
        <b-dropdown-item aria-role="listitem" @click="uploadDocuments"><i class="far fa-copy fa-fw fa-lg"></i>&nbsp;Documentos</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="createFolder"><i class="far fa-folder fa-fw fa-lg"></i>&nbsp;Carpeta</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="createCompositeUnit" v-if="$store.getters.hasAnyRole(['lyris','serie-user'])"><i class="far fa-file-archive fa-fw fa-lg"></i>&nbsp;Unidad compuesta</b-dropdown-item>
      </b-dropdown>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      isDropdownOpen: false
    }
  },
  methods: {
    uploadDocuments: function () {
      this.$emit('upload-documents')
    },
    createFolder: function () {
      this.$emit('create-folder')
    },
    createCompositeUnit: function () {
      this.$emit('create-composite-unit')
    }
  },
  computed: {
    selectedDocumentsIndex () {
      return this.$store.state.repository.selectedDocumentsIndex
    }
  }
}
</script>

<style lang="scss" scoped>
// .floating-actions-container{
//   @media screen and (max-width: $desktop){
//     //
//   }
// }
.button-floater{
  display: block;
  position: fixed;
  z-index: 15;
  bottom: 15px;
  right: 15px;
  &.push-higher{
    @media screen and (max-width: $desktop){
      //
      bottom: 50px;
    }
  }
}
.plus-button {
  // padding: 20px 10px;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-content: middle;
  justify-content: center;
  border-radius: 50%;
  background-color: $primary;
  &.mobile-position{
    position: fixed;
    z-index: 40;
    bottom: 15px;
    right: 15px;
  }
  &:hover{
    background-color: $info;
    cursor: pointer;
  }
}
.bg-overlay{
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: #000000a1;
  z-index: 10;
  left: 0;
  top: 0;
}
</style>
