<template>
  <div class="modal-card">
    <div class="modal-card-body has-text-centered">
        <img src="/statics/signar-logo.svg" class="image is-centered my-4" v-if="firmadorSelected == 'signar'" width="100" alt>
        <img src="/statics/pfdrar-logo.png" class="image is-centered my-4"  v-else-if="firmadorSelected == 'pfdrar'" width="100" alt>
      <h1 class="title is-4">Abriendo firmador</h1>
      <div class="notification" v-if="isLoading">
        <p class="animated flash infinite slower has-text-primary"><i class="fas fa-spin fa-sync"></i> Conectando con el servidor, un momento...</p>
      </div>
      <div class="content" v-if="firmadorSelected == 'signar'">
        <p>Si el firmador
          <u>NO</u> se ejecutó, verifique si su navegador está bloqueando los pop-ups, deshabilitelos y <a @click="retrySignar(urlSignar)"><u>haga click aquí</u></a> para volver a intentar
        </p>
        <p>
          <b>Recomendamos habilitar los pop-ups en Signar®</b>
        </p>
        <p>¿No cuenta con Signar® Firmador?<br><a href="https://firmador.lyris.com.ar" target="_blank"><i class="fas fa-download"></i>&nbsp;Descárguelo aquí</a></p>
        <p>¿Está ingresando desde un dispositivo Android?<br><a href="https://play.google.com/store/apps/details?id=ar.com.helenasoftware.signar" target="_blank"><i class="fas fa-download"></i>&nbsp;Descargue la Aplicación y vuelva a intentar</a></p>
        <p class="is-size-7">Si desea, puede utilizar al versión Legacy (JNLP) <a @click="preparePilaJNLP">haciendo clic aquí</a></p>
      </div>
      <div class="content" v-else>
        <div class="notification is-warning">
          El firmador no se encuentra disponible para su uso
        </div>
        <p>Si el firmador
          <u>NO</u> se ejecutó, verifique si su navegador está bloqueando los pop-ups
        </p>
        <p>
          <b>Recomendamos habilitar los pop-ups en Signar®</b>
        </p>
      </div>
        <div class="buttons is-centered">
          <a @click="$parent.close()" class="button is-dark">
            Cerrar
          </a>
          <a @click="reloadUrl" class="button is-primary">
            <i class="fas fa-redo"></i>&nbsp;Recargar pagina
          </a>
        </div>
    </div>
  </div>
    <!-- <div class="modal-card-body" v-if="!noDocs">
      <div class="has-text-centered" v-if="!firmadorSelected">
        <h1 class="subtitle is-5">Paso 1</h1>
        <h1 class="title is-4 animated flash">Seleccione el firmador</h1>
        <div class="columns">
          <div class="column has-text-centered" v-if="user.firmadores_habilitados.signar">
              <img src="/statics/signar-logo.svg" @click="preparePilaSignar" class="image firmador-select" alt>
          </div>
          <div class="column has-text-centered" v-if="user.firmadores_habilitados.pfdrar">
             <b-tooltip label="No disponible" position="is-bottom">
              <img src="/statics/pfdrar-logo.png" @click="preparePilaPfdrar" class="image firmador-select" alt>
             </b-tooltip>
          </div>
        </div>
      </div>
      <div class="has-text-centered" v-else>
        <h1 class="subtitle is-5">Paso 2</h1>
        <h1 class="title is-3 animated flash">Abriendo firmador</h1>
        <h1 class="subtitle is-5" v-if="firmadorSelected === 'signar'">Signar ®</h1>
        <h1 class="subtitle is-6" v-if="firmadorSelected === 'pfdrar'">Plataforma de Firma Digital Remota - Secretaria de Modernización - Argentina</h1>
          <img src="/statics/signar-logo.svg" v-if="firmadorSelected === 'signar'" class="image" alt="Signar Firmador - By Lyris">
          <img src="/statics/pfdrar-logo.png" v-if="firmadorSelected === 'pfdrar'" class="image" alt="Plataforma de firma digital remota - Argentina">
        <div class="content">s
          <div v-if="firmadorSelected === 'signar'">
            <p class="has-text-info">NOTA: Si el firmador
              <u>NO</u> se ejecutó, verifique si su navegador está bloqueando los pop-ups y <a @click="retrySignar(urlSignar)"><u>haga click aquí</u></a>
              <br>Recomendamos habilitar los pop-ups para esta página.
            </p>
            <hr>
            <p>¿No cuenta con Signar® Firmador?&nbsp;&nbsp;&nbsp;<a href="https://firmador.lyris.com.ar" target="_blank"><i class="fas fa-download"></i>&nbsp;Descárguelo aquí</a></p>
            <p class="is-size-7">Si desea, puede utilizar al versión legacy (JNLP) <a @click="preparePilaJNLP">haciendo clic aquí</a></p>
          </div>
          <div v-if="firmadorSelected === 'pfdrar'">
            <p class="has-text-info">NOTA: El firmador no se encuentra disponible para su uso
            </p>
          </div>
        </div>
        <a @click="$parent.close()" class="button is-light">
          <i class="fas fa-times fa-fw"></i>&nbsp;Cerrar
        </a>
      </div>
      <div class="has-text-centered" v-if="firmadorSelected && postergados">
        <h1 class="title has-text-primary is-5"><i class="fas fa-exclamation-triangle"></i>&nbsp;Existen documentos pendientes por firmar</h1>
        <p class="subtitle is-6">Para firmar los documentos pendientes, haga clic en "Continuar"</p>
        <p>
          <a @click="continuarPostergados" class="button is-info">
            Continuar&nbsp;<i class="fas arrow-right"></i>
          </a>
        </p>
      </div>
    </div>
    <div class="modal-card-body" v-else>
       <h1 class="title is-3">La pila de documentos está vacia</h1>
        <h1 class="subtitle is-6">Para poder firmar debe haber documentos en pila</h1>
        <a @click="$parent.close()" class="button is-light">
          <i class="fas fa-times fa-fw"></i>&nbsp;Cerrar
        </a>
    </div>
  </div> -->
</template>

<script>
import fieldsUtils from '@/mixins/fieldsUtils'
export default {
  mixins: [fieldsUtils],
  data () {
    return {
      isLoading: true,
      payloadClone: null
      // firmadorSelected: null,
      // showTable: false,
      // noDocs: false,
      // postergados: false,
      // urlJnlp: null,
      // urlSignar: null,
      // payloadClone: null
    }
  },
  mounted: function () {
    switch (this.firmadorSelected) {
      case 'signar':
        this.preparePilaSignar()
        break
      default:
        this.preparePilaGenerico()
        break
    }
    // if (this.docStack.length === 0 && this.isObjectEmpty(this.firmadorStore.payload)) this.noDocs = true
    // if (this.user.firmadores_habilitados.signar && !this.user.firmadores_habilitados.pfdrar) {
    //   // this.submitPila('signar')
    //   this.preparePilaSignar()
    // } else if (!this.user.firmadores_habilitados.signar && this.user.firmadores_habilitados.pfdrar) {
    //   // this.submitPila('pfdrar')
    //   this.preparePilaPfdrar()
    // }
  },
  beforeDestroy: function () {
    this.$store.dispatch('firmador/unload')
  },
  methods: {
    reloadUrl: function () {
      location.reload()
    },
    retrySignar: function (url) {
      window.open(url, '_self')
    },
    preparePilaSignar: function () {
      this.isLoading = true
      const payload = Object.assign({}, this.firmadorStore.payload)
      this.payloadClone = payload
      this.$http.post('/solicitud-firma', payload)
        .then(res => {
          this.urlSignar = res.data.link
          this.$store.dispatch('firmador/unloadPila')
          window.open(res.data.link, '_self')
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Ocurrió un error, por favor intentelo mas tarde',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    preparePilaJNLP: function () {
      this.isLoading = true
      const jnlpPayload = {
        opcion: this.payloadClone.opcion,
        firmador: this.payloadClone.firmador
      }
      if (this.payloadClone.opcion !== 'recibos') {
        jnlpPayload.lista = this.payloadClone.parametros.documentos
      }
      this.$http.post('usuario/' + this.$store.getters.getUser.id + '/pila-documentos', jnlpPayload)
        .then((res) => {
          this.urlJnlp = location.href.replace(
            window.location,
            `/jnlp/firmador?token=${res.data.token}`
          )
        })
        .then(() => {
          window.open(this.urlJnlp, '_self')
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Ocurrió un error, por favor intentelo mas tarde',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    preparePilaGenerico: function () {
      this.isLoading = false
      // const payload = Object.assign({}, this.firmadorStore.payload)
      // payload.firmador = 'pfdrar'
      // this.payloadClone = payload
      // this.$http.post('usuario/' + this.$store.getters.getUser.id + '/pila-documentos', payload)
      //   .then((res) => {
      //     if (res.data.pila_postergada && res.data.pila_postergada.length > 0) {
      //       this.$store.commit('firmador/setPila', res.data.pila_postergada)
      //       payload.lista = res.data.pila_postergada
      //       this.postergados = true
      //     } else {
      //       this.$store.dispatch('firmador/unloadPila')
      //     }
      //     window.open(res.data.link, '_self')
      //   })
    },
    continuarPostergados: function () {
      const payload = this.firmadorStore.payload
      payload.parametros.documentos = this.docStack.map(doc => doc.id)
      delete payload.firmador
      this.$store.commit('firmador/setPayload', payload)
      this.firmadorSelected = null
      this.postergados = false
    }
    // submitPila: function (firmador) {
    //   this.firmadorSelected = firmador
    //   const payload = Object.assign({}, this.firmadorStore.payload)
    //   payload.firmador = firmador
    //   this.payloadClone = payload
    //   this.$http.post('/solicitud-firma', payload)
    //     .then(res => {
    //       switch (firmador) {
    //         case 'pfdrar':
    //           if (res.data.pila_postergada && res.data.pila_postergada.length > 0) {
    //             this.$store.commit('firmador/setPila', res.data.pila_postergada)
    //             payload.lista = res.data.pila_postergada
    //             this.postergados = true
    //             // setTimeout(() => {
    //             //   this.$eventHub.$emit('hidePila')
    //             // }, 1000)
    //           } else {
    //             // this.$store.commit('firmador/cleanPila')
    //             this.$store.dispatch('firmador/unloadPila')
    //           }
    //           this.firmarPfdrar(res.data.link)
    //           break
    //         default: // signar
    //           this.urlSignar = res.data.link
    //           // this.firmarDocumentosSignar(res.data.token, res.data.url, res.data.usr)
    //           // this.$store.commit('firmador/cleanPila')
    //           this.$store.dispatch('firmador/unloadPila')
    //           this.firmarSignar(res.data.link)
    //           break
    //       }
    //     })
    //     .catch(err => {
    //       console.error(err)
    //       this.$buefy.snackbar.open({
    //         message: 'Error. No se pudo comunicar con el servidor de archivos',
    //         type: 'is-danger'
    //       })
    //     })
    // },
    // submitPilaJnlp: function () {
    //   this.$http.post('usuario/' + this.$store.getters.getUser.id + '/pila-documentos', this.payloadClone)
    //     .then((res) => {
    //       if (this.payloadClone.opcion === 'recibos') {
    //         this.urlJnlp = location.href.replace(
    //           window.location,
    //           `/jnlp/recibos?token=${res.data.token}`
    //         )
    //       } else {
    //         this.urlJnlp = location.href.replace(
    //           window.location,
    //           `/jnlp/firmador?token=${res.data.token}`
    //         )
    //       }
    //     }).then(() => {
    //       window.open(this.urlJnlp, '_self')
    //     })
    // },
    // firmarDocumentosSignar: function (token, url, id) {
    //   this.urlJnlp = location.href.replace(
    //     window.location,
    //     '/jnlp/firmador?token=' + token
    //   )
    //   // this.urlProtocol = `signar://${token};${url};${id};firmador`
    //   // this.$store.commit('cleanPila')
    //   // this.$store.commit('cleanPilaDocumentoPayload')
    //   // // deployJava.launchEx(url);
    //   // window.open(this.urlProtocol, '_self')
    // },
    // firmarRecibosSignar: function (token) {
    //   this.urlJnlp = location.href.replace(
    //     window.location,
    //     '/jnlp/recibos?token=' + token
    //   )
    //   // this.$store.commit('cleanPila');
    //   // this.$store.commit('cleanPilaDocumentoPayload');
    //   // window.open(url);
    // },
    // firmarPfdrar: function (url) {
    //   window.open(url, '_self')
    // },
    // continuarPostergados: function () {
    //   const payload = this.firmadorStore.payload
    //   payload.parametros.documentos = this.docStack.map(doc => doc.id)
    //   delete payload.firmador
    //   this.$store.commit('firmador/setPayload', payload)
    //   this.firmadorSelected = null
    //   this.postergados = false
    // },
    // goBack: function () {
    //   if (this.firmadorStore.goBack != null) {
    //     this.$router.push(this.firmadorStore.goBack)
    //   } else {
    //     this.$router.push('/')
    //   }
    // }
  },
  computed: {
    session () {
      return this.$store.state.session
    },
    user: function () {
      return this.$store.getters.getUser
    },
    angle: function () {
      return this.showTable ? 'fa-angle-up' : 'fa-angle-down'
    },
    firmadorStore: function () {
      return this.$store.state.firmador
    },
    firmadorSelected: function () {
      return this.$store.state.firmador.payload.firmador
    },
    docStack: function () {
      return this.firmadorStore.pila
    },
    currentUrl: function () {
      return window.location
    }
  }
}
</script>

<style lang="scss" scoped>
// img.image {
//   width: 170px;
//   margin: 0 auto 20px;
// }
.firmador-select{
  cursor: pointer;
  padding: 20px 30px;
  border-radius: 5px;
  border: 1px solid #cacaca;
}
</style>
