<template>
  <div>
    <RouteBreadcrumb :routes="rutas" :ends-file="true"/>
    <acciones @descargar="descargar" @recargar="updateDocument" @deleteDocument="openDeleteDocument" @renameDocument="openRenameDocument" @moveDocument="openMoveDocument" @addpila="addToPila" @preview="openMoveDocument" @side-by-side="toggleSideBySide" @print="printFile"></acciones>
    <section class="section">
      <div class="">
        <div class="columns">
          <div class="column">
            <div class="box">
             <h1 class="title document-title is-3 is-size-4">
          <i class="far fa-file"></i>&nbsp;&nbsp;{{documento.nombre.length > 50 ? documento.nombre.substring(0,50) + '...' : documento.nombre}}</h1>
              <informacion
              @open-asignar-aspecto="openAsignarAspecto"
              :can-asignar-aspectos="$store.getters.hasAnyRole(['aspec-user','lyris']) && nivelPermisoUser >= 6"
              :can-delete-aspectos="$store.getters.hasAnyRole(['aspec-user','lyris']) && nivelPermisoUser >= 6"
              @quitar-aspecto="quitarAspecto"
              />
            </div>
            <div class="box">
              <h1 class="subtitle is-5 has-text-primary">
                <i class="fas fa-info-circle"></i>&nbsp;Metadatos</h1>
                <metadatos
                  v-for="aspecto in aspectosDocumento" :key="`asp-meta-${aspecto.id}`"
                  :force-edit="false"
                  :hide-save-button="false"
                  :metadatos="documento.lista_metadatos"
                  :aspecto="aspecto"
                  :read-only="nivelPermisoUser < 4"
                  :can-edit="$store.getters.hasAnyRole(['aspec-user','lyris']) && nivelPermisoUser >= 4"
                  @submit="saveEditMetadatos"
                />
            </div>
            <!-- <div class="box" v-if="$store.getters.hasAnyRole(['aspec-user','lyris'])">
              <aspectos :id="documento.id" @updateDocument="updateDocument"></aspectos>
            </div>
            <div class="box">
              <metadatos :id="documento.id" @updateDocument="updateDocument"></metadatos>
            </div> -->
            <div class="box">
              <h1 class="subtitle is-5 has-text-primary">
                <i class="fas fa-signature"></i>&nbsp;Firmas</h1>
              <firmas></firmas>
            </div>
            <div class="box mb-0">
              <h1 class="subtitle is-5 has-text-primary">
                <i class="fas fa-users-cog"></i>&nbsp;Permisos</h1>
            </div>
              <permisos @updateDocument="updateDocument" :documento="documento" :nivel-permiso-user="nivelPermisoUser"></permisos>
          </div>
          <div class="column is-3 " v-if="!openSideBySide">
            <div class="box is-paddingless box-preview-document">
              <a class="overlay-button-side-by-side button is-primary is-radiusless p-2 is-hidden-touch" @click="toggleSideBySide"><i class="fas fa-columns"></i></a>
            <figure class="image">
              <a @click="previewModalActive = true" v-if="documento.preview_uri">
                <img :src="documento.preview_uri" alt="Previsualización" />
              </a>
              <img src="/statics/default-document.png" v-else alt="Previsualización">
            </figure>
            </div>
            <subir-version :documento-id="documento.id" v-if="isVersionable" @updateDocument="updateDocument"></subir-version>
          </div>
          <div class="column is-6 pl-0" v-else>
            <div class="is-flex file-side-to-side">
              <a @click="toggleSideBySide"><b-tooltip label="Cerrar" position="is-left"><i class="fas fa-lg fa-angle-double-right"></i></b-tooltip></a>
               <iframe :src="urlPreviewer" frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
    <b-modal :active.sync="previewModalActive">
      <figure class="image">
        <img :src="documento.preview_uri" alt="Previsualización" />
      </figure>
    </b-modal>
  </div>
</template>

<script>
import print from 'print-js'
import Acciones from '@/components/documento/Acciones'
import SubirVersion from '@/components/documento/SubirVersion'
import Informacion from '@/components/documento/Informacion'
// import Aspectos from '@/components/documento/Aspectos'
// import Metadatos from '@/components/documento/Metadatos'
import Firmas from '@/components/documento/Firmas'
// import Permisos from '@/components/documento/Permisos'
import Permisos from '@/components/globals/permisos/Permisos'
import RouteBreadcrumb from '@/components/utils/RouteBreadcrumb'
import ModalMoverDocumento from '@/components/documento/ModalMoverDocumento'
import ModalEliminarDocumento from '@/components/documento/ModalEliminarDocumento'
import ModalRenombrarDocumento from '@/components/documento/ModalRenombrarDocumento'
import ModalAsignarAspecto from '@/components/globals/aspectos/ModalAsignar'
import Metadatos from '@/components/globals/aspectos/Metadatos'
import permisosUtils from '@/mixins/permisosUtils'
import store from '@/store'
// import http from '@/http'

export default {
  mixins: [permisosUtils],
  props: ['id'],
  components: {
    Acciones,
    SubirVersion,
    Informacion,
    // Aspectos,
    // Metadatos,
    Metadatos,
    Firmas,
    Permisos,
    RouteBreadcrumb
  },
  data () {
    return {
      previewModalActive: false,
      openSideBySide: false
    }
  },
  beforeRouteUpdate: (to, from, next) => {
    // Save the next function so its visible for the promise..
    // by default this is pased to the promise.
    if (to.params.id === from.params.id) {
      console.info('[ROUTE] No need to fetch data, already fetched')
      next()
    } else {
      console.log('[ROUTE] Updated route')
      store.dispatch('document/fetchDocument', to.params.id)
        .then(response => {
          console.info('[ROUTER] Data fetch success. Continue to route')
          this.next()
        })
        .catch(error => {
          console.error('[ROUTER] Data fetch failed', error)
          this.next(false)
        })
    }
  },
  beforeRouteLeave (to, from, next) {
    store.dispatch('document/unload')
    next()
  },
  methods: {
    updateDocument: function () {
      this.startLoading()
      this.$store.dispatch('document/fetchDocument', this.id)
        .catch(err => {
          if (err.estado === 404) {
            this.$buefy.snackbar.open({
              message: 'El documento no existe o recurso no se encuentra disponible',
              type: 'is-warning'
            })
            console.info('[VUEX] fetchDocument returned error. Back to origin.')
            this.$router.push({ name: '404' })
          }
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    descargar: function () {
      this.$http
        .get(`/documento/${this.documento.id}/descarga`)
        .then(response => {
          window.location.href = response.data.link
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message:
              'Error de conexión. Compruebe la conectividad con internet.',
            type: 'is-danger'
          })
        })
    },
    openDeleteDocument: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalEliminarDocumento,
        events: {
          refresh: () => {
            this.$router.push({ name: 'repositorio', params: { idFolder: this.documento.origen_id } })
          }
        }
      })
    },
    openRenameDocument: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalRenombrarDocumento,
        width: 600,
        events: {
          refresh: () => {
            this.updateDocument()
          }
        }
      })
    },
    openMoveDocument: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalMoverDocumento,
        width: 720,
        props: {
          slugRoot: this.rutas && this.rutas[0]
        },
        events: {
          refresh: () => {
            this.updateDocument()
          }
        }
      })
    },
    openAsignarAspecto: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalAsignarAspecto,
        width: 720,
        props: {
          aspectos: this.todosLosAspectos,
          aspectosDocumentos: this.documento.aspectos
        },
        events: {
          'save-without-metadatos': (aspectoId) => this.saveWithoutMetadatos(aspectoId),
          'save-with-metadatos': (aspectoId, metadatos) => this.saveWithMetadatos(aspectoId, metadatos)
        }
      })
    },
    saveWithoutMetadatos: function (aspectoId) {
      this.startLoading()
      this.$http
        .post(`/documento/${this.documento.id}/aspecto/${aspectoId}`)
        .then(response => {
          this.updateDocument()
          this.$buefy.snackbar.open({
            message: 'El aspecto ha sido asignado correctamente',
            type: 'is-success'
          })
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio un error al asociar el aspecto',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    saveWithMetadatos: function (aspectoId, metadatos) {
      this.startLoading()
      this.$http
        .post(`/documento/${this.documento.id}/aspecto/${aspectoId}`)
        .then(responseOne => this.$http.post(`/documento/${this.documento.id}/metadato`, metadatos))
        .then(responseTwo => {
          this.updateDocument()
          this.$buefy.snackbar.open({
            message: 'El aspecto ha sido asignado correctamente',
            type: 'is-success'
          })
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio un error al asociar el aspecto',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    saveEditMetadatos: function (metadatos) {
      this.startLoading()
      this.$http.post(`/documento/${this.documento.id}/metadato`, metadatos)
        .then(response => {
          this.updateDocument()
          this.$buefy.snackbar.open({
            message: 'El aspecto ha sido asignado correctamente',
            type: 'is-success'
          })
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio un error al asociar el aspecto',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    quitarAspecto: function (resource) {
      this.startLoading()
      this.$http.delete(`/documento/${this.documento.id}/aspecto/${resource.id}`)
        .then(res => {
          this.updateDocument()
          this.$buefy.snackbar.open({
            message: 'El aspecto se ha quitado exitosamente',
            type: 'is-success'
          })
        }).catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio un error al quitar el aspecto',
            type: 'is-danger'
          })
        }).finally(() => {
          this.stopLoading()
        })
    },
    addToPila: function () {
      this.$store.commit('firmador/addPila', this.documento)
    },
    toggleSideBySide: function () {
      if (this.openSideBySide) {
        this.openSideBySide = false
      } else {
        if (this.urlPreviewer) {
          this.openSideBySide = true
        } else {
          this.$http
            .get(`/documento/${this.documento.id}/descarga`)
            .then(response => {
              this.urlPreviewer = `${response.data.link}?inline=true`
              this.openSideBySide = true
            })
            .catch(error => {
              console.error('Fail', error)
              this.$buefy.snackbar.open({
                message:
                  'Error de conexión. Compruebe la conectividad con internet.',
                type: 'is-danger'
              })
            })
        }
      }
    },
    printFile: function () {
      this.$http
        .get(`/documento/${this.documento.id}/descarga`)
        .then(response => {
          this.urlPreviewer = `${response.data.link}?inline=true`
          print({
            printable: response.data.link,
            type: 'pdf',
            documentTitle: this.documento.nombre,
            onError: () => {
              this.$buefy.snackbar.open({
                message:
              'Hubo un error al intentar imprimir el archivo.<br>Por favor, <b>intente descargar <i class="fas fa-download"></i> el archivo e imprimalo</b>.',
                type: 'is-danger',
                duration: 15000
              })
            }
          })
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message:
              'Error de conexión. Compruebe la conectividad con internet.',
            type: 'is-danger'
          })
        })
    }
  },
  computed: {
    documento: function () {
      return this.$store.state.document.document
    },
    user: function () {
      return this.$store.getters.getUser
    },
    nivelPermisoUser: function () {
      if (this.$store.getters.hasAnyRole(['lyris'])) return 9
      return this.getNivelPermisoUser(this.documento.permisos, this.user)
    },
    isVersionable: function () {
      return (this.documento.tipo_documento_id !== 'recibo' && this.documento.tipo_documento_id !== 'otroDocumento')
    },
    rutas: function () {
      if (!this.documento) return []
      return this.documento.ruta.split('/')
    },
    todosLosAspectos: function () {
      return this.$store.getters['organizacion/allAspectos']
    },
    aspectosDocumento: function () {
      if (!this.documento) return []
      return this.documento.aspectos
    }
  }
}
</script>

<style lang="scss" scoped>
figure {
  margin-bottom: 1rem;
}
.document-title {
  word-break: break-all;
}
.file-side-to-side{
   position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  height:  100%;
  max-height: 100vh;
  width: 100%;
  iframe {
    width: 100%;
  }
  i {
    margin-right: 7px;
    margin-top: 8px;
  }
}
.box-preview-document{
  position: relative;
  .overlay-button-side-by-side{
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 10;
    transition: opacity 0.2s;
    opacity: 0.4;
    line-height: normal;
    border-bottom-left-radius: 7px !important;
    height: auto;
    &:hover{
      opacity: 1;
    }
  }
}
</style>
