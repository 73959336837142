<template>
    <div class="modal-card">
        <div class="modal-card-head">
          <p class="modal-card-title">Subir documentos a un empleado</p>
        </div>
        <div class="modal-card-body">
          <div class="field">
            <label for="" class="label">Seleccionar un usuario</label>
            <div class="control">
              <input class="input" ref="input" v-model="userInput" type="text" placeholder="Escriba aquí">
              <span class="help">Escriba el nombre o apellido del usuario</span>
              <span class="help">Sólo se puede seleccionar usuarios con rol Usuario de Otros Documentos</span>
            </div>
          </div>
          <div class="content">
            <p>{{status}}</p>
            <table class="table is-bordered is-narrow is-marginless" v-show="searchResult.length > 0">
              <tbody>
                <tr v-for="result in searchResult" :key="result.index">
                  <td>
                    <i class="fas fa-user"></i>&nbsp;
                    <a @click="selectUser(result)">{{result.nombre}} {{result.apellido}}</a>
                    <span class="disabled" v-if="tieneRolLyris">organizacion_id: {{result.organizacion_id}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-card-body" v-if="selectedUser">
          <label class="label">Usuario seleccionado</label>
          <b-field grouped group-multiline>
            <div class="control">
                <b-tag type="is-primary"
                    attached
                    aria-close-label="Close tag"
                    closable
                    @close="selectedUser = null">
                    {{ selectedUser.apellido.toUpperCase() }}, {{ selectedUser.nombre }}
                </b-tag>
            </div>
          </b-field>
        </div>
        <div class="modal-card-foot is-justify-content-flex-end">
          <button @click="$parent.close()" class="button is-outlined is-dark">
            Cancelar</button>
          <button @click="submit()" :disabled="selectedUser == null" class="button is-primary">Continuar</button>
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  data () {
    return {
      userInput: '',
      status: '',
      searchResult: [],
      selectedUser: null,
      selectedLevel: 0
    }
  },
  mounted: function () {
    this.focusInput()
  },
  methods: {
    focusInput () {
      setTimeout(() => { this.$refs.input.focus() }, 200)
    },
    selectUser: function (user) {
      this.selectedUser = user
    },
    getUser: debounce(
      function () {
        this.status = 'Buscando...'
        this.$http
          .request({
            method: 'get',
            url:
              '/organizacion/' +
              this.$store.getters.getUser.organization +
              '/usuario?s=' +
              this.userInput +
              '&rol=otros-user'
          })
          .then(response => {
            this.searchResult = response.data.data
            this.status = ''
          })
          .catch(error => {
            console.error('Fail', error)
            this.status = 'Error al solicitar el pedido al servidor...'
          })
      },
      // This is the number of milliseconds we wait for the user to stop typing.
      500
    ),
    submit: function () {
      this.$emit('launch-firmador', [this.selectedUser.id])
      this.$parent.close()
    }
  },
  computed: {
    tieneRolLyris: function () {
      return this.$store.getters.hasAnyRole(['lyris'])
    }
  },
  watch: {
    userInput: function (newUserInput) {
      this.status = 'Tipeando...'
      this.searchResult = []
      if (newUserInput.trim().length >= 3) this.getUser()
      else this.status = 'Por favor, escriba más caracteres para la búsqueda.'
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  color: gray
}
</style>
