<template>
  <section>
    <!-- <div class="notification" v-if="fetching">
      <i class="fas fa-sync fa-spin"></i> Cargando serie...
    </div>
    <section v-if="serie"> -->
      <div class="box">
        <h1 class="subtitle is-5">Editar serie documental</h1>
        <h1 class="title is-1">{{serie.nombre}}</h1>
        <hr>
      <div class="content">
        <p>{{serie.descripcion}}</p>
        <p class="is-size-7">Última edición: {{serie.created_at}}</p>
      </div>
      <hr>

      <div class="buttons">
        <router-link
          class="button is-outlined is-primary"
          :to="{name: 'organizacionVerSerieDocumental', params: {idSerie: serie.id} }"
        >
          <i class="fas fa-reply"></i>&nbsp;Desactivar edición
        </router-link>
      </div>
      </div>
    <div class="notification is-link animated flash" v-if="this.$route.query.creado">
      <h5 class="title is-5"><i class="fas fa-check-circle"></i>&nbsp;¡Felicidades! ¡La serie ha sido creada!</h5>
      <div class="content">
      <p>El próximo paso es crear los eventos de la serie.</p>
      <p> Para eso, comience a crear Eventos sus acciones en la sección <b>"Eventos de la serie"</b></p>
      </div>
    </div>
    <editar-eventos ref="editarEventos"  @crearEvento="crearEvento" @deleteEvento="deleteEvento" @editarAccion="editarAccion" @editarEvento="editarEvento" v-if="this.$route.query.creado" />
    <editar-datos ref="editDatos" @submit="submitEdit"  />
    <editar-aspectos ref="editAspectos" @deleteAspecto="deleteAspecto" @addAspecto="addAspecto"  />
    <editar-estados ref="editEstados" @submitEstado="submitEstado" @submitEstadoInicial="submitEstadoInicial" @deleteEstado="deleteEstado" @editarNombreEstado="editarNombreEstado"  />
    <editar-subunidades ref="editSubunidad" @submitSubunidad="submitSubunidad" @submitEditarNombreSubunidad="submitEditarNombreSubunidad" @deleteSubunidad="deleteSubunidad"   />
    <editar-eventos ref="editarEventos"  @crearEvento="crearEvento" @deleteEvento="deleteEvento" @nuevaAccion="nuevaAccion" @editarAccion="editarAccion" @deleteAccionEvento="deleteAccionEvento" @editarEvento="editarEvento" v-if="!this.$route.query.creado" />
    <!-- </section> -->
  </section>
</template>

<script>
// import SerieDocumentalForm from './Form'
// import CrearEvento from './CrearEvento'
import EditarDatos from '@/components/organizacion/series-documentales/EditarDatos'
import EditarAspectos from '@/components/organizacion/series-documentales/EditarAspectos'
import EditarEstados from '@/components/organizacion/series-documentales/EditarEstados'
import EditarSubunidades from '@/components/organizacion/series-documentales/EditarSubunidades'
import EditarEventos from '@/components/organizacion/series-documentales/EditarEventos'

export default {
  props: ['idOrganization', 'idSerie'],
  components: {
    EditarDatos,
    EditarAspectos,
    EditarEstados,
    EditarSubunidades,
    EditarEventos
  },
  data () {
    return {
      // fetching: true,
      // serie: null,
      // areasDisponibles: [],
      fetchingAreas: false,
      fetchedAreas: false
    }
  },
  created: function () {
    // this.$emit('updateResource')
    // this.fetchAreasDisponibles()
  },
  computed: {
    serie: function () {
      return this.$store.state.serieDocumental.serieDocumental
    },
    aspectos: function () {
      return this.$store.getters['organizacion/allAspectos']
    },
    aspectosSerie: function () {
      if (!this.aspectos) return []
      return this.aspectos.filter((a) =>
        Object.values(this.serie.aspectos).includes(a.id)
      )
    }
  },
  methods: {
    submitEdit: function (payload) {
      this.startLoading()
      this.$http
        .patch(`/serie-documental/${this.idSerie}`, payload)
        .then(result => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: 'Los datos de la serie documental ha sido editada exitosamente',
            type: 'is-success'
          })
          this.$emit('updateResource')
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio con un error.',
            type: 'is-danger'
          })
        })
    },
    submitEstado: function (estado) {
      this.startLoading()
      this.$http
        .post(`/serie-documental/${this.idSerie}/estado`, estado)
        .then(result => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: `El estado (${estado.nombre}) ha sido creado exitosamente`,
            type: 'is-success'
          })
          // this.$refs.editEstados.finalizarSubmitEstado(estado)
          this.$emit('updateResource')
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio con un error.',
            type: 'is-danger'
          })
        })
    },
    submitEstadoInicial: function (payload) {
      this.startLoading()
      this.$http
        .patch(`/serie-documental/${this.idSerie}`, payload)
        .then(result => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: 'Se ha configurado exitosamente el nuevo estado inicial',
            type: 'is-success'
          })
          this.$emit('updateResource')
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio con un error.',
            type: 'is-danger'
          })
        })
    },
    editarNombreEstado: function (estado, slug, value) {
      this.startLoading()
      this.$http
        .patch(`/serie-documental/${this.idSerie}/estado/${slug}`, {
          nombre: value
        })
        .then(result => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: `El estado <b>${estado}</b> ha cambiado el nombre a <b>${value}</b> exitosamente`,
            type: 'is-success'
          })
          this.$emit('updateResource')
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: err.mensaje,
            type: 'is-danger'
          })
        })
    },
    deleteEstado: function (estado, slug) {
      this.startLoading()
      this.$http
        .delete(`/serie-documental/${this.idSerie}/estado/${slug}`)
        .then(result => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: `El estado (${estado}) ha sido eliminado exitosamente`,
            type: 'is-success'
          })
          this.$emit('updateResource')
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: err.mensaje,
            type: 'is-danger'
          })
        })
    },
    submitSubunidad: function (subunidad) {
      this.startLoading()
      this.$http
        .post(`/serie-documental/${this.idSerie}/subunidad`, subunidad)
        .then(result => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: `La subunidad (${subunidad.parametros.nombre}) ha sido creada exitosamente`,
            type: 'is-success'
          })
          this.$emit('updateResource')

          // this.$refs.editSubunidad.finalizarSubmitSubunidad(subunidad)
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: err.mensaje,
            type: 'is-danger'
          })
        })
    },
    deleteSubunidad: function (key) {
      this.startLoading()
      this.$http
        .delete(`/serie-documental/${this.idSerie}/subunidad/${key}`)
        .then(result => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: `La subunidad (${key}) ha sido eliminado exitosamente`,
            type: 'is-success'
          })
          this.$emit('updateResource')
          // this.$refs.editSubunidad.finalizarDeleteSubunidad(key)
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: err.mensaje,
            type: 'is-danger'
          })
        })
    },
    submitEditarNombreSubunidad: function (subunidad, payload) {
      this.startLoading()
      this.$http
        .patch(`/serie-documental/${this.idSerie}/subunidad/${subunidad.key}`, payload)
        .then(result => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: `La subunidad (${payload.nombre}) ha sido editada exitosamente`,
            type: 'is-success'
          })
          this.$emit('updateResource')
          // this.$refs.editSubunidad.finalizarSubmitEditarNombreSubunidad()
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio con un error.',
            type: 'is-danger'
          })
        })
    },
    deleteEvento: function (resource) {
      this.startLoading()
      this.$http
        .delete(`/serie-documental-evento/${resource.id}`)
        .then(res => {
          this.$emit('updateResource')
          this.$buefy.snackbar.open({
            message: 'El evento se ha eliminado exitosamente',
            type: 'is-success'
          })
        })
        .catch(err => {
          this.$buefy.snackbar.open({
            message: err.mensaje,
            type: 'is-warning'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    addAspecto: function (resource) {
      this.startLoading()
      this.$http
        .post(`/serie-documental/${this.idSerie}/aspecto/${resource.id}`)
        .then(res => {
          this.$emit('updateResource')
          this.$buefy.snackbar.open({
            message: 'El aspecto ha sido agregado exitosamente',
            type: 'is-success'
          })
        })
        .catch(err => {
          this.$buefy.snackbar.open({
            message: err.mensaje,
            type: 'is-warning'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    deleteAspecto: function (resource) {
      this.startLoading()
      this.$http
        .delete(`/serie-documental/${this.idSerie}/aspecto/${resource.id}`)
        .then(res => {
          this.$emit('updateResource')
          this.$buefy.snackbar.open({
            message: 'El aspecto ha sido eliminado exitosamente',
            type: 'is-success'
          })
        })
        .catch(err => {
          this.$buefy.snackbar.open({
            message: err.mensaje,
            type: 'is-warning'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    editarAccion: function (evento, accion, payload) {
      this.startLoading()
      this.$http
        .patch(
          `/serie-documental-accion/${accion.id}`,
          payload
        )
        .then(res => {
          this.$emit('updateResource')
          this.$buefy.snackbar.open({
            message: 'La accion se ha editado exitosamente',
            type: 'is-success'
          })
        })
        .catch(err => {
          this.$buefy.snackbar.open({
            message: err.mensaje,
            type: 'is-warning'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    nuevaAccion: function (evento, payload) {
      // console.log(payload)
      this.startLoading()
      this.$http
        .post(
          '/serie-documental-accion',
          payload
        )
        .then(res => {
          this.$emit('updateResource')
          this.$buefy.snackbar.open({
            message: `La accion del evento <b>${evento.nombre}</b> ha sido creado exitosamente`,
            type: 'is-success'
          })
        })
        .catch(err => {
          this.$buefy.snackbar.open({
            message: err.mensaje,
            type: 'is-warning'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    deleteAccionEvento: function (evento, accion) {
      this.startLoading()
      this.$http
        .delete(
          `/serie-documental-accion/${accion.id}`)
        .then(res => {
          this.$emit('updateResource')
          this.$buefy.snackbar.open({
            message: 'La acción ha sido eliminada correctamente',
            type: 'is-success'
          })
        })
        .catch(err => {
          this.$buefy.snackbar.open({
            message: err.mensaje,
            type: 'is-warning'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    editarEvento: function (resource, payload) {
      this.startLoading()
      this.$http
        .patch(`/serie-documental-evento/${resource.id}`, payload)
        .then(res => {
          this.$emit('updateResource')
          this.$buefy.snackbar.open({
            message: 'El evento se ha editado exitosamente',
            type: 'is-success'
          })
        })
        .catch(err => {
          this.$buefy.snackbar.open({
            message: err.mensaje,
            type: 'is-warning'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    crearEvento: function () {
      this.$emit('updateResource')
    }
  }
}
</script>
