import Vue from 'vue'
import Vuex, {
  // eslint-disable-next-line no-unused-vars
  Store
} from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import signarModule from './signar'
import firmadorModule from './firmador'
import repositoryModule from '@/views/repositorio/store'
import documentModule from '@/views/documento/store'
import recibosModule from '@/views/recibos/store'
import reciboModule from '@/views/recibo/store'
import otrosDocumentosModule from '@/views/otros-documentos/store'
import profileModule from '@/views/perfil/store'
import customSettingsModule from '@/views/custom-settings/store'
import unidadCompuestaModule from '@/views/unidad-compuesta/store'
import serieDocumentalModule from '@/views/organizacion/series-documentales/store'
import organizacionModule from '@/views/organizacion/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    signar: signarModule,
    repository: repositoryModule,
    document: documentModule,
    profile: profileModule,
    customSettings: customSettingsModule,
    firmador: firmadorModule,
    recibos: recibosModule,
    recibo: reciboModule,
    otrosDocumentos: otrosDocumentosModule,
    unidadCompuesta: unidadCompuestaModule,
    serieDocumental: serieDocumentalModule,
    organizacion: organizacionModule
  },
  state: {
    session: null,
    // unidadCompuesta: {},
    // serieDocumentalModuleService: {
    //   operacionesDisponibles: []
    // },
    // recibo: {},
    // organizacion: null,
    // selectedUser: null,
    pila: [],
    // aspectos: [],
    organizacionEnv: null
    // rolesOrganizacion: [],
    // areasOrganizacion: []
    // lang: 'es'
  },
  mutations: {
    login (state, data) {
      state.session = data
    },
    logout (state) {
      state.session = null
    },
    setUser (state, user) {
      state.session.user = user
    },
    // setUnidadCompuesta (state, unidadCompuesta) {
    //   state.unidadCompuesta = unidadCompuesta
    // },
    // setRecibo (state, recibo) {
    //   state.recibo = recibo
    // },
    // setOrganizacion (state, organizacion) {
    //   state.organizacion = organizacion
    // },
    // setRolesOrganizacion (state, roles) {
    //   state.rolesOrganizacion = roles
    // },
    // setAreasOrganizacion (state, areas) {
    //   state.areasOrganizacion = areas
    // },
    // setAspectos (state, aspectos) {
    //   state.aspectos = aspectos
    // },
    // setSelectedUser (state, user) {
    //   state.selectedUser = user
    // },
    // setOperacionesDisponibles (state, payload) {
    //   state.serieDocumentalModuleService.operacionesDisponibles = payload
    // },
    setOrganizacionEnv (state, organizacion) {
      state.organizacionEnv = organizacion
    }
  },
  getters: {
    hasAnyRole: (state) => (roles) => state.session && state.session.user.roles.some(role => roles.includes(role)),
    // getUnidadCompuesta: (state) => {
    //   return state.unidadCompuesta
    // },
    // getRecibo: (state) => {
    //   return state.recibo
    // },
    // getOrganizacion: (state) => {
    //   return state.organizacion
    // },
    // getSelectedUser: (state) => {
    //   return state.selectedUser
    // },
    // getAspectos: (state) => {
    //   return state.aspectos
    // },
    getUser: (state) => {
      return state.session && state.session.user
    }
    // getOperacionesDisponibles: (state) => {
    //   return state.serieDocumentalModuleService.operacionesDisponibles
    // },
    // getOperacion: (state) => (id) => {
    //   return state.serieDocumentalModuleService.operacionesDisponibles.find(x => {
    //     return x.id === id
    //   })
    // }
  },
  plugins: [createPersistedState({
    key: 'signar-storage',
    paths: ['session', 'customSettings']
  })]
})
