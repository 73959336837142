<template>
  <section class="box">
    <h1 class="subtitle is-5">Aspectos globales</h1>
    <h1 class="title is-1">Nuevo</h1>
    <aspectos-form @submit="submit"></aspectos-form>
  </section>
</template>

<script>
import AspectosForm from '@/components/globals/aspectos/Form'

export default {
  components: {
    AspectosForm
  },
  data () {
    return {
    }
  },
  methods: {
    submit: function (payload) {
      this.startLoading()
      this.$http
        .post('/aspecto', payload)
        .then(result => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: 'El aspecto global ha sido creado exitosamente',
            type: 'is-success'
          })
          this.$router.push({ name: 'listarAspectosGlobales' })
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio con un error.',
            type: 'is-danger'
          })
        })
    }
  }
}
</script>

<style>
</style>
