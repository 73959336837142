<template>
  <section class="section pt-0">
    <div class="is-flex">
      <a href="https://lyris.com.ar" target="_blank" class="">
        <img src="/statics/lyris-grey.svg" class="lyris-logo image is-96x96" alt="Lyris IT">
      </a>
    </div>
    <div class="box">
      <header class="is-flex is-justify-content-center">
        <h1 class="title is-4 has-text-centered mr-5">{{ TITLES.VERIFY_SIGNED_DOCUMENT }}</h1>
        <b-tag rounded type="is-warning" size="is-medium" class="">{{ TAGS.DEMO }}</b-tag>
      </header>
      <section class="content columns is-align-items-center is-justify-content-center">
        <div class="column is-half">
          <b-field position="is-right">
            <b-upload expanded v-model="file" drag-drop :accept='MISC.PDF'>
              <section class="p-2 has-text-centered">
                <p class="">
                  <i class="fas fa-plus"></i>&nbsp;{{ BUTTONS.UPLOAD_DOCUMENT }}
                </p>
              </section>
            </b-upload>
          </b-field>
        </div>
        <div class="column is-half">
          <b-button size="is-medium" icon-left="fas fa-upload fa-fw" class="is-primary is-fullwidth"
            :disabled="!this.file || this.pressedVerifyDocument"
            :loading="this.isLoading"
            @click="fetchSigns()">
            {{ BUTTONS.VERIFY_DOCUMENT }}
          </b-button>
        </div>
      </section>
      <section class="mb-5">
        <header class="is-flex is-justify-content-center">
          <span v-if="this.file" class="tag is-rounded is-medium">
            <b>{{ MESSAGES.SELECTED_DOCUMENT }}</b>&nbsp;{{this.file.name}}&nbsp;
            <a @click="cleanFile()">
                <i class="fas fa-times-circle"></i>
            </a>
          </span>
          <span v-else class="tag is-rounded is-medium">
            <i class="fas fa-exclamation-triangle fa-fw"></i>&nbsp;{{ MESSAGES.DOCUMENT_NOT_SELECTED }}
          </span>
        </header>
      </section>
      <header>
        <h1 class="title is-4 mb-5">{{ TITLES.SIGNS_OVER_DOCUMENT }}</h1>
      </header>
      <article class="card m-5" v-for="(sign, index) in signsList" v-bind:key="index">
        <header class="card-header is-align-items-center">
          <p class="card-header-title">
            {{ sign.signatureName }}
          </p>
          <b-tooltip :label="TOOLTIP.VALID_SIGN" :active="sign.valid" multilined position="is-left">
            <b-tag rounded :type="sign.valid ? 'is-success' : 'is-danger'" size="is-medium" class="is-500 mr-3">{{ getValidSignTagLabel(sign) }}</b-tag>
          </b-tooltip>
        </header>
        <div class="card-content">
          <div class="content">
            <p>
              <b>{{ CARD_LABELS.SIGN_DATE }}</b> {{ getSignDateText(sign) }}
            </p>
            <p>
              <b>{{ CARD_LABELS.INTEGRITY }}</b> {{getIntegrityValidText(sign) }}
            </p>
            <p>
              <b>{{ CARD_LABELS.SIGN_CERTIFICATE }}</b> {{ getSignCertificateData(sign) }}
            </p>
            <p>
              <b>{{ CARD_LABELS.EMISOR_CERTIFICATE }}</b> {{ getEmisorCertificateData(sign) }}
            </p>
            <p>
              <b>{{ CARD_LABELS.EMISOR_CERTIFICATE_VERIFIED }}</b> {{ getCertificateValidText(sign) }}
            </p>
          </div>
          <b-table
            narrowed
            :data="getDataAboutSignCertificate(sign)"
            :loading="isLoading">

            <b-table-column field="field" :label="TABLE.CERTIFICATE_INFO" v-slot="props">
                <span class="has-text-weight-semibold">
                  {{ props.row.field }}
                </span>
            </b-table-column>

            <b-table-column field="valid" centered :label="TABLE.VALID" v-slot="props">
              <span>
                  <b-icon
                      pack="fas"
                      :icon="props.row.valid.icon"
                      :type="props.row.valid.type"
                      size="is-medium">
                  </b-icon>
              </span>
            </b-table-column>

            <b-table-column field="expired" centered :label="TABLE.EXPIRED" v-slot="props">
              <span>
                  <b-icon
                      pack="fas"
                      :icon="props.row.expired.icon"
                      :type="props.row.expired.type"
                      size="is-medium">
                  </b-icon>
              </span>
            </b-table-column>

            <b-table-column field="revoked" centered :label="TABLE.REVOKED" v-slot="props">
              <span>
                  <b-icon
                      pack="fas"
                      :icon="props.row.revoked.icon"
                      :type="props.row.revoked.type"
                      size="is-medium">
                  </b-icon>
              </span>
            </b-table-column>
          </b-table>
        </div>
      </article>

      <div class="box has-text-centered" v-if="isLoading">
        <loading-spin></loading-spin>
      </div>

      <article class="box has-text-centered" v-if="this.signsListEmpty && !isLoading">
        <section class="section is-mono">
          <span class="tag is-rounded is-medium">
            <i class="fas fa-info fa-fw"></i>&nbsp;{{ this.getNoSignsText }}
          </span>
        </section>
    </article>
    </div>
  </section>
</template>

<script>
import LoadingSpin from '@/components/utils/LoadingSpin'
export default {
  components: {
    LoadingSpin
  },
  data () {
    return {
      TITLES: {
        VERIFY_SIGNED_DOCUMENT: 'Verifique un documento firmado',
        SIGNS_OVER_DOCUMENT: 'Firmas sobre el documento'
      },
      BUTTONS: {
        UPLOAD_DOCUMENT: 'Subir un documento PDF',
        VERIFY_DOCUMENT: 'Verificar documento'
      },
      TAGS: {
        SIGN_VALID: 'Firma válida',
        SIGN_INVALID: 'Firma no válida',
        DEMO: 'DEMO'
      },
      ERRORS: {
        FETCHING_SIGNS: 'Ocurrió un error al recuperar las firmas del documento',
        SIGN_DATE_IS_NULL: 'No hay datos sobre la fecha de firma',
        INTEGRITY_IS_NULL: 'No hay datos sobre la integridad',
        SIGN_CERTIFICATE_IS_NULL: 'No hay datos sobre el certificado de firma',
        EMISOR_CERTIFICATE_IS_NULL: 'No hay datos sobre el certificado del emisor',
        EMISOR_CERTIFICATE_VERIFIED_IS_NULL: 'No hay datos sobre la verificación del certificado del emisor'
      },
      LABELS: {
        SIGNS_OVER_DOCUMENT: 'Firmas sobre el documento'
      },
      CARD_LABELS: {
        SIGN_DATE: 'Fecha y hora de firma: ',
        INTEGRITY: 'Integridad: ',
        SIGN_CERTIFICATE: 'Certificado de firma: ',
        EMISOR_CERTIFICATE: 'Certificado del emisor: ',
        EMISOR_CERTIFICATE_VERIFIED: 'Certificado del emisor verificado: '
      },
      CARD_VALUES: {
        INTEGRITY_VALID: '✅ (¡El documento no fue modificado luego de firmarse!)',
        INTEGRITY_INVALID: '❌ (El documento fue modificado luego de firmarse, no está integro)',
        CERTIFICATE_VERIFIED: '✅ (¡El certificado del emisor está verificado!)',
        CERTIFICATE_UNVERIFIED: '❌ (El certificado del emisor no está verificado)'
      },
      MESSAGES: {
        SELECTED_DOCUMENT: 'Documento seleccionado: ',
        DOCUMENT_NOT_SELECTED: 'No se seleccionó ningún documento',
        UPLOAD_DOCUMENT_TO_VERIFY_SIGNS: 'Presione el botón "Subir un documento PDF" para subir un nuevo documento',
        DOCUMENT_SELECTED_BUT_BUTTON_NOT_PRESSED: 'Presione el botón "Verificar documento" para verificar las firmas del documento',
        DOCUMENT_SELECTED_BUT_SIGNS_EMPTY: 'El documento que subió no contiene ninguna firma'
      },
      TABLE: {
        VALID: 'Válido',
        EXPIRED: 'Expirado',
        REVOKED: 'Revocado',
        WHEN_THE_DOCUMENT_WAS_SIGNED: 'Al momento de la firma',
        NOW: 'Actualmente',
        CERTIFICATE_INFO: 'Información del certificado'
      },
      TOOLTIP: {
        VALID_SIGN: 'La integridad no ha sido alterada y el certificado de firma era válido al momento de la firma'
      },
      MISC: {
        URL: '/demo-verificador',
        FILE: 'file',
        PDF: '.pdf',
        POST: 'post'
      },

      file: null,
      isLoading: false,
      pressedVerifyDocument: false,
      signsList: []
    }
  },
  methods: {
    cleanFile: function () {
      this.file = null
    },
    cleanSigns: function () {
      this.signsList = []
      this.pressedVerifyDocument = false
    },
    fetchSigns: function () {
      if (!this.file || this.isLoading) {
        return
      }
      this.cleanSigns()
      this.pressedVerifyDocument = true
      this.isLoading = true
      const data = new FormData()
      data.append(this.MISC.FILE, this.file)
      this.$http
        .request({
          method: this.MISC.POST,
          url: this.MISC.URL,
          data: data
        })
        .then((res) => {
          this.signsList = res.data
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: this.ERRORS.FETCHING_SIGNS,
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getDataAboutSignCertificate: function (sign) {
      return [
        {
          field: this.TABLE.WHEN_THE_DOCUMENT_WAS_SIGNED,
          valid: this.getIconAndType(sign.certificate.validAtSigning),
          expired: this.getIconAndType(sign.certificate.expiredAtSigning),
          revoked: this.getIconAndType(sign.certificate.revokedAtSigning)
        },
        {
          field: this.TABLE.NOW,
          valid: this.getIconAndType(sign.certificate.valid),
          expired: this.getIconAndType(sign.certificate.expired),
          revoked: this.getIconAndType(sign.certificate.revoked)
        }
      ]
    },
    getIconAndType: function (value) {
      return {
        icon: value === true ? 'check-circle' : value === false ? 'times-circle' : 'minus',
        type: value === true ? 'is-success' : value === false ? 'is-danger' : ''
      }
    },
    getIntegrityValidText: function (sign) {
      return sign.integrity ? this.CARD_VALUES.INTEGRITY_VALID : this.CARD_VALUES.INTEGRITY_INVALID
    },
    getCertificateValidText: function (sign) {
      return sign.certificate.verified ? this.CARD_VALUES.CERTIFICATE_VERIFIED : this.CARD_VALUES.CERTIFICATE_UNVERIFIED
    },
    getSignDateText: function (sign) {
      if (!sign.signingDate) {
        return this.ERRORS.SIGN_DATE_IS_NULL
      }

      const date = new Date(sign.signingDate)
      const dayFormatted = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      const monthFormatted = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1

      // i want the hour minutes and seconds from sign.signingDate
      const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()

      return dayFormatted + '-' + monthFormatted + '-' + date.getFullYear() + ' ' + hour + ':' + minutes + ':' + seconds
    },
    getSignCertificateData: function (sign) {
      if (!sign.certificate.subject) {
        return this.ERRORS.SIGN_CERTIFICATE_IS_NULL
      }

      const dataArray = sign.certificate.subject.split(',')
      let certificateData = ''

      for (let i = 0; i < dataArray.length - 1; i++) {
        certificateData += dataArray[i].split('=')[1] + ' | '
      }
      certificateData += dataArray[dataArray.length - 1].split('=')[1]

      return certificateData
    },
    getEmisorCertificateData: function (sign) {
      if (!sign.certificate.issuer) {
        return this.ERRORS.EMISOR_CERTIFICATE_IS_NULL
      }

      const dataArray = sign.certificate.issuer.split(',')
      let certificateData = ''

      for (let i = 0; i < dataArray.length - 1; i++) {
        certificateData += dataArray[i].split('=')[1] + ' | '
      }
      certificateData += dataArray[dataArray.length - 1].split('=')[1]

      return certificateData
    },
    getValidSignTagLabel: function (sign) {
      return sign.valid ? this.TAGS.SIGN_VALID : this.TAGS.SIGN_INVALID
    }
  },
  computed: {
    signsListEmpty: function () {
      return this.signsList.length === 0
    },
    getNoSignsText: function () {
      if (this.file) {
        return this.pressedVerifyDocument ? this.MESSAGES.DOCUMENT_SELECTED_BUT_SIGNS_EMPTY : this.MESSAGES.DOCUMENT_SELECTED_BUT_BUTTON_NOT_PRESSED
      } else {
        return this.MESSAGES.UPLOAD_DOCUMENT_TO_VERIFY_SIGNS
      }
    }
  },
  watch: {
    file: function () {
      this.cleanSigns()
    }
  }
}
</script>

<style>
.box {
  border: 1px solid #e0e0e0;
}

.card {
  border: 1px solid #e0e0e0;
}

.image {
  display: block;
}

</style>
