<template>
  <section class="box">
      <h1 class="subtitle is-5">Aspectos globales</h1>
    <h1 class="title is-1">Editar</h1>
    <hr>
    <div class="notification" v-if="fetching">
      <i class="fas fa-sync fa-spin"></i>Cargando aspecto...
    </div>
    <aspectos-form @submit="submit" :edit="true" :previousData="aspecto" v-if="ready"></aspectos-form>
  </section>
</template>

<script>
import AspectosForm from '@/components/globals/aspectos/Form'

export default {
  props: ['id', 'idAspecto'],
  components: {
    AspectosForm
  },
  data () {
    return {
      fetching: true,
      ready: false,
      aspecto: null
    }
  },
  beforeMount: function () {
    this.fetchAspecto()
  },
  methods: {
    fetchAspecto: function () {
      this.fetching = false
      this.startLoading()
      this.$http.get('/aspecto')
        .then(result => {
          this.aspecto = result.data.find(asp => {
            return asp.id === Number.parseInt(this.idAspecto)
          })
          this.fetching = false
          this.ready = true
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio con un error.',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    submit: function (payload) {
      this.startLoading()
      this.$http
        .patch(`/aspecto/${this.id}`, payload)
        .then(result => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: 'El aspecto global ha sido editado exitosamente',
            type: 'is-success'
          })
          this.$router.push({ name: 'listarAspectosGlobales' })
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio con un error.',
            type: 'is-danger'
          })
        })
    }
  }

}
</script>

<style>
</style>
