<template>
  <section>
    <div class="columns is-mobile is-multiline">
        <div class="column is-12-mobile is-4-tablet is-3-desktop is-2-fullhd">
          <div class="card folders-container">
            <div class="card-content px-0 pt-2 pb-3">
              <div class="row-folder-header">
                 <a class="is-size-7" @click="toPreviousFolder" v-if="canGoToPreviousFolder"><i class="fas fa-reply fa-fw"></i>&nbsp;Volver atrás</a>
                 <!-- <span class="is-size-7" v-else-if="loadingFolders || loadingDocuments"><i class="fas fa-sync fa-spin"></i>&nbsp;&nbsp;Cargando...</span> -->
                 <span class="is-size-7" v-else-if="loadingFolders"><i class="fas fa-sync fa-spin"></i>&nbsp;&nbsp;Cargando...</span>
                 <span class="is-size-7" v-else><i class="fas fa-info-circle"></i>&nbsp;&nbsp;Nivel superior</span>
              </div>
              <div class="row-folder"
              v-for="(route, index) in routes"
              :key="`folder-route-${index}`"
              @drop.prevent="dropOnFolderRoute($event, routes.slice(0,index+1).join('/'))"
              @dragover.prevent
              @dragenter="onDragEnterFolderRoute($event,routes.slice(0,index+1).join('/'))"
              @dragleave="onDragLeaveFolderRoute($event,routes.slice(0,index+1).join('/'))"
              @click.exact="selectPreviousFolder(index)"
              @dblclick="dobleClickPreviousFolders(routes.slice(0,index+1).join('/'))"
              :class="{'selected': selectedFolder != null && selectedFolder.nombre == currentFolder.nombre && index == routes.length-1, 'dropping': routes.slice(0,index+1).join('/') == onDragFolderRoute}"
              :style="{paddingLeft: `${20 + (12*index)}px`}">
                <span v-if="index < routes.length-1" class="previous-unloaded-folders">
                 <i class="far fa-fw fa-lg" :class="{'fa-folder-open': index > 0, 'fa-building': index == 0}"></i>&nbsp;&nbsp;<b>{{route}}</b>
                </span>
                <span v-else-if="route == '' && $store.getters.hasAnyRole(['lyris'])">
                 <i class="fab fa-fw fa-lg fa-pagelines"></i>&nbsp;&nbsp;<b>Raiz</b>
                </span>
                <span v-else>
                 <i class="far fa-fw fa-lg" :class="{'fa-folder-open': index > 0, 'fa-building': index == 0}"></i>&nbsp;&nbsp;<b>{{route}}</b>
                </span>
                <!-- <i class="far fa-fw fa-lg fa-folder-open"></i>&nbsp;&nbsp;<b>{{getCurrentFolderReadableName(currentFolder)}}</b> -->
              </div>
              <div
                v-for="(folder,indexFolder) in folders"
                :key="`folder-${indexFolder}-${folder.id}`"
                @drop.prevent="dropOnFolder($event, folder)"
                @dragover.prevent
                @dragenter="onDragEnterFolder($event,folder.id)"
                @dragleave="onDragLeaveFolder($event,folder.id)"
                @click.exact="selectOneFolder(folder, indexFolder)"
                @click.middle="middleClick(folder)"
                @dblclick="dobleClick(folder)"
                @contextmenu.prevent="openContextMenuFolder(folder, indexFolder, $event)"
                :class="{'selected': selectedFolder != null && folder.id == selectedFolder.id, 'dropping': folder.id == onDragFolderId}"
                class="row-folder"
                :style="{paddingLeft: `${20 + (12* routes.length )}px`}"
                >
                <i class="fa-fw fa-lg" :class="getIconFile(folder.tipo_documento_id)"></i>&nbsp;&nbsp;{{folder.nombre}}

              </div>
              <infinite-loading @infinite="infiniteHandlerFolders" :identifier="identifierLoaderFolders">
                <span slot="no-more"></span>
                <span slot="no-results"></span>
              </infinite-loading>
            </div>
          </div>
        </div>
        <div class="column is-12-mobile is-8-tablet"
        @drop="dropFilesOverDocuments($event)"
        @dragenter="onDragEnterOverDocuments($event)"
        @dragleave="onDragLeaveOverDocuments($event)"
        @dragover.prevent
        :class="{'is-6-desktop is-8-fullhd': showSidebar || selectedDocumentsIndex.length > 1, 'is-9-desktop is-10-fullhd': !showSidebar && selectedDocumentsIndex.length <= 1 }">
          <div class="card documents-container">
            <div class="overlay-dragging-files is-justify-content-center is-align-items-center" :class="{'active': onDragDocumentsCounterAux > 0}">
              <div class="has-text-centered">
                <p class="has-text-white pt-5 pb-2"><i class="fas fa-file-upload fa-2x animated pulse infinite"></i></p>
                <p class="has-text-white is-size-7">Suelte aquí los archivos para subirlos a la carpeta</p>
              </div>
            </div>
            <div class="card-content px-0 pt-2 pb-3">
              <div class="row-file-header">
                <div class="row-file-name">
                  <a @click="toggleSortDocuments('nombre')" class="has-text-dark" :class="{'has-text-link': queryDocuments.sort == 'nombre'}"><b>Nombre</b></a>
                  <i v-if="queryDocuments.sort == 'nombre' && queryDocuments.direction == 'desc'" class="fas fa-sort-alpha-down fa-fw has-text-link"></i>
                  <i v-else-if="queryDocuments.sort == 'nombre' && queryDocuments.direction == 'asc'" class="fas fa-sort-alpha-up fa-fw has-text-link"></i>
                  <i v-else class="fas fa-sort-alpha-up fa-fw has-text-kinda-dark"></i>
                </div>
                <div class="row-file-date is-hidden-mobile">
                  <a class="has-text-dark" :class="{'has-text-link': queryDocuments.sort == 'created_at'}" @click="toggleSortDocuments('created_at')"><b>Creado</b></a>
                  <i v-if="queryDocuments.sort == 'created_at' && queryDocuments.direction == 'desc'" class="fas fa-sort-numeric-up fa-fw has-text-link"></i>
                  <i v-else-if="queryDocuments.sort == 'created_at' && queryDocuments.direction == 'asc'" class="fas fa-sort-numeric-down fa-fw has-text-link"></i>
                  <i v-else class="fas fa-sort-numeric-down fa-fw has-text-kinda-dark"></i>
                </div>
                <div class="row-file-size is-hidden-mobile">
                  <b>Peso</b>
                </div>
              </div>
              <div
                v-for="(document,indexDocument) in documents"
                :key="`document-${indexDocument}-${document.id}`"
                @click.exact="selectOneDocument(document, indexDocument)"
                @click.middle="middleClick(document)"
                @click.ctrl.exact="toggleOneDocument(document, indexDocument)"
                @click.shift.exact="toggleSelectDocumentShift(document, indexDocument)"
                @dblclick="dobleClick(document)"
                @contextmenu.prevent="openContextMenuDocument(document, indexDocument, $event)"
                draggable
                @dragstart='dragFileStart($event,document)'
                @dragend='dragFileEnd($event)'
                :class="{'selected': selectedDocumentsId.includes(document.id)}"
                class="row-file">
                <div class="row-file-name">
                  <i class="fa-fw fa-lg" :class="getIconFile(document.tipo_documento_id)"></i>&nbsp;&nbsp;{{document.nombre}}
                </div>
                <div class="row-file-date is-size-7 is-hidden-mobile">
                  {{ new Date(document.created_at) | moment('DD/MM/YYYY') }}
                </div>
                <div class="row-file-size is-size-7 is-hidden-mobile">
                  {{readableSizeFile(document.peso)}}
                </div>
              </div>
              <infinite-loading @infinite="infiniteHandlerDocuments" :identifier="identifierLoaderDocuments">
                <div slot="no-more" class=" py-5 is-size-7 is-mono">~ Fin del listado ~</div>
                <div slot="no-results" class=" py-5 is-size-7 is-mono">~ Fin del listado ~</div>
              </infinite-loading>
            </div>
          </div>
        </div>
        <sidebar v-if="showSidebar"
        :file.sync="selectedSidebarDocument"
        @close-sidebar="closeSidebar"
        @folder-permissions="$emit('folder-permissions')"
        @delete-document="$emit('delete-document')"
        @move-document="$emit('move-document')"
        @rename-document="$emit('rename-document')"
        @preview-document="$emit('preview-document')" />
        <multiple-sidebar v-if="selectedDocumentsIndex.length > 1"
        @move-multiple-documents="$emit('move-multiple-documents')"
        @delete-multiple-documents="$emit('delete-multiple-documents')"
        @unselect-documents="unselectDocuments" />
      </div>
      <context-menu
        :selectedDocuments.sync="selectedDocuments"
        ref="menu"
        @move-multiple-documents="$emit('move-multiple-documents')"
        @rename-document="$emit('rename-document')"
        @folder-permissions="$emit('folder-permissions')"
        @delete-multiple-documents="$emit('delete-multiple-documents')"
        />
        <!-- <span ref="dragLabelCursorForSelectedDocuments" class="tag is-link is-radiusless has-text-weight-bold is-hidden-touch" style="position: absolute;bottom: -100px;right: -100px;">
          {{selectedDocuments.length}}&nbsp;&nbsp;<i class="far" :class="selectedDocuments.length > 1 ? 'fa-copy' : 'fa-file'"></i>
        </span> -->
  </section>
</template>

<script>
import Sidebar from './Sidebar'
import InfiniteLoading from 'vue-infinite-loading'
import ContextMenu from './ContextMenu'
import MultipleSidebar from './MultipleSidebar'
import ModalConfirmAction from '@/components/utils/ModalConfirmAction'
import filesUtils from '@/mixins/filesUtils'

export default {
  props: ['idFolder'],
  mixins: [filesUtils],
  components: {
    InfiniteLoading,
    ContextMenu,
    Sidebar,
    MultipleSidebar
  },
  data () {
    return {
      loadingDocuments: true,
      loadingFolders: true,
      documents: [],
      folders: [],
      paginatorFolders: {
        current_page: 1,
        last_page: null,
        next_page_url: null,
        prev_page_url: null
      },
      paginatorDocuments: {
        current_page: 1,
        last_page: null,
        next_page_url: null,
        prev_page_url: null
      },
      identifierLoaderDocuments: Math.random(),
      identifierLoaderFolders: Math.random(),
      selectedDocuments: [],
      selectedDocumentsId: [],
      selectedDocumentsIndex: [],
      selectedFolder: null,
      selectedSidebarDocument: null,
      selectedContextDocument: null,
      onDragFolderId: null,
      onDragFolderRoute: null,
      onDragFolderCounterAux: 0,
      onDragFolderRouteCounterAux: 0,
      onDragDocumentsCounterAux: 0,
      lastIndexClicked: null,
      currentFolder: null,
      routes: [],
      queryFolders: {
        sort: null,
        direction: null,
        carpetas: 1,
        page: 1
      },
      queryDocuments: {
        sort: null,
        direction: null,
        carpetas: 0,
        page: 1
      }
    }
  },
  beforeMount () {
    console.log('Repository: Added keydown event listener')
    window.addEventListener('keydown', this.keydownEventHandler)
    // add the event listener when this instance is mounted
    window.addEventListener('click', this.clickEventHandler)
  },
  beforeDestroy () {
    console.log('Repository: Removed keydown event listener')
    // remove the event listener when this instance is destroyed
    window.removeEventListener('keydown', this.keydownEventHandler)
    // remove the event listener when this instance is destroyed
    window.removeEventListener('click', this.clickEventHandler)
  },
  methods: {
    clickEventHandler: function (event) {
      // if the context menu is visible and the user clicks outside of it, close it
      if (this.$refs.menu && this.$refs.menu.show && !this.$refs.menu.$el.contains(event.target)) {
        this.$refs.menu.close()
      }
    },
    keydownEventHandler: function (event) {
      if (event.key === 'Delete') {
        console.log('Repository: Delete key pressed')
        if (this.selectedDocumentsId.length > 0) {
          console.log('Repository: Delete Key event: Documents selected')
          this.$emit('delete-multiple-documents')
        } else {
          console.log('Repository: Delete Key event: No documents selected. Abort...')
        }
      }
    },
    dropFilesOverDocuments: function (event) {
      event.preventDefault()
      console.log('Repository: dropFilesOverDocuments')
      const dt = event.dataTransfer
      if (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') !== -1 : dt.types.contains('Files'))) {
        console.log('Repository: dropFilesOverDocuments: Files dragging detected')
        console.log('Repository: dropFilesOverDocuments: got ' + event.dataTransfer.files.length + ' files')
        this.$emit('drag-and-drop-upload-documents', event.dataTransfer.files)
      } else {
        event.preventDefault()
      }
      this.onDragDocumentsCounterAux = 0
      this.onDragFolderCounterAux = 0
      // return
      // if (this.selectedDocuments.length || event.dataTransfer.files.length === 0) {
      // }
    },
    // Drag and Drop Files to Folders
    dragFileStart: function (event, document) {
      console.log('Repository: dragFileStart')
      if (!this.selectedDocumentsId.includes(document.id)) {
        console.log('Repository: dragFileStart: file not in selectedDocuments... deny')
        event.preventDefault()
        return
      }
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      // const crt = this.$refs.dragLabelCursorForSelectedDocuments
      // event.dataTransfer.setDragImage(crt, -10, -10)
      event.dataTransfer.setData('itemID', this.selectedDocumentsId)
    },
    dragFileEnd: function (event, document) {
      console.log('Repository: dragFileEnd')
      this.onDragFolderId = null
    },
    dropOnFolder: function (event, folder) {
      if (!this.selectedDocuments.length || event.dataTransfer.files.length > 0) {
        event.preventDefault()
        return
      }
      console.log('Repository: dropOnFolder', event.dataTransfer.getData('itemID'))
      this.$buefy.modal.open({
        parent: this,
        component: ModalConfirmAction,
        width: 720,
        props: {
          title: `Está por mover ${this.selectedDocumentsId.length} documento${this.selectedDocumentsId.length > 1 ? 's' : ''}`,
          subtitle: '¿Seguro que desea realizar la siguiente acción?',
          actionText: 'Mover',
          actionClass: null
        },
        events: {
          accept: () => {
            this.$emit('drag-and-drop-move-documents', folder)
            this.onDragFolderId = null
            this.onDragFolderCounterAux = 0
          }
        }
      })
    },
    onDragEnterFolder: function (event, folderId) {
      if (!this.selectedDocuments.length) {
        event.preventDefault()
        return
      }
      console.log('Repository: onDragEnterFolder')
      this.onDragFolderCounterAux++
      this.onDragFolderId = folderId
    },
    onDragLeaveFolder: function (event) {
      if (!this.selectedDocuments.length) {
        event.preventDefault()
        return
      }
      console.log('Repository: onDragLeaveFolder')
      this.onDragFolderCounterAux--
      if (this.onDragFolderCounterAux === 0) {
        this.onDragFolderId = null
      }
    },
    dropOnFolderRoute: function (event, folderRoute) {
      if (!this.selectedDocuments.length || event.dataTransfer.files.length > 0) {
        event.preventDefault()
        return
      }
      console.log('Repository: dropOnFolderRoute', event.dataTransfer.getData('itemID'))
      this.$buefy.modal.open({
        parent: this,
        component: ModalConfirmAction,
        width: 720,
        props: {
          title: `Está por mover ${this.selectedDocumentsId.length} documento${this.selectedDocumentsId.length > 1 ? 's' : ''}`,
          subtitle: '¿Seguro que desea realizar la siguiente acción?',
          actionText: 'Mover',
          actionClass: null
        },
        events: {
          accept: () => {
            this.$emit('drag-and-drop-move-documents', folderRoute)
            this.onDragFolderRoute = null
            this.onDragFolderRouteCounterAux = 0
          }
        }
      })
    },
    onDragEnterFolderRoute: function (event, folderRoute) {
      if (!this.selectedDocuments.length) {
        event.preventDefault()
        return
      }
      console.log('Repository: onDragEnterFolderRoute')
      this.onDragFolderRouteCounterAux++
      this.onDragFolderRoute = folderRoute
    },
    onDragLeaveFolderRoute: function (event) {
      if (!this.selectedDocuments.length) {
        event.preventDefault()
        return
      }
      console.log('Repository: onDragLeaveFolderRoute')
      this.onDragFolderRouteCounterAux--
      if (this.onDragFolderRouteCounterAux === 0) {
        this.onDragFolderRoute = null
      }
    },
    onDragEnterOverDocuments: function (event) {
      console.log('Repository: onDragEnterOverDocuments')
      const dt = event.dataTransfer
      if (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') !== -1 : dt.types.contains('Files'))) {
        console.log('Repository: onDragEnterOverDocuments: Files dragging detected')
        this.onDragDocumentsCounterAux++
      }
    },
    onDragLeaveOverDocuments: function (event) {
      console.log('Repository: onDragLeaveOverDocuments')
      const dt = event.dataTransfer
      if (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') !== -1 : dt.types.contains('Files'))) {
        console.log('Repository: onDragLeaveOverDocuments: Files dragging detected')
        this.onDragDocumentsCounterAux--
      }
    },
    resetAll () {
      this.$store.dispatch('repository/unload')
      this.selectedDocuments = []
      this.selectedDocumentsId = []
      this.selectedDocumentsIndex = []
      this.selectedContextDocument = null
      this.selectedSidebarDocument = null
      this.lastIndexClicked = null
      this.selectedFolder = null
      this.onDragFolderId = null
      this.currentFolder = null
      this.folders = []
      this.routes = []
      this.queryFolders.page = 1
      this.documents = []
      this.queryDocuments.page = 1
      this.identifierLoaderDocuments = Math.random()
      this.identifierLoaderFolders = Math.random()
    },
    refreshDocuments () {
      this.selectedDocuments = []
      this.selectedDocumentsId = []
      this.selectedDocumentsIndex = []
      this.selectedContextDocument = null
      this.selectedSidebarDocument = null
      this.lastIndexClicked = null
      this.selectedFolder = null
      this.onDragFolderId = null
      this.documents = []
      this.queryDocuments.page = 1
      this.identifierLoaderDocuments = Math.random()
    },
    unselectDocuments () {
      this.selectedDocuments = []
      this.selectedDocumentsId = []
      this.selectedDocumentsIndex = []
      this.selectedContextDocument = null
      this.selectedSidebarDocument = null
      this.lastIndexClicked = null
    },
    selectPreviousFolder (index) {
      if (this.routes.length - 1 === index) {
        this.selectedFolder = this.currentFolder
        this.selectedContextDocument = null
        this.selectedSidebarDocument = this.currentFolder
        this.onDragFolderId = null
        this.selectedDocuments = []
        this.selectedDocumentsId = []
        this.selectedDocumentsIndex = []
        this.lastIndexClicked = null
      }
    },
    selectOneFolder (folder, indexFolder) {
      this.selectedFolder = folder
      this.selectedContextDocument = null
      this.selectedSidebarDocument = folder
      this.onDragFolderId = null
      this.selectedDocuments = []
      this.selectedDocumentsId = []
      this.selectedDocumentsIndex = []
      this.lastIndexClicked = null
    },
    openContextMenuFolder (folder, indexDocument, ev) {
      this.selectedFolder = folder
      this.selectedContextDocument = folder
      this.selectedSidebarDocument = folder
      this.onDragFolderId = null
      this.selectedDocuments = [folder]
      this.selectedDocumentsId = [folder.id]
      this.selectedDocumentsIndex = [indexDocument]
      this.lastIndexClicked = indexDocument
      this.$refs.menu.open(ev)
    },
    selectOneDocument (document, indexDocument) {
      this.selectedFolder = null
      this.selectedDocuments = [document]
      this.selectedDocumentsId = [document.id]
      this.selectedDocumentsIndex = [indexDocument]
      this.selectedContextDocument = null
      this.selectedSidebarDocument = document
      this.lastIndexClicked = indexDocument
    },
    openContextMenuDocument (document, indexDocument, ev) {
      this.selectedFolder = null
      if (this.selectedDocuments.length < 2 || !this.selectedDocuments.includes(document)) {
        this.selectedDocuments = [document]
        this.selectedDocumentsId = [document.id]
        this.selectedDocumentsIndex = [indexDocument]
        this.selectedSidebarDocument = document
        this.selectedContextDocument = document
        this.lastIndexClicked = indexDocument
      }
      this.$refs.menu.open(ev)
    },
    toggleOneDocument (document, indexDocument) {
      this.selectedFolder = null
      this.toggleSelectDocument(document, indexDocument)
      this.selectedContextDocument = null
      if (this.selectedDocumentsIndex.length > 0) this.selectedSidebarDocument = null
      else this.selectedSidebarDocument = document
      this.lastIndexClicked = indexDocument
    },
    toggleSelectDocument (document, indexDocument) {
      if (this.selectedDocumentsIndex.includes(indexDocument)) {
        this.selectedDocuments = this.selectedDocuments.filter(d => d.id !== document.id)
        this.selectedDocumentsId = this.selectedDocumentsId.filter(id => id !== document.id)
        this.selectedDocumentsIndex = this.selectedDocumentsIndex.filter(index => index !== indexDocument)
      } else {
        this.selectedDocuments.push(document)
        this.selectedDocumentsId.push(document.id)
        this.selectedDocumentsIndex.push(indexDocument)
      }
    },
    toggleSelectDocumentShift (document, indexDocument) {
      this.selectedFolder = null
      this.selectedDocuments = []
      this.selectedDocumentsId = []
      this.selectedDocumentsIndex = []
      this.selectedSidebarDocument = null
      this.selectedContextDocument = null
      if (this.lastIndexClicked < indexDocument) {
        // seleccionando de arriba para abajo
        for (let i = this.lastIndexClicked; i <= indexDocument; i++) {
          const theDocument = this.documents[i]
          this.toggleSelectDocument(theDocument, i)
        }
      } else {
        for (let i = this.lastIndexClicked; i >= indexDocument; i--) {
          const theDocument = this.documents[i]
          this.toggleSelectDocument(theDocument, i)
        }
      }
    },
    toggleSortDocuments: function (nombre) {
      const lastQuery = this.queryDocuments
      if (nombre !== lastQuery.sort) {
        this.queryDocuments.sort = nombre
        this.queryDocuments.direction = 'asc'
      } else if (this.queryDocuments.direction === 'asc') {
        this.queryDocuments.direction = 'desc'
      } else if (this.queryDocuments.direction === 'desc') {
        this.queryDocuments.sort = null
        this.queryDocuments.direction = null
      }
      this.refreshDocuments()
    },
    updatePaginator (cual, data) {
      const aux = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url
      }
      switch (cual) {
        case 'folders':
          this.paginatorFolders = Object.assign({}, this.paginatorFolders, aux)
          break
        case 'documents':
          this.paginatorDocuments = Object.assign({}, this.paginatorDocuments, aux)
          break
      }
    },
    infiniteHandlerFolders ($state) {
      this.loadingFolders = true
      this.$http.get(this.urlFolders, { params: this.queryFolders })
        .then(({ data }) => {
          if (data.current_page === data.last_page) {
            const arrayFolders = this.prepareFolders(data.data)
            this.folders.push(...arrayFolders)
            this.loadingFolders = false
            $state.complete()
          }
          if (data.current_page < data.last_page) {
            this.queryFolders.page += 1
            const arrayFolders = this.prepareFolders(data.data)
            this.folders.push(...arrayFolders)
            $state.loaded()
          }
        })
    },
    infiniteHandlerDocuments ($state) {
      this.loadingDocuments = true
      this.$http.get(this.urlDocuments, { params: this.queryDocuments })
        .then(({ data }) => {
          this.loadingDocuments = false
          if (data.current_page === data.last_page) {
            const arrayDocuments = this.prepareDocuments(data.data)
            this.documents.push(...arrayDocuments)
            $state.complete()
          }
          if (data.current_page < data.last_page) {
            this.queryDocuments.page += 1
            const arrayDocuments = this.prepareDocuments(data.data)
            this.documents.push(...arrayDocuments)
            $state.loaded()
          }
        })
    },
    dobleClick (document) {
      switch (document.tipo_documento_id) {
        case 'carpeta':
          this.$router.push({ name: 'repositorioFolder', params: { idFolder: document.id } })
          break
        case 'documento':
        case 'recibo':
        case 'otroDocumento':
          this.$router.push({ name: 'documento', params: { id: document.id } })
          break
        case 'unidadCompuesta':
          this.$router.push({ name: 'unidad-compuesta', params: { id: document.id } })
          break
        default:
          this.$buefy.snackbar.open({
            message: 'Hubo un error al abrir el archivo',
            type: 'is-danger'
          })
          break
      }
    },
    middleClick (document) {
      let routeData = null
      switch (document.tipo_documento_id) {
        case 'carpeta':
          routeData = this.$router.resolve({ name: 'repositorioFolder', params: { idFolder: document.id } })
          window.open(routeData.href, '_blank')
          break
        case 'documento':
        case 'recibo':
        case 'otroDocumento':
          routeData = this.$router.resolve({ name: 'documento', params: { id: document.id } })
          window.open(routeData.href, '_blank')
          break
        case 'unidadCompuesta':
          routeData = this.$router.resolve({ name: 'unidad-compuesta', params: { id: document.id } })
          break
        default:
          this.$buefy.snackbar.open({
            message: 'Hubo un error al abrir el archivo',
            type: 'is-danger'
          })
          break
      }
    },
    dobleClickPreviousFolders (route) {
      this.$router.push('/repositorio/' + route)
    },
    toPreviousFolder () {
      if (this.routes.length === 1 && this.previousFolder === null && this.$store.getters.hasAnyRole(['lyris']) && this.routes[0] !== '') {
        this.$router.push('/repositorio/')
        return
      }
      if (this.previousFolder !== null) {
        this.$router.push('/repositorio/' + this.previousFolder)
        return
      }
      this.$buefy.snackbar.open({
        message: 'No puede volver para atrás',
        type: 'is-warning',
        position: 'is-bottom-left'
      })
    },
    getIconFile (tipo) {
      switch (tipo) {
        case 'carpeta':
          return 'far fa-folder'
        case 'recibo':
          return 'fas fa-dollar-sign'
        case 'unidadCompuesta':
          return 'far fa-file-archive'
        default:
          return 'far fa-file'
      }
    },
    getCurrentFolderReadableName (currentFolder) {
      if (!this.currentFolder) return '?'
      const arrRoute = this.currentFolder.ruta.split('/')
      return arrRoute.pop()
    },
    closeSidebar () {
      this.selectedSidebarDocument = null
    },
    prepareDocuments (arrayDocuments) {
      return arrayDocuments.filter(e => e.nombre !== '.')
    },
    prepareFolders (arrayFolders) {
      const anchor = arrayFolders.find(e => e.nombre === '.')
      // this.rutas = anchor ? anchor.ruta.split('/') : null;
      this.$emit('update:rutas', anchor ? anchor.ruta.split('/') : null)
      this.routes = anchor ? anchor.ruta.split('/') : null
      this.currentFolder = arrayFolders.find(doc => {
        return doc.nombre === '.'
      })
      this.$store.commit('repository/currentFolder', this.currentFolder)
      return arrayFolders.filter(e => e.nombre !== '.')
    }
  },
  computed: {
    urlFolders () {
      let theUrl = null
      if (!this.idFolder) {
        theUrl = '/carpeta'
      } else if (isNaN(parseInt(this.idFolder))) {
        theUrl = '/carpeta/' + this.idFolder
      } else {
        theUrl = '/carpeta/' + this.idFolder
      }
      return theUrl
    },
    urlDocuments () {
      let theUrl = null
      if (!this.idFolder) {
        theUrl = '/carpeta'
      } else if (isNaN(parseInt(this.idFolder))) {
        theUrl = '/carpeta/' + this.idFolder
      } else {
        theUrl = '/carpeta/' + this.idFolder
      }
      return theUrl
    },
    previousFolder: function () {
      return this.currentFolder && this.currentFolder.origen_id
    },
    showSidebar: function () {
      if (this.selectedSidebarDocument) {
        return true
      }
      return false
    },
    canGoToPreviousFolder () {
      if (this.currentFolder) {
        if (this.routes.length === 1 && this.previousFolder === null && this.$store.getters.hasAnyRole(['lyris']) && this.routes[0] !== '') return true
        if (this.previousFolder !== null) return true
      }
      return false
    }
  },
  watch: {
    selectedDocumentsIndex (newVal, oldVal) {
      this.$store.commit('repository/selectedDocumentsIndex', newVal)
    },
    selectedDocuments (newVal, oldVal) {
      this.$store.commit('repository/selectedDocuments', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.row-folder{
  padding: 5px 20px 5px 0px;
  margin: 0px 0;
  background-color: transparent;
  // transition: background-color .2s ease-in;
  white-space: nowrap;
  user-select: none;
  overflow: hidden !important;
  text-overflow: ellipsis;
  &:hover{
    background-color: #f3f3f3;
    .previous-unloaded-folders {
      color: #4a4a4a;

    }
  }
  &.selected{
    background-color: $primary;
    color: #FFF;
    // transition: background-color 0 ease-in;
  }
  &.dropping{
    background-color: orange;
    color: #FFF;
    // transition: background-color 0 ease-in;
    .previous-unloaded-folders{
      color: #FFF;
    }
  }
  .previous-unloaded-folders{
    color: #a8a8a8;
  }
}
.row-folder-current{
  padding: 5px 20px;
  margin: 0px 0;
  background-color: transparent;
  // transition: background-color .2s ease-in;
  white-space: nowrap;
  user-select: none;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.row-folder-header{
  padding: 5px 20px 10px;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 6px;
  background-color: transparent;
  user-select: none;
}
.documents-container{
  position: relative;
  .overlay-dragging-files{
    position: absolute;
    background-color: transparent;
    display: none;
    transition: background-color 0.3s ease;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    &.active{
    background-color: rgba($primary,0.9);
    display: flex;
    }
  }
}
.row-file{
  padding: 5px 20px;
  margin: 0px 0;
  background-color: transparent;
  // transition: background-color .2s ease-in;
  display: flex;
  flex-direction: row;
  align-items: center;
  .row-file-name{
    white-space: nowrap;
    overflow-x: hidden !important;
    text-overflow: ellipsis;
    width: 100%;
  }
  .row-file-size, .row-file-date{
    width: 100px;
    text-align: center;
  }
  &:hover{
    background-color: #f3f3f3;
  }
  &.selected{
    background-color: $primary;
    color: #FFF;
  }
  user-select: none;
}
.row-file-header{
  display: flex;
  padding: 5px 20px 10px;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 6px;
  .row-file-name{
    width: 100%;
  }
  .row-file-size, .row-file-date{
    width: 100px;
    text-align: center;
  }
}
.has-text-kinda-dark{
  color: #CACACA;
}
.sidebar-container {
  @media screen and (min-width: $tablet){
    width: 270px;
  }
}
.folders-container{
  position: sticky;
  top: 10px;
}
</style>
