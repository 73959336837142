import { render, staticRenderFns } from "./Repositorio.vue?vue&type=template&id=0b9afaf9&scoped=true&"
import script from "./Repositorio.vue?vue&type=script&lang=js&"
export * from "./Repositorio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b9afaf9",
  null
  
)

export default component.exports