<template>
  <div
    class="context-menu"
    v-if="show && this.selectedDocuments"
    :style="style"
    ref="context"
    tabindex="0"
    @blur="close"
  >
    <ul>
      <li v-if="onlyOneFileSelected"
          @click="canOpenFile? openFile() : openWarningSnackBar()"
          :class="canOpenFile? '' : 'disabled-option'">
        <a :class="canOpenFile? 'has-text-dark' : 'disabled-option'">
          <i class="fas fa-external-link-alt fa-fw"></i>&nbsp;Abrir en nueva pestaña
        </a>
      </li>
      <li v-if="allFilesAreType('documento')"
          @click="canSignAll? addAllToPila() : openWarningSnackBar()"
          :class="canSignAll? '' : 'disabled-option'">
        <a :class="canSignAll? 'has-text-dark' : 'disabled-option'">
          <i class="fas fa-plus fa-fw"></i>
          <i class="fas fa-inbox fa-fw"></i>&nbsp;Agregar para firmar
        </a>
      </li>
      <li v-if="allFilesAreType('documento')"
          @click="canDownloadAll? downloadAll() : openWarningSnackBar()"
          :class="canDownloadAll? '' : 'disabled-option'">
        <a :class="canDownloadAll? 'has-text-dark' : 'disabled-option'">
          <i class="fas fa-download fa-fw"></i>&nbsp;Descargar
        </a>
      </li>
      <li @click="canMoveAll? moveAll() : openWarningSnackBar()"
          :class="canMoveAll? '' : 'disabled-option'">
        <a :class="canMoveAll? 'has-text-dark' : 'disabled-option'">
          <i class="fas fa-dolly fa-fw"></i>&nbsp;Mover
        </a>
      </li>
      <li v-if="onlyOneFileSelected && !atLeastOneIsType('recibo')"
          @click="canRenameDocument? renameDocument() : openWarningSnackBar()"
          :class="canRenameDocument? '' : 'disabled-option'">
        <a :class="canRenameDocument? 'has-text-dark' : 'disabled-option'">
          <i class="fas fa-terminal fa-fw"></i>&nbsp;Renombrar
        </a>
      </li>
      <li v-if="onlyOneFileSelected && allFilesAreType('carpeta')" @click="openPermissions()">
        <a class="has-text-dark">
          <i class="fas fa-users-cog fa-fw"></i>&nbsp;Gestionar permisos
        </a>
      </li>
      <li @click="canDeleteAll? deleteAll() : openWarningSnackBar()"
          :class="canDeleteAll? 'last-option' : 'last-option disabled-option'">
        <a :class="canDeleteAll? 'has-text-danger' : 'disabled-option'">
          <i class="fas fa-trash fa-fw"></i>&nbsp;Eliminar
        </a>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'CmpContextMenu',
  props: {
    selectedDocuments: []
  },
  data () {
    return {
      left: 0, // left position
      top: 0, // top position
      show: false, // affect display of context menu
      minimumLevelPermissionTo: {
        watch: 1,
        download: 1,
        // downloadConfidential: 2, // Aun no se utiliza, pero se planea usar en el futuro
        sign: 3,
        rename: 6,
        move: 7,
        delete: 7
      }
    }
  },
  methods: {
    openWarningSnackBar () {
      this.$buefy.snackbar.open({
        message: this.messageSnackbar,
        type: 'is-danger'
      })
    },
    close () {
      this.show = false
      this.left = 0
      this.top = 0
    },
    open (evt) {
      this.left = evt.pageX
      this.top = evt.pageY
      this.show = true
    },
    addAllToPila: function () {
      this.selectedDocuments.forEach(file => this.$store.commit('firmador/addPila', file))
      this.close()
    },
    downloadAll: function () {
      this.$buefy.snackbar.open({
        message:
          'Comprimiendo los documentos seleccionados para descargar, aguarde un momento',
        type: 'is-success'
      })
      const selectedIds = this.selectedDocuments.map(file => file.id)
      this.$http
        .get('/documento/' + selectedIds.join(',') + '/descarga')
        .then(response => {
          window.location.href = response.data.link
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message:
              'Ha ocurrido un error con la descarga',
            type: 'is-danger'
          })
        })
      this.close()
    },
    openFile: function () {
      let routeData = null
      switch (this.uniqueSelectedFile.tipo_documento_id) {
        case 'carpeta':
          routeData = this.$router.resolve({ name: 'repositorio', params: { idFolder: this.uniqueSelectedFile.id } })
          window.open(routeData.href, '_blank')
          break
        case 'documento':
        case 'recibo':
        case 'otroDocumento':
          routeData = this.$router.resolve({ name: 'documento', params: { id: this.uniqueSelectedFile.id } })
          window.open(routeData.href, '_blank')
          break
        case 'unidadCompuesta':
          routeData = this.$router.resolve({ name: 'unidad-compuesta', params: { id: this.uniqueSelectedFile.id } })
          window.open(routeData.href, '_blank')
          break
        default:
          this.$buefy.snackbar.open({
            message: 'Hubo un error al abrir el archivo',
            type: 'is-danger'
          })
          break
      }
    },
    openPermissions: function () {
      this.$store.commit('repository/selectedDocument', this.uniqueSelectedFile)
      this.$emit('folder-permissions')
      this.close()
    },
    deleteAll: function () {
      this.$emit('delete-multiple-documents')
      this.close()
    },
    moveAll: function () {
      this.$emit('move-multiple-documents')
      this.close()
    },
    renameDocument: function () {
      this.$store.commit('repository/selectedDocument', this.uniqueSelectedFile)
      this.$emit('rename-document')
      this.close()
    },
    allFilesAreType (type) {
      return this.selectedDocuments.every(file => type === file.tipo_documento_id)
    },
    atLeastOneIsType (type) {
      return this.selectedDocuments.some(file => type === file.tipo_documento_id)
    },
    userPermissionsOverMinimumLevel (permissions, minimumLevelPermission) {
      const userPermissions = permissions.find(perm => (perm.usuario && perm.usuario.id === this.user.id))
      return (userPermissions && userPermissions.nivel >= minimumLevelPermission)
    },
    areaPermissionsOverMinimumLevel (permissions, minimumLevelPermission) {
      const areaPermissions = permissions.find(perm => (perm.area && this.user.areas.some(idArea => idArea === perm.area.id)))
      return (areaPermissions && areaPermissions.nivel >= minimumLevelPermission)
    },
    organizationPermissionsOverMinimumLevel (permissions, minimumLevelPermission) {
      const organizationPermissions = permissions.find(perm => (perm.organizacion && perm.organizacion.id === this.user.organization))
      return (organizationPermissions && organizationPermissions.nivel >= minimumLevelPermission)
    },
    hasPermissionLevelOver (minimumLevelPermission) {
      return this.selectedDocuments.every(file => {
        return (this.userPermissionsOverMinimumLevel(file.permisos, minimumLevelPermission) ||
                this.areaPermissionsOverMinimumLevel(file.permisos, minimumLevelPermission) ||
                this.organizationPermissionsOverMinimumLevel(file.permisos, minimumLevelPermission))
      })
    }
  },
  computed: {
    messageSnackbar () {
      if (this.onlyOneFileSelected) {
        if (this.uniqueSelectedFile.tipo_documento_id === 'carpeta') {
          return 'No cuenta con el nivel de permiso suficiente sobre la carpeta'
        }
        return 'No cuenta con el nivel de permiso suficiente sobre el documento'
      }

      return 'No cuenta con el nivel de permiso suficiente sobre algunos documentos'
    },
    user: function () {
      return this.$store.getters.getUser
    },
    onlyOneFileSelected () {
      return this.selectedDocuments.length === 1
    },
    uniqueSelectedFile () {
      return this.selectedDocuments[0]
    },
    allArePDFs () {
      return this.selectedDocuments.every(file => file.mime === 'application/pdf')
    },
    canOpenFile () {
      return this.hasPermissionLevelOver(this.minimumLevelPermissionTo.watch)
    },
    canSignAll () {
      return (this.allArePDFs && this.hasPermissionLevelOver(this.minimumLevelPermissionTo.sign))
    },
    canDownloadAll () {
      return this.hasPermissionLevelOver(this.minimumLevelPermissionTo.download)
    },
    canMoveAll () {
      return this.hasPermissionLevelOver(this.minimumLevelPermissionTo.move)
    },
    canDeleteAll () {
      return this.hasPermissionLevelOver(this.minimumLevelPermissionTo.delete)
    },
    canRenameDocument () {
      return this.hasPermissionLevelOver(this.minimumLevelPermissionTo.rename)
    },
    style () {
      return {
        top: this.top + 'px',
        left: this.left + 'px'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.context-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  z-index: 999;
  outline: none;
  cursor: pointer;
  min-width: 180px;
  user-select: none;
}

ul li {
  user-select: none;
  padding: 5px 10px;
  font-size: 0.75rem;
  &:hover {
    background-color: #f3f3f3;
  }
}
.last-option{
  padding-top: 5px;
  border-top: 1px solid #dddddd
}
.disabled-option {
  cursor: not-allowed;
  color: #808080
}
</style>
