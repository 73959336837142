<template>
  <div class="box">
    <h1 class="subtitle is-5">Administrar organización</h1>
      <h1 class="title is-1">Nueva área</h1>
      <div class="content">
        <p>Ingrese los datos del área a registrar para la organización. Una vez que finalizó de cargar los datos, presione el botón
      <span class="tag is-primary">Guardar</span></p>
      </div>
      <hr>
    <div class="notification is-warning" v-if="error">{{error}}</div>
    <div class="field">
      <label class="label">Nombre</label>
      <div class="control">
        <input
          v-model="model.nombre"
          name="nombre"
          class="input"
          :class="{'is-danger': errors.has('nombre') }"
          v-validate="{required:true}"
          @keyup.enter="submit()"
          v-autofocus
        >
        <span class="help is-danger" v-if="errors.has('nombre')">{{errors.first('nombre')}}</span>
      </div>
    </div>
    <b-field label="Depende de">
      <b-select :loading="isLoading" v-model="model.dependencia_id" key="lista-areas" expanded>
        <option :value="null">{{isLoading ? "Espere un momento" : "Ninguna"}}</option>
        <option v-for="opcion in areas" :value="opcion.id" :key="opcion.id">{{ opcion.nombre }}</option>
      </b-select>
    </b-field>
    <br>
    <div class="field is-clearfix">
      <button @click.prevent="submit()" class="button is-primary is-pulled-right">
        <i class="fas fa-save fa-fw"></i>Guardar
      </button>
    </div>
  </div>
</template>

<script>
// import router from '@/router'

export default {
  props: ['idOrganization'],
  data () {
    return {
      model: {
        nombre: '',
        dependencia_id: null
      },
      error: '',
      areas: [],
      isLoading: true,
      showHelp: false
    }
  },
  created () {
    this.obtenerAreas()
  },
  methods: {
    obtenerAreas () {
      this.isLoading = true
      this.startLoading()
      this.$http
        .get('/organizacion/' + this.idOrganization + '/area')
        .then(response => {
          return new Promise(resolve =>
            setTimeout(() => resolve(response), 500)
          )
        })
        .then(response => {
          this.areas = response.data.data
          this.isLoading = false
          this.stopLoading()
        })
        .catch(error => {
          this.$emit('fail', error)
          console.log('Fetching data failed', error)
          this.stopLoading()
        })
    },
    submit () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$buefy.snackbar.open({
            message: 'Hay errores en el formulario',
            type: 'is-danger'
          })
          return
        }
        this.$http
          .post(`/organizacion/${this.idOrganization}/area`, this.payload)
          .then(response => {
            this.$router.push({
              name: 'organizacionAreas',
              params: { id: this.id }
            })
            this.$buefy.snackbar.open({
              message: 'Área creada exitosamente',
              type: 'is-success'
            })
          })
          .catch(error => {
            console.log(error)
            this.$buefy.snackbar.open({
              message: 'El servidor respondio con un error',
              type: 'is-danger'
            })
          })
      })
    }
  },
  computed: {
    payload: function () {
      const p = {}
      p.nombre = this.model.nombre
      if (this.model.dependencia_id) {
        p.dependencia_id = this.model.dependencia_id
      }
      return p
    }
  }
}
</script>
