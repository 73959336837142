<template>
    <div class="box">
        <article class="media">
            <figure class="media-left">
                <i class="fas fa-building fa-4x fa-fw"></i>
            </figure>
            <div class="media-content">
                <div class="field is-grouped is-pulled-right" v-show="$store.getters.hasAnyRole(['lyris','admin'])">
                    <p class="control">
                        <b-tooltip label="Editar" position="is-left">
                            <router-link :to="{ name: 'organizacionEditar', params: { idOrganization: idOrganization }}" class="button is-black is-outlined">
                                <i class="fas fa-edit"></i>
                            </router-link>
                        </b-tooltip>
                    </p>
                </div>
                <h1 class="title is-1">{{organizacion.razon_social}}</h1>
                <h1 class="subtitle is-6 is-mono">{{organizacion.slug}}</h1>
                <div class="content">
                    <table class="table is-narrow">
                        <tbody>
                            <tr>
                                <th width="150">CUIT</th>
                                <td>{{organizacion.cuit ? organizacion.cuit : "Sin Datos"}}</td>
                            </tr>
                            <tr>
                                <th width="150">Dirección</th>
                                <td>{{organizacion.direccion ? organizacion.direccion : "Sin Datos"}}</td>
                            </tr>
                            <tr>
                                <th width="150">Telefono</th>
                                <td>{{organizacion.telefono ? organizacion.telefono : "Sin Datos"}}</td>
                            </tr>
                            <tr>
                                <th width="150">Email</th>
                                <td v-if="organizacion.email">
                                    <a :href="'mailto:' + organizacion.email">{{organizacion.email}}</a>
                                </td>
                                <td v-else>Sin datos</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
export default {
  props: ['idOrganization'],
  data () {
    return {}
  },
  computed: {
    organizacion: function () {
      return this.$store.state.organizacion.organizacion
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  border-radius: 100px;
}
</style>
