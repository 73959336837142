<template>
  <div class="box">
    <h1 class="subtitle is-5">Administrar organización</h1>
    <h1 class="title is-1">Editar aspecto</h1>
    <hr>
    <div class="notification" v-if="fetching">
      <i class="fas fa-sync fa-spin"></i> Cargando aspecto...
    </div>
    <aspectos-form @submit="submit" :edit="true" :previousData="aspecto" v-if="ready"></aspectos-form>
  </div>
</template>

<script>
import AspectosForm from '@/components/globals/aspectos/Form'

export default {
  props: ['idOrganization', 'idAspecto'],
  components: {
    AspectosForm
  },
  data () {
    return {
      fetching: true,
      ready: false,
      aspecto: null
    }
  },
  beforeMount: function () {
    this.fetchAspecto()
  },
  methods: {
    fetchAspecto: function () {
      this.fetching = false
      this.startLoading()
      this.$http.get(`/organizacion/${this.idOrganization}/aspecto`)
        .then(result => {
          this.aspecto = result.data.find(asp => {
            return asp.id === this.idAspecto
          })
          this.fetching = false
          this.ready = true
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio con un error.',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    submit: function (payload) {
      this.startLoading()
      this.$http
        .put(`/aspecto/${this.idAspecto}`, payload)
        .then(result => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: 'El aspecto ha sido editado exitosamente',
            type: 'is-success'
          })
          this.$router.push({ name: 'organizacionAspectos', params: { idOrganization: this.idOrganization } })
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio con un error.',
            type: 'is-danger'
          })
        })
    }
  }

}
</script>

<style>
</style>
