<template>
<section>
  <div class="box">
    <h1 class="subtitle is-5">Aspectos globales</h1>
    <h1 class="title is-1">Listado</h1>
    <div class="content">
      <p>Los aspectos globales de documentos son conjuntos de aspectos documentales disponibles para ser usadas por todas las organizaciones</p>
    </div>
    <hr>
     <router-link :to="{ name: 'crearAspectosGlobales' }" class="button is-outlined is-primary is-fullwidth" exact>
              <i class="fas fa-plus fa-fw"></i>&nbsp;Nuevo aspecto global
            </router-link>
  </div>
  <div class="box">
            <h1 class="subtitle is-5">
      Aspectos globales de documentos disponibles
    </h1>
    <b-table :data="aspectos" paginated per-page="20" detailed detail-key="id">
        <b-table-column field="nombre" label="Nombre" sortable v-slot="props">
          <b>{{ props.row.nombre }}</b>
          <p v-if="props.row.descripcion" class="is-size-7">{{props.row.descripcion}}</p>
        </b-table-column>
        <b-table-column label="Atributos" v-slot="props">{{ atributosList(props.row.atributos).join(', ') }}</b-table-column>
        <b-table-column centered width="75" v-slot="props">
          <p> <router-link
            :to="{name: 'adminAspectosGlobalesEditar', params: {idAspecto: props.row.id } }"
            class="is-size-7">
            <i class="fas fa-edit"></i> Editar
          </router-link></p>
          <p><a
            @click="confirmDelete(props.row.id)"
            class="has-text-danger is-size-7"
          >
            <i class="fas fa-trash"></i> Eliminar
          </a></p>
        </b-table-column>
      <template slot="detail" slot-scope="props">
        <article class="media" v-for="attr in props.row.atributos" :key="attr.id">
          <div class="media-content">
            <span class="tag is-dark is-mono is-pulled-right">{{attr.id}}</span>
            <b>{{attr.nombre}}</b>
            <span class="has-text-danger" v-if="attr.obligatorio">*</span>
            <br>
            <p v-if="attr.descripcion" class="is-size-7">
              <span class="has-text-primary" v-if="attr.obligatorio">(Opcional)</span>
              <span class>({{getNombreAtributo(attr.tipo)}})</span>
              {{attr.descripcion}}
              <span v-if="attr.tipo == 'enum'"><br><b>Lista de opciones:</b> {{attr.parametros.opciones.join(' • ')}}</span>
            </p>
          </div>
        </article>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p class="is-mono">Sin aspectos</p>
          </div>
        </section>
      </template>
    </b-table>
    <b-modal :active.sync="showConfirmDelete">
      <div class="notification is-light">
        <h1 class="title is-3">
          <i class="fas fa-exclamation-triangle"></i>&nbsp;Atención
        </h1>
        <h1 class="subtitle is-6">
          Esta por realizar una acción sin retorno. ¿Confirma que desea eliminar el aspecto?
        </h1>
        <div class="is-clearfix">
          <button @click="showConfirmDelete = false" class="button is-dark is-outlined">
            Cancelar
          </button>
          <button @click="deleteResource()" class="button is-danger is-pulled-right">
            Eliminar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
  </section>
</template>

<script>
import attributesUtils from '@/mixins/attributesUtils'

export default {
  mixins: [attributesUtils],
  data () {
    return {
      aspectos: [],
      showConfirmDelete: false,
      toDelete: null
    }
  },
  mounted: function () {
    this.getTable()
  },
  methods: {
    getTable: function () {
      this.startLoading()
      this.$http
        .get('/aspecto')
        .then(response => {
          this.stopLoading()
          this.aspectos = response.data
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio con un error.',
            type: 'is-danger'
          })
        })
    },
    atributosList: function (arrAtributos) {
      return arrAtributos.map(att => att.nombre)
    },
    confirmDelete: function (id) {
      this.toDelete = id
      this.showConfirmDelete = true
    },
    deleteResource: function () {
      this.startLoading()
      this.$http
        .delete('/aspecto/' + this.toDelete)
        .then(response => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: 'El aspecto ha sido eliminado.',
            type: 'is-success'
          })
          this.getTable()
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondio con un error.',
            type: 'is-danger'
          })
        })
      this.showConfirmDelete = false
    }
  }
}
</script>
