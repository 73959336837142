<template>
  <div class="box">
    <div class="field">
      <label for class="label">Tipo</label>
      <div class="control">
        <div class="select is-fullwidth">
          <select v-model="query.tipoDocumento">
            <option :value="null">- Todos -</option>
            <option value="carpeta">Carpetas</option>
            <option value="documento">Documentos</option>
            <option value="recibo" v-if="$store.getters.hasAnyRole(['empleador', 'empleado'])">Recibos</option>
            <option value="subunidad" v-if="$store.getters.hasAnyRole(['serie-user','serie-admin'])">Subunidades</option>
            <option value="unidadCompuesta" v-if="$store.getters.hasAnyRole(['serie-user','serie-admin'])">Unidades compuestas</option>
          </select>
        </div>
      </div>
    </div>
    <div class="field is-clearfix" v-if="$store.getters.hasAnyRole(['aspec-user','aspec-admin','lyris'])">
      <div class="control is-pulled-right">
        <a @click="openAspectosModal" class="">
          <i class="fas fa-plus"></i>
        </a>
      </div>
      <label class="label is-pulled-left">Aspectos</label>
      <div class="control">
        <div class="tags">
          <div class="tags has-addons" v-for="(aspecto,i) in aspectos" :key="i">
            <span class="tag is-link">{{aspecto.name}}</span>
            <a class="tag is-delete" @click="removeAspecto(aspecto)"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-clearfix" v-if="$store.getters.hasAnyRole(['aspec-user','aspec-admin','lyris'])">
      <div class="control is-pulled-right">
        <a @click="openMetadatosModal" class="">
          <i class="fas fa-plus"></i>
        </a>
      </div>
      <label class="label is-pulled-left">Metadatos</label>
      <div class="control">
        <div class="tags">
          <div class="tags has-addons" v-for="(metadato,i) in metadatos" :key="i">
            <span class="tag is-link">{{metadato.name}}</span>
            <a class="tag is-delete" @click="removeMetadatosList(metadato.value)"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="field" v-if="query.tipoDocumento == 'unidadCompuesta' && $store.getters.hasAnyRole(['serie-user','serie-admin'])">
      <label for class="label">Serie documental</label>
      <div class="control">
        <pertenencia-series-documentales @setSerie="setSerieDocumental" @setEstadoSerie="setEstadoSerie"></pertenencia-series-documentales>
      </div>
    </div>
    <div class="field is-clearfix" v-if="$store.getters.hasAnyRole(['user'])">
      <label class="label" :class="{'is-pulled-left': explorerLoading}">Carpeta raiz</label>
      <i class="fas fa-lg fa-circle-notch fa-spin is-pulled-rigth" v-if="explorerLoading"></i>
      <a @click="selected = null" v-if="selected" class="is-pulled-right">
        <i class="fas fa-times"></i>
      </a>
      <p v-if="selected" class="help">{{selected.ruta}}</p>
      <button
        @click="showExplorerModal = true"
        v-if="!selected"
        class="button is-primary is-small is-outlined is-fullwidth"
      >Elegir carpeta</button>
      <b-modal ref="explorerModal" :active.sync="showExplorerModal" scroll="keep">
        <div class="card">
          <div class="card-content">
            <h1 class="title is-5">
              <i class="fas fa-arrow-right"></i>&nbsp;Elija la carpeta raiz
            </h1>
            <div class="field">
            <div class="control">
              <a class="button is-static is-fullwidth has-text-left">{{getRuta}}</a>
            </div>
          </div>
            <explorer :initFolder="initRoute" :slug="organizacionSlug" ref="listado" :rutas.sync="rutas" :selected.sync="selected"></explorer>
             <span class="help">Elija la carpeta donde se guardaran los recibos.</span>
            <br>
            <button
              class="button is-dark is-outlined is-fullwidth"
              type="button"
              @click="$refs.explorerModal.close()"
            >Cerrar</button>
          </div>
        </div>
      </b-modal>
    </div>
    <div class="field">
      <label for class="label">Otros filtros</label>
      <b-checkbox v-model="query.confidencial">
        <i class="fas fa-lock"></i>&nbsp; Solo confidenciales
      </b-checkbox>
    </div>
  </div>
</template>

<script>
import Explorer from '@/components/utils/Explorer'
import ModalAspectos from './ModalAspectos'
import ModalMetadatos from './ModalMetadatos'
import PertenenciaSeriesDocumentales from './filtros/PertenenciaSeriesDocumentales'
export default {
  props: ['query'],
  components: {
    Explorer,
    PertenenciaSeriesDocumentales
  },
  data () {
    return {
      rutas: null,
      selected: null,
      showExplorerModal: false,
      explorerLoading: true,
      initRoute: null,
      organizacionSlug: null,
      metadatos: [],
      aspectos: []
    }
  },
  mounted: function () {
    if (this.$store.getters.hasAnyRole(['user'])) {
      this.fetchCarpetaRoot()
    }
  },
  methods: {
    fetchCarpetaRoot: function () {
      let folder = null
      this.$http
        .get('/carpeta')
        .then(res => {
          folder = res.data.data.find(carpeta => {
            if (carpeta.nombre === '.') return carpeta
          })
          this.initRoute = folder.ruta.split('/')[0]
          this.organizacionSlug = folder.ruta.split('/')[0]
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.explorerLoading = false
        })
    },
    openMetadatosModal: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalMetadatos,
        events: {
          addMetadatoQuery: m => {
            this.metadatos.push(m)
            this.query.metadatos.push(m.value)
          }
        }
      })
    },
    addMetadatosList: function (m) {
      this.metadatos.push(m)
      this.query.metadatos = this.metadatos.map(m => {
        return m.value
      })
    },
    removeMetadatosList: function (value) {
      this.metadatos = this.metadatos.filter(m => {
        return m.value !== value
      })
      this.query.metadatos = this.metadatos.map(m => {
        return m.value
      })
    },
    openAspectosModal: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalAspectos,
        events: {
          addAspectoQuery: aspecto => {
            if (this.aspectos.some(a => a.id === aspecto.id)) return
            this.aspectos.push(aspecto)
            this.query.aspectos.push(aspecto.id)
          }
        }
      })
    },
    removeAspecto: function (aspecto) {
      this.aspectos = this.aspectos.filter(a => a.id !== aspecto.id)
      this.query.aspectos = this.aspectos.map(a => a.id)
    },
    setSerieDocumental: function (serieId) {
      this.query.serie = serieId
    },
    setEstadoSerie: function (serieEstado) {
      this.query.estado = serieEstado
    }
  },
  computed: {
    getRuta: function () {
      if (this.selected == null) {
        return this.organizacionSlug
      } else {
        return this.selected.ruta
      }
    },
    organizacion: function () {
      return this.$store.getters.getUser.organization
    }
  },
  watch: {
    selected: function (newVal, oldVal) {
      if (newVal) this.query.origen_id = newVal.id
      else this.query.origen_id = null
    },
    'query.tipoDocumento': function (newVal, oldVal) {
      if (oldVal === 'unidad-compuesta' && newVal !== 'unidadCompuesta') {
        this.query.serie = null
      }
    }
  }
}
</script>

<style>
.field:not(:last-child) {
  margin-bottom: 1.8em;
}
</style>
