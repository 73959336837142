<template>
  <section>
        <div class="field">
          <label class="label" :class="{'has-text-danger': errors.has('nombre','serie') }">Nombre del evento</label>
          <div class="control">
            <input
              v-model="model.nombre"
              name="nombre"
              class="input"
              placeholder="Requerido"
              data-vv-scope="serie"
              :class="{'is-danger': errors.has('nombre','serie') }"
              v-validate="{required:true}"
            >
          </div>
          <span
            class="help is-danger"
            v-if="errors.has('nombre','serie')"
          >{{errors.first('nombre','serie')}}</span>
        </div>
        <!-- ============================= -->

        <div class="field">
          <label class="label">¿Que acción ejecuta el evento?</label>
          <div class="control" v-for="accion in accionesDisponibles" :key="accion.id">
            <a @click="addAccion(accion)"><i class="fas fa-plus-circle"></i></a>&nbsp;&nbsp;{{accion.nombre}}
          </div>
          <p v-for="(inc,index) in incompatibilidades" class="help is-danger" :key="index"><i class="fas fa-times-circle"></i>&nbsp;"{{inc.queAccion}}" es incompatible con "{{inc.cualAccion}}"</p>
        </div>
        <!-- Parametros accion ============================= -->
        <parametros :ref="'param-' + accion.id + `-${index}`" v-for="(accion,index) in model.accion" :key="accion.specialKey" :accion="accion" @delete="deleteAccion(accion.specialKey)"></parametros>
        <!-- ============================= -->
        <div class="field">
          <label class="label">Curso de los estados</label>
        </div>
        <div class="columns">
          <div class="column">
            <div class="notification">
              <h1 class="title is-5">Estados Iniciales</h1>
              <p class="subtitle is-7">Seleccione el o los estados iniciales</p>
                <div class="field" v-for="(nombreEstado, key) in serieDocumental.estados" :key="key">
                  <b-checkbox v-model="model.estadosIniciales" :native-value="key">{{nombreEstado}}</b-checkbox>
                </div>
            </div>
          </div>
          <div class="column is-narrow is-hidden-touch">
            <br>
            <h1 class="title is-5 is-marginless">
              <i class="fas fa-arrow-right"></i>
            </h1>
          </div>
          <div class="column">
            <div class="notification">
              <h1 class="title is-5">Estado Final</h1>
              <p class="subtitle is-7">Seleccione aquí el estado final</p>
              <div class="field">
                  <b-radio v-model="model.estadoFinal" :native-value="null"><i>Mantener el estado inicial</i></b-radio>
                </div>
              <div class="field" v-for="(nombreEstado, key) in serieDocumental.estados" :key="key">
                  <b-radio v-model="model.estadoFinal" :native-value="key">{{nombreEstado}}</b-radio>
                </div>
            </div>
          </div>
        </div>
        <div v-if="areasDisponibles.length > 0">
          <div class="field">
            <b-switch v-model="habilitarRestringirAreas"><label for="" class="label">Habilitar restringir por areas</label></b-switch>
          </div>
          <div v-if="habilitarRestringirAreas">
            <div class="block my-4" v-if="areasDisponibles.length">
              <b-checkbox v-model="model.areasSeleccionadas"
                v-for="area in areasDisponibles"
                :native-value="area.id"
                :key="area.id">
                {{area.nombre}}
              </b-checkbox>
            </div>
            <div class="content">
              <p class="" v-if="model.areasSeleccionadas.length == 0">
                <i class="fas fa-info-circle fa-lg has-text-link"></i>&nbsp;No ha seleccionado ningun area en particular. Por lo tanto, todas las areas e individuos con privilegios pueden ejecutar el evento
              </p>
            </div>
          </div>
        </div>
        <div class="field" v-else>
          <label class="label">Habilitar restringir por areas</label>
          <p class="control">
            <i class="fas fa-info-circle fa-lg has-text-link"></i>&nbsp;La organización no cuenta con areas para restringir las acciones por areas
          </p>
        </div>
        <div class="buttons is-right">
        <a @click="addEvento" class="button is-primary"><i class="fas fa-plus"></i>&nbsp;Agregar</a>
        </div>
      <!-- </div>
    </div> -->
  </section>
</template>

<script>
import Parametros from './ParametrosAccion'
import validatorUtils from '@/mixins/validatorUtils'

export default {
  data () {
    return {
      model: {
        nombre: '',
        estadosIniciales: [],
        estadoFinal: undefined,
        accion: [],
        areasSeleccionadas: []
      },
      incompatibilidades: [],
      randomNumber: 0,
      // --------------
      habilitarRestringirAreas: false
    }
  },
  // created: function () {
  //   // Crear array de objects estados
  //   this.estadosDisponibles = Object.keys(this.estados).map(key => {
  //     return {
  //       key: key,
  //       name: this.estados[key]
  //     }
  //   })
  // },
  mixins: [validatorUtils],
  methods: {
    resetState () {
      this.model.nombre = ''
      this.model.estadosIniciales = []
      this.model.estadoFinal = undefined
      this.model.accion = []
      this.model.areasSeleccionadas = []
    },
    removeFinal: function (key) {
      this.model.estadoFinal = null
    },
    addEvento: function () {
      const promises = this.model.accion.map((a, index) => {
        return this.$refs['param-' + a.id + `-${index}`][0].validateForm()
      })
      if (!this.model.estadosIniciales.length) {
        this.$buefy.snackbar.open({
          message: 'Debe definir el o los estados iniciales del evento',
          type: 'is-danger'
        })
        return
      }
      if (this.model.estadoFinal === undefined) {
        this.$buefy.snackbar.open({
          message: 'Debe definir el estado final del evento',
          type: 'is-danger'
        })
        return
      }
      Promise.all(promises).then(x => {
        const acciones = this.model.accion.map((a, index) => {
          const accionPayload = this.$refs['param-' + a.id + `-${index}`][0].getPayload()
          return {
            documento_operacion_id: a.id,
            descripcion: accionPayload.descripcion,
            parametros: accionPayload.parametros
          }
        })
        const payload = {
          nombre: this.model.nombre,
          estados_iniciales: this.model.estadosIniciales,
          estado_final: this.model.estadoFinal,
          acciones: acciones,
          areas_habilitadas: this.model.areasSeleccionadas.length > 0 ? this.model.areasSeleccionadas : null
        }
        console.log('Crear evento payload', payload)
        this.$validator.validateAll('serie').then(result => {
          if (!result) {
            this.$buefy.snackbar.open({
              message: 'Error en los campos de crear evento, por favor, revise.',
              type: 'is-danger'
            })
            return
          }
          this.$http.post(`/serie-documental/${this.serieDocumental.id}/evento`, payload).then(res => {
            this.$buefy.snackbar.open({
              message: 'Evento creado',
              type: 'is-success'
            })
            this.resetState()
            this.$emit('update')
            this.clearValidatorErrors('serie')
          }).catch(error => {
            console.error('Fail', error)
            this.$buefy.snackbar.open({
              message: 'Error al crear el evento',
              type: 'is-danger'
            })
          })
        }).catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error al validar los campos del evento.',
            type: 'is-danger'
          })
        })
        this.model.accion.forEach((a, index) => {
          console.log(this.$refs['param-' + a.id + `-${index}`][0].getPayload())
        })
      }).catch(err => {
        console.error(err)
        this.$buefy.snackbar.open({
          message: 'Error en los parametros de las acciones. Por favor, revise los campos.',
          type: 'is-danger'
        })
      })
    },
    addAccion: function (accion) {
      if (accion.id === 'cargarAspectoCompuesta' && this.serieDocumental.aspectos.length === 0) {
        this.$buefy.dialog.alert({
          title: 'Atención',
          message: 'No se puede agregar la acción al avento: La serie documental no cuenta con aspectos.',
          type: 'is-primary',
          hasIcon: true,
          icon: 'exclamation-triangle',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }
      if ((accion.id === 'firmarPdfSubunidad' || accion.id === 'anexarSubunidad') && this.serieDocumental.estructura.length === 0) {
        this.$buefy.dialog.alert({
          title: 'Atención',
          message: 'No se puede agregar la acción al avento: La serie documental no cuenta con subunidades.',
          type: 'is-primary',
          hasIcon: true,
          icon: 'exclamation-triangle',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }
      accion.specialKey = `accion-${this.randomNumber}`
      this.randomNumber++
      this.model.accion.push(Object.assign({}, accion))
    },
    deleteAccion: function (key) {
      this.model.accion = this.model.accion.filter((a, i) => {
        return key !== a.specialKey
      })
    }
  },
  components: {
    Parametros
  },
  computed: {
    serieDocumental: function () {
      return this.$store.state.serieDocumental.serieDocumental
    },
    areasDisponibles: function () {
      return this.$store.state.organizacion.areas
    },
    accionesDisponibles: function () {
      return this.$store.state.signar.operacionesDisponiblesUnidadCompuesta
    },
    accionNombre: function () {
      const objArr = {}
      this.accionesDisponibles.forEach(acc => {
        objArr[acc.id] = acc.nombre
      })
      return objArr
    },
    idOrganizacion: function () {
      return this.$store.state.organizacion.organizacion.id
    }
  }
}
</script>

<style lang="scss" scoped>
.tags {
  min-height: 50px;
}
.cursor-drag {
  cursor: grab;
}
</style>
