<template>
<div class="column is-3-desktop is-2-fullhd is-hidden-touch">
  <div class="card sticky-column">
    <div class="card-image">
      <figure class="image">
        <img v-if="file.preview_uri" :src="file.preview_uri ? file.preview_uri : '/statics/default-document.png'"  class="image" alt="Previsualización">
        <img v-else :src="(file.tipo_documento_id == 'carpeta') ? '/statics/default-folder.png' : '/statics/default-document.png'" class="image"  alt="Previsualización">
      </figure>
    </div>
    <hr class="my-0">
    <div class="card-content">
      <p class="title is-5 name-file" v-if="currentFolder">{{currentFolder.id == file.id ? getCurrentFolderReadableName(currentFolder) : file.nombre}}</p>
      <div class="content">

        <p>
          <b>Propietario</b>
          <br><span class="is-size-7" v-if="loadingOwner">
            <i class="fas fa-sync fa-spin"></i>&nbsp;&nbsp;Cargando</span>
          <span class="is-size-7" v-else>{{theOwnersName}}</span>
        </p>
        <p v-if="isDocument">
          <b>Versión</b>
          <b-tag type="is-primary">{{file.ultima_version ? file.ultima_version.orden+1 : 1}}</b-tag>
        </p>
        <p>
          <b>Fecha creación</b>
          <br><span class="is-size-7">{{file.created_at}}</span>
        </p>
      </div>
      <ul>
        <li v-if="isDocument"
            @click="canSign? addToPila() : openWarningSnackBar()"
            :class="canSign? 'my-1' : 'my-1 disabled-option'">
          <a :class="canSign? 'has-text-info' : 'disabled-option'">
            <i class="fas fa-plus fa-fw"></i>
            <i class="fas fa-inbox fa-fw"></i>&nbsp;Agregar para firmar
          </a>
        </li>
        <li v-if="isDocument"
            @click="canDownload? download() : openWarningSnackBar()"
            :class="canDownload? 'my-1' : 'my-1 disabled-option'">
          <a :class="canDownload? 'has-text-info' : 'disabled-option'">
            <i class="fas fa-download fa-fw"></i>&nbsp;Descargar
          </a>
        </li>
        <li v-if="isDocument"
            @click="canPreview? previewDocument() : openWarningSnackBar()"
            :class="canPreview? 'my-1' : 'my-1 disabled-option'">
          <a :class="canPreview? 'has-text-info' : 'disabled-option'">
            <i class="fas fa-glasses fa-fw"></i>&nbsp;Previsualizar
          </a>
        </li>
        <li @click="canMove? moveDocument() : openWarningSnackBar()"
            :class="canMove? 'my-1' : 'my-1 disabled-option'">
          <a :class="canMove? 'has-text-info' : 'disabled-option'">
            <i class="fas fa-dolly fa-fw"></i>&nbsp;Mover
          </a>
        </li>
        <li v-if="!isRecibo"
            @click="canRename? renameDocument() : openWarningSnackBar()"
            :class="canRename? 'my-1' : 'my-1 disabled-option'">
          <a :class="canRename? 'has-text-info' : 'disabled-option'">
            <i class="fas fa-terminal fa-fw"></i>&nbsp;Renombrar
          </a>
        </li>
        <li v-if="isFolder" @click="openPermissions()" class="my-1">
          <a class="has-text-info">
            <i class="fas fa-users-cog fa-fw"></i>&nbsp;Gestionar permisos
          </a>
        </li>
        <li v-if="isFolder"
            @click="canSetRootFolder? setRootFolder() : openWarningSnackBar()"
            :class="canSetRootFolder? 'my-1' : 'my-1 disabled-option'">
          <a :class="canSetRootFolder? 'has-text-info' : 'disabled-option'">
            <i class="fas fa-hammer fa-fw"></i>&nbsp;Definir como carpeta raiz
          </a>
        </li>
        <li @click="canDelete? deleteDocument() : openWarningSnackBar()"
            :class="canDelete? 'my-4' : 'my-4 disabled-option'">
          <a :class="canDelete? 'has-text-danger' : 'disabled-option'">
            <i class="fas fa-trash fa-fw"></i>&nbsp;Eliminar
          </a>
        </li>
      </ul>
    </div>
    <div class="card-footer">
      <a :class="canOpenFile? 'has-text-primary card-footer-item' : 'card-footer-item disabled-option'"
          @click="canOpenFile? openFile() : openWarningSnackBar()">
        <i class="fas fa-external-link-alt fa-fw"></i>&nbsp;Abrir
      </a>
      <a @click="closeSidebar()" class="has-text-dark card-footer-item">
        Ocultar&nbsp;<i class="fas fa-angle-double-right fa-fw"></i>
      </a>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: ['file'],
  data () {
    return {
      loadingOwner: false,
      theOwnersName: '',
      minimumLevelPermissionTo: {
        watch: 1,
        download: 1,
        // downloadConfidential: 2, // Aun no se utiliza, pero se planea usar en el futuro
        sign: 3,
        setRootFolder: 4,
        rename: 6,
        move: 7,
        delete: 7
      }
    }
  },
  mounted: function () {
    this.checkOwner()
  },
  methods: {
    openWarningSnackBar () {
      this.$buefy.snackbar.open({
        message: 'No cuenta con el nivel de permiso suficiente sobre ' + (this.isFolder ? 'la carpeta' : 'el documento'),
        type: 'is-danger'
      })
    },
    checkOwner: function () {
      if (this.file.propietario_id === 1) {
        this.theOwnersName = 'Lyris IT'
        return this.theOwnersName
      } else if (this.file.propietario_id === this.user.id) {
        this.theOwnersName = `${this.user.name} ${this.user.surname}`
      } else {
        this.fetchOwner()
      }
    },
    fetchOwner: function () {
      this.loadingOwner = true
      this.$http
        .get('/usuario/' + this.file.propietario_id)
        .then(res => {
          this.theOwnersName = res.data.nombre + ' ' + res.data.apellido
          this.loadingOwner = false
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message:
              'Ha ocurrido un error al conectarse con el servidor',
            type: 'is-danger'
          })
        })
    },
    addToPila: function () {
      this.$store.commit('firmador/addPila', this.file)
    },
    download: function () {
      this.$http
        .get('/documento/' + this.file.id + '/descarga')
        .then(response => {
          window.location.href = response.data.link
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message:
              'Ha ocurrido un error con la descarga',
            type: 'is-danger'
          })
        })
    },
    setRootFolder: function () {
      this.$http
        .put('/usuario/' + this.user.id + '/carpeta-raiz', {
          carpeta_raiz_id: this.file.id
        })
        .then(response => {
          this.$buefy.snackbar.open({
            message: `La carpeta ${this.file.nombre} es ahora la carpeta raiz`,
            type: 'is-success'
          })
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message:
              'Ha ocurrido un error al definir la carpeta como raiz',
            type: 'is-danger'
          })
        })
    },
    getCurrentFolderReadableName (currentFolder) {
      if (!this.currentFolder) return '?'
      const arrRoute = this.currentFolder.ruta.split('/')
      return arrRoute.pop()
    },
    openFile: function () {
      let routeData = null
      switch (this.file.tipo_documento_id) {
        case 'carpeta':
          routeData = this.$router.resolve({ name: 'repositorio', params: { idFolder: this.file.id } })
          window.open(routeData.href, '_blank')
          break
        case 'documento':
        case 'recibo':
        case 'otroDocumento':
          routeData = this.$router.resolve({ name: 'documento', params: { id: this.file.id } })
          window.open(routeData.href, '_blank')
          break
        case 'unidadCompuesta':
          routeData = this.$router.resolve({ name: 'unidad-compuesta', params: { id: this.file.id } })
          window.open(routeData.href, '_blank')
          break
        default:
          this.$buefy.snackbar.open({
            message: 'Hubo un error al abrir el archivo',
            type: 'is-danger'
          })
          break
      }
    },
    openPermissions: function () {
      this.$store.commit('repository/selectedDocument', this.file)
      this.$emit('folder-permissions')
    },
    deleteDocument: function () {
      this.$store.commit('repository/selectedDocument', this.file)
      this.$emit('delete-document')
    },
    moveDocument: function () {
      this.$store.commit('repository/selectedDocument', this.file)
      this.$emit('move-document')
    },
    previewDocument: function () {
      this.$store.commit('repository/selectedDocument', this.file)
      this.$emit('preview-document')
    },
    renameDocument: function () {
      this.$store.commit('repository/selectedDocument', this.file)
      this.$emit('rename-document')
    },
    closeSidebar: function () {
      this.$store.commit('repository/selectedDocument', null)
      this.$emit('close-sidebar')
    },
    userPermissionsOverMinimumLevel (permissions, minimumLevelPermission) {
      const userPermissions = permissions.find(perm => (perm.usuario && perm.usuario.id === this.user.id))
      return (userPermissions && userPermissions.nivel >= minimumLevelPermission)
    },
    areaPermissionsOverMinimumLevel (permissions, minimumLevelPermission) {
      const areaPermissions = permissions.find(perm => (perm.area && this.user.areas.some(idArea => idArea === perm.area.id)))
      return (areaPermissions && areaPermissions.nivel >= minimumLevelPermission)
    },
    organizationPermissionsOverMinimumLevel (permissions, minimumLevelPermission) {
      const organizationPermissions = permissions.find(perm => (perm.organizacion && perm.organizacion.id === this.user.organization))
      return (organizationPermissions && organizationPermissions.nivel >= minimumLevelPermission)
    },
    hasPermissionLevelOver (minimumLevelPermission) {
      return (this.userPermissionsOverMinimumLevel(this.file.permisos, minimumLevelPermission) ||
              this.areaPermissionsOverMinimumLevel(this.file.permisos, minimumLevelPermission) ||
              this.organizationPermissionsOverMinimumLevel(this.file.permisos, minimumLevelPermission))
    }
  },
  computed: {
    currentFolder: function () {
      return this.$store.state.repository.currentFolder
    },
    isSignable: function () {
      return this.file.mime === 'application/pdf'
    },
    isDocument: function () {
      return this.file.tipo_documento_id === 'documento'
    },
    isFolder: function () {
      return this.file.tipo_documento_id === 'carpeta'
    },
    isRecibo: function () {
      return this.file.tipo_documento_id === 'recibo'
    },
    user: function () {
      return this.$store.getters.getUser
    },
    canOpenFile () {
      return this.hasPermissionLevelOver(this.minimumLevelPermissionTo.watch)
    },
    canSign: function () {
      return (this.isSignable && this.hasPermissionLevelOver(this.minimumLevelPermissionTo.sign))
    },
    canDownload: function () {
      return this.hasPermissionLevelOver(this.minimumLevelPermissionTo.download)
    },
    canPreview: function () {
      return this.hasPermissionLevelOver(this.minimumLevelPermissionTo.watch)
    },
    canMove: function () {
      return this.hasPermissionLevelOver(this.minimumLevelPermissionTo.move)
    },
    canRename: function () {
      return this.hasPermissionLevelOver(this.minimumLevelPermissionTo.rename)
    },
    canSetRootFolder: function () {
      return this.hasPermissionLevelOver(this.minimumLevelPermissionTo.setRootFolder)
    },
    canDelete: function () {
      return this.hasPermissionLevelOver(this.minimumLevelPermissionTo.delete)
    }
  },
  watch: {
    file (newVal, oldVal) {
      if (newVal && newVal.propietario_id !== oldVal.propietario_id) {
        this.checkOwner()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .panel-block {
//   font-size: 1rem;
//   font-weight: 400;
// }
.name-file {
  word-break: break-all;
}
.sticky-column{
  position: sticky;
  top: 10px;
  max-height: 95vh;
  overflow-y: auto;
}
.disabled-option {
  cursor: not-allowed;
  color: #808080
}
</style>
