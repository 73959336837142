<template>
<div>
    <!-- <route-breadcrumb :routes="rutas" :ends-file="true" /> -->
      <Acciones :estado="recibo.estado" @descargar="descargar" :refresh="refresh" @addpila="addToPila" @eliminar="eliminar"  @preview="openPreview" @print="printFile"/>
    <section class="section">
      <!-- <h5 class="title is-5 has-text-dark is-hidden-desktop">
        <i class="fas fa-file fa-fw"></i>&nbsp;{{rutas[rutas.length-1]}}</h5>
      <br> -->
      <div class="columns">
        <div class="column">
          <div class="box">
            <h1 class="title document-title is-4">
              <i class="fas fa-file-invoice-dollar"></i>&nbsp;&nbsp;{{recibo.nombre.length > 50 ? recibo.nombre.substring(0,50) + '...' : recibo.nombre}}</h1>
            <Informacion />
          </div>
            <div class="box">
              <Status />
            </div>
        </div>
        <div class="column is-3">
          <div v-if="recibo.preview_uri" class="box">
            <figure class="image">
                <a @click="previewModalActive = true" v-if="recibo.preview_uri" class="show-magnifier-cursor">
              <img :src="recibo.preview_uri" alt="Previsualización">
            </a>
            <img src="/statics/default-document.png" v-else alt="Previsualización">
            </figure>
          </div>
          <b-modal :active.sync="previewModalActive">
                <figure class="image">
            <a :href="recibo.preview_uri" target="_blank">
                    <img :src="recibo.preview_uri" alt="Previsualización">
            </a>
              </figure>
          </b-modal>
        </div>
      </div>
      <!-- <file-preview ref="filePreview"></file-preview> -->
    </section>
</div>
</template>

<script>
import print from 'print-js'
import Acciones from '@/components/recibo/Acciones'
import Status from '@/components/recibo/Status'
import Informacion from '@/components/recibo/Informacion'
// import RouteBreadcrumb from '@/components/utils/RouteBreadcrumb'
// import FilePreview from '@/components/utils/FilePreview'

import store from '@/store'
// import http from '@/http'

export default {
  props: ['id'],
  components: {
    Acciones,
    Status,
    Informacion
  },
  data () {
    return {
      rutas: null,
      previewModalActive: false
    }
  },
  created () {
    this.rutas = this.recibo.ruta.split('/')
  },
  beforeRouteUpdate: (to, from, next) => {
    // Save the next function so its visible for the promise..
    // by default this is pased to the promise.
    if (to.params.id === from.params.id) {
      console.info('[ROUTE] No need to fetch data, already fetched')
      next()
    } else {
      console.log('[ROUTE] Updated route')
      store.dispatch('recibo/fetchRecibo', to.params.id)
        .then(response => {
          console.info('[ROUTER] Data fetch success. Continue to route')
          this.next()
        })
        .catch(error => {
          console.error('[ROUTER] Data fetch failed', error)
          this.next(false)
        })
    }
  },
  methods: {
    descargar: function (v) {
      this.$http
        .get('/documento/' + this.recibo.id + '/descarga?v=' + v)
        .then(response => {
          window.location.href = response.data.link
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message:
              'Error de conexión. Compruebe la conectividad con internet.',
            type: 'is-danger'
          })
        })
    },
    eliminar: function () {
      this.$http
        .delete('/documento/' + this.recibo.id)
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'El recibo ha sido eliminado',
            type: 'is-success'
          })
          this.$router.push({ name: 'listarRecibos' })
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error inesperado.',
            type: 'is-danger'
          })
        })
    },
    refresh: function () {
      this.startLoading()
      store.dispatch('recibo/fetchRecibo', this.recibo.id)
        .finally(() => {
          this.stopLoading()
        })
    },
    addToPila: function () {
      this.$store.commit('firmador/addPila', this.recibo)
    },
    openPreview: function () {
      this.$eventHub.$emit('openFilePreview', this.recibo)
    },
    printFile: function () {
      this.$http
        .get('/documento/' + this.recibo.id + '/descarga?v=recibo')
        .then(response => {
          print({
            printable: response.data.link,
            type: 'pdf',
            documentTitle: this.recibo.nombre,
            onError: () => {
              this.$buefy.snackbar.open({
                message:
              'Hubo un error al intentar imprimir el archivo.<br>Por favor, <b>intente descargar <i class="fas fa-download"></i> el archivo e imprimalo</b>.',
                type: 'is-danger',
                duration: 15000
              })
            }
          })
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message:
              'Error de conexión. Compruebe la conectividad con internet.',
            type: 'is-danger'
          })
        })
    }
  },
  computed: {
    recibo: function () {
      return this.$store.state.recibo.recibo
    }
  }
}
</script>

<style lang="scss" scoped>
.document-title {
  word-break: break-all;
}
progress.progress{
  margin-bottom: 5px;
}
</style>
