<template>
<div>

  <section class="box mb-0">
        <h1 class="title is-5">Eventos de la serie</h1>
        <div class="content">
        <p>Para ver la información extendida de cada evento, haga clic en el icono <i class="fas fa-angle-down fa-fw has-text-primary"></i></p>
        </div>
        <p class="mt-5 is-italic is-mono" v-if="serieDocumental.eventos.length == 0">
          - No hay eventos en la serie -
        </p>
  </section>
  <b-collapse
            class="card is-radiusless my-1 is-shadowless"
            animation="slide"
            v-for="(evento, index) of serieDocumental.eventos"
            :key="index"
            :open="isOpen == index"
            @open="isOpen = index">
            <template #trigger="props">
                <div
                    class="card-header is-radiusless"
                    role="button"
                >
                    <div class="card-header-title is-flex-direction-column is-align-items-start">
                        <p class="mb-3">Evento:&nbsp;&nbsp;{{ evento.nombre }} <span class="is-4 ml-5 has-text-primary is-400 "><i class="fas fa-bolt"></i> {{evento.acciones.length}}</span><span class="is-4 ml-5 has-text-primary is-400 " v-if="evento.areas_habilitadas"><i class="fas fa-lock"></i> {{evento.areas_habilitadas.length}} Areas</span></p>
                        <div class="tags is-400">
                          <span
                            class="tag"
                            :class="e != serieDocumental.estado_inicial ? 'is-light' : 'is-link'"
                            v-for="e in evento.estados_iniciales"
                            :key="e"
                          >{{serieDocumental.estados[e]}}</span>
                          <span class="tag is-white">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <span
                            class="tag"
                            :class="evento.estado_final != serieDocumental.estado_inicial ? 'is-light' : 'is-link'"
                            v-if="serieDocumental.estados[evento.estado_final]"
                          >{{serieDocumental.estados[evento.estado_final]}}</span>
                          <span class="tag is-light" v-else>
                            <i class="fas fa-asterisk"></i>
                          </span>
                        </div>
                    </div>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'angle-up' : 'angle-down'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
              <h1 class="subtitle is-4">
                Evento
              </h1>
              <h1 class="title is-3">
                {{ evento.nombre }}
              </h1>
              <nav class="breadcrumb has-bullet-separator" aria-label="breadcrumbs">
                <ul>
                  <li><a @click="openModalEditarEvento(evento)"><i class="fas fa-edit"></i>&nbsp;Editar</a></li>
                  <li><a @click="openModalEliminarEvento(evento)" class="has-text-danger"><i class="fas fa-times-circle"></i>&nbsp;Eliminar</a></li>
                </ul>
              </nav>
              <hr>
              <div class="columns is-multiline">
                <div class="column is-6">
                  <h1 class="subtitle is-5">Estados iniciales</h1>
                  <div class="tags">
                    <span
                      class="tag is-medium"
                      :class="e != serieDocumental.estado_inicial ? 'is-light' : 'is-link'"
                      v-for="e in evento.estados_iniciales"
                      :key="e"
                    >{{serieDocumental.estados[e]}}</span>
                  </div>
                </div>
                <div class="column is-6">
                  <h1 class="subtitle is-5">Estados final</h1>
                  <div class="tags">
                    <span
                      class="tag is-primary"
                      v-if="serieDocumental.estados[evento.estado_final]"
                    >{{serieDocumental.estados[evento.estado_final]}}</span>
                    <span v-else>
                      <i>
                        <i class="fas fa-asterisk"></i>&nbsp;Mantiene el estado
                      </i>
                    </span>
                  </div>
                </div>
              </div>
                <hr>
              <h1 class="subtitle is-5">Areas habilitadas para ejecutar acciones</h1>
                <div class="tags" v-if="evento.areas_habilitadas">
                  <span
                    class="tag is-dark"
                    v-for="a in evento.areas_habilitadas"
                    :key="a"
                  >{{getArea(a) || '???'}}</span>
                </div>
                <div class="content" v-else>
                  <p class="is-italic is-mono">Todas las areas pueden ejecutar las acciones</p>
                </div>
                <hr>
                <h1 class="title is-5">Acciones</h1>
                 <div class="content">
                  <p>Puede editar la descripción de las acciones haciendo clic en <span class="has-text-primary"><i class="fas fa-edit"></i></span>, o eliminarlas haciendo clic sobre el ícono <span class="has-text-primary"><i class="fas fa-times-circle"></i></span></p>
                   <p><b>NOTA:</b> No puede eliminar acciones que pertenezcan a un evento que se encuentre en ejecución en al menos una unidad.</p>
                  </div>
                  <div class="buttons">
                    <a @click="openModalNuevaAccion(evento)" class="button is-primary is-outlined"><i class="fas fa-plus"></i>&nbsp;Agregar acción</a>
                  </div>
                <div class="content" v-if="evento.acciones.length === 0">
                  <p class="is-italic is-mono">No se requieren acciones para ejecutar el evento</p>
                </div>
                <div v-else>
                  <div class="box is-flex is-flex-direction-row" v-for="(a, index) in evento.acciones" :key="index">
                    <div class="mr-4">
                      <i class="fas fa-bolt fa-2x has-text-primary"></i>
                    </div>
                    <div class="is-flex-grow-1">
                      <h1 class="subtitle is-6">Acción #{{index+1}}</h1>
                      <h1 class="title is-5 mb-3">{{getNombreAccion(a.documento_operacion_id)}}</h1>
                      <p class="is-italic">{{a.descripcion}}</p>
                    </div>
                    <div class="ml-4">
                      <p class="mb-3">

                      <a @click="openModalEditarAccion(evento,a)" class="has-text-primary">
                          <i class="fas fa-edit fa-lg"></i>
                        </a>
                      </p>
                      <p @click="openDialogDeleteAccion(evento,a)" class="my-3">
                      <a class="has-text-primary">
                          <i class="fas fa-times-circle fa-lg"></i>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
            </div>
        </b-collapse>
        <div class="box mb-5">
          <h1 class="subtitle is-5">Nuevo evento</h1>
        <!-- <div class="content">
          <p>Puede crear un nuevo evento para la serie documental. Complete los campos y haga clic en <span class="has-text-primary"><i class="fas fa-plus"></i> Agregar</span></p>
        </div> -->
        <crear-evento @update="crearEvento" ></crear-evento>
        </div>
</div>
</template>

<script>
import CrearEvento from './CrearEvento'

import ModalNuevaAccion from './ModalNuevaAccion'
import ModalEditarEvento from './ModalEditarEvento'
import ModalEditarAccion from './ModalEditarAccion'

export default {
  // props: {},
  components: {
    CrearEvento
  },
  data () {
    return {
      isOpen: null,
      accionesDisponibles: [],
      showCrearEvento: false
    }
  },
  mounted () {
    this.fetchAccionesDisponibles()
  },
  methods: {
    crearEvento: function () {
      this.$emit('crearEvento')
    },
    fetchAccionesDisponibles () {
      this.$http
        .get('/documento-operacion?tipo=' + this.serieDocumental.tipo_documento_id)
        .then(res => {
          this.accionesDisponibles = res.data
        })
        .catch(error => {
          console.error(error.message)
        })
    },
    getNombreAccion: function (idAccion) {
      if (!this.accionesDisponibles.length) return '???'
      return (this.accionesDisponibles.find(a => a.id === idAccion)).nombre
    },
    // ---------------------------
    openModalEliminarEvento: function (evento) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar evento',
        message: `<div class="content has-text-danger">
          <h1 class="title has-text-danger">Atención</h1>
          <p>
            La acción que está por realizar tiene un efecto importante de las unidades compuesta que instanciaron la serie de la cual el evento <b>${evento.nombre}</b> pertenece
          </p>
          <p>Usted podrá eliminar el evento siempre y cuando <u>ninguna</u> unidad compuesta haya ejecutado el evento <b>${evento.nombre}</b> o este en ejecución.</p>
          <p>De proceder con la eliminación, se <b>eliminara</b> el evento.</p>
          <p>
            <i class="fas fa-exclamation-circle"></i>&nbsp;Recomendación: Revise el flujo de los estados al hacerla eliminacion para no caer en estados huerfanos.
          </p>
        </div>
        <p>
          ¿Esta seguro que quiere <b>eliminar</b> el evento <b>${evento.nombre}</b>?
        </p>`,
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('deleteEvento', evento)
      })
    },
    openModalEditarAccion: function (evento, accion) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalEditarAccion,
        hasModalCard: true,
        props: {
          evento: evento,
          accion: accion
        },
        events: {
          submit: (evento, accion, payload) => {
            this.$emit('editarAccion', evento, accion, payload)
          }
        }
      })
    },
    openModalNuevaAccion: function (evento) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalNuevaAccion,
        hasModalCard: true,
        props: {
          evento: evento,
          subunidades: this.serieDocumental.estructura,
          aspectosIniciales: this.serieDocumental.aspectos,
          acciones: this.accionesDisponibles,
          idOrganizacion: this.idOrganizacion
        },
        events: {
          submit: (evento, payload) => {
            this.$emit('nuevaAccion', evento, payload)
          }
        }
      })
    },
    openDialogDeleteAccion: function (evento, accion) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar Acción',
        message: `<div class="content has-text-danger">
          <h1 class="title has-text-danger">Atención</h1>
          <p>
            La acción que está por realizar tiene un efecto importante de las unidades compuesta que instanciaron la serie
          </p>
          <p>Usted podrá eliminar la acción del evento <b>${evento.nombre}</b> siempre y cuando el evento no se encuentre en ejecución en al menos una unidad.</p>
          <p>De proceder con la eliminación, se <b>eliminará</b> la acción.</p>
          </div>
        <p>¿Esta seguro que quiere <b>eliminar</b> la accion del evento <b>${evento.nombre}</b> de la serie documental?</p>`,
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('deleteAccionEvento', evento, accion)
      })
    },
    openModalEditarEvento: function (resource) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalEditarEvento,
        hasModalCard: true,
        props: {
          resource: resource,
          idOrganizacion: this.idOrganizacion
        },
        events: {
          submit: (resource, payload) => {
            this.$emit('editarEvento', resource, payload)
          }
        }
      })
    },
    getArea: function (area) {
      if (this.areas.length > 0) {
        const theArea = this.areas.find(a => {
          return a.id === area
        })
        // console.log(theArea)
        if (theArea) {
          return theArea.nombre
        }
      }
      return null
    }
  },
  computed: {
    idOrganizacion: function () {
      return this.$store.state.organizacion.organizacion.id
    },
    serieDocumental: function () {
      return this.$store.state.serieDocumental.serieDocumental
    },
    areas: function () {
      return this.$store.state.organizacion.areas
    }
  }
}
</script>

<style>

</style>
