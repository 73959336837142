<template>
  <div>
    <b-table
      :data="otrosDocumentos"
      :bordered="false"
      :narrowed="false"
      :hoverable="true"
      :loading="isLoading"
      checkable
      :checked-rows="selectedOtrosDocumentos"
      :mobile-cards="true"
      :sortable="true"
      @dblclick="goToDocument"
      @check="checkRow">

      <b-table-column field="nombre" label="Documentos" sortable v-slot="props">
        <span class="break-word"><i class="fas fa-file"></i>&nbsp;&nbsp;{{ props.row.nombre }}</span>
      </b-table-column>

      <b-table-column field="usuario.usuario.apellido" sortable label="Empleado" v-slot="props" v-if="$store.getters.hasAnyRole(['otros-admin', 'lyris'])">
        {{ props.row.usuario.usuario.apellido }}, {{ props.row.usuario.usuario.nombre }}
      </b-table-column>

      <b-table-column field="estado" label="Estado de firma" sortable centered v-slot="props">
        <span><i class="fas" :class="`${getConformidadIcon(props.row.estado)} ${getConformidadIcon(props.row.estado)}`"></i></span>
      </b-table-column>

      <b-table-column field="created_at" label="Fecha" sortable centered v-slot="props">
        {{ new Date(props.row.created_at) | moment('DD/MM/YYYY') }}
      </b-table-column>

      <b-table-column field="tipo_otro_documento.nombre" label="Tipo" sortable centered v-slot="props">
        {{ props.row.tipo_otro_documento.nombre }}
      </b-table-column>

      <template #empty>
        <div class="is-family-monospace">
          <span v-if="isLoading"></span>
          <span v-else>No hay documentos que cumplan con los filtros de búsqueda.</span>
        </div>
      </template>
    </b-table>
    <infinite-loading @infinite="infiniteHandlerDocuments" :identifier="identifierLoaderDocuments">
      <div slot="no-more" class=" py-5 is-size-7 is-family-monospace">
        <span v-if="isLoading">Cargando...</span>
        <span v-else>~ Fin del listado ~</span>
      </div>
      <div slot="no-results" class=" py-5 is-size-7 is-family-monospace">
        <span v-if="isLoading">Cargando...</span>
        <span v-else>~ Fin del listado ~</span>
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'

export default {
  data () {
    return {
      isLoading: true,
      otrosDocumentos: [],
      selectedArray: [],
      identifierLoaderDocuments: Math.random(),
      queryDocuments: {
        page: 1,
        estado: null,
        tipo: null,
        fecha: null,
        usuario: null
      }
    }
  },
  computed: {
    url () {
      return '/otro-documento'
    },
    selectedOtrosDocumentos: function () {
      return this.$store.state.otrosDocumentos.selected
    }
  },
  components: {
    InfiniteLoading
  },
  methods: {
    infiniteHandlerDocuments ($state) {
      this.isLoading = true
      this.$http.get(this.url, { params: this.queryDocuments })
        .then(({ data }) => {
          this.isLoading = false
          if (data.current_page === data.last_page) {
            this.otrosDocumentos.push(...data.data)
            $state.complete()
          }
          if (data.current_page < data.last_page) {
            this.queryDocuments.page += 1
            this.otrosDocumentos.push(...data.data)
            $state.loaded()
          }
        })
    },
    setQuery (query) {
      this.queryDocuments.estado = query.estado
      this.queryDocuments.tipo = query.tipo
      this.queryDocuments.fecha = query.fecha
      this.queryDocuments.usuario = query.empleado
      this.queryDocuments.page = 1
      this.otrosDocumentos = []
      this.identifierLoaderDocuments = Math.random()
    },
    refresh () {
      this.queryDocuments.page = 1
      this.otrosDocumentos = []
      this.$store.commit('otrosDocumentos/setSelected', [])
      this.identifierLoaderDocuments = Math.random()
    },
    goToDocument (documento) {
      this.$router.push('/documento/' + documento.id)
    },
    checkRow: function (checkedDocsArray) {
      this.$store.commit('otrosDocumentos/setSelected', checkedDocsArray)
    },
    getConformidadIcon: function (status) {
      let icon = ''
      switch (status) {
        case 'pendiente':
          icon = 'fa-clock has-text-warning'
          break
        case 'firmado':
        case 'disponible':
          icon = 'fa-check has-text-success'
          break
        default:
          icon = 'fa-question'
          break
      }
      return icon
    }
  }
}
</script>
