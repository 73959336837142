import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
// import http from '@/http'

import routesDemoVerificador from '@/views/demo-verificador/routes'
import routesBase from '@/views/base/routes'
import routesAuth from '@/views/auth/routes'
import routesPortal from '@/views/portal/routes'
import routesCustomSettings from '@/views/custom-settings/routes'
import routesFirmador from '@/views/firmador/routes'
import routesAdmin from '@/views/admin/routes'
import routesPerfil from '@/views/perfil/routes'
import routesDocumento from '@/views/documento/routes'
import routesOrganizacion from '@/views/organizacion/routes'
// import routesUsuario from '@/views/organizacion/usuario/routes'
import routesRecibos from '@/views/recibos/routes'
import routesRecibo from '@/views/recibo/routes'
import routesRepositorio from '@/views/repositorio/routes'
import routesOtrosDocumentos from '@/views/otros-documentos/routes'
import routesCertificados from '@/views/certificados/routes'
// import routesRepositorioOld from '@/components/repositorio-old/routes'
import routesUnidadCompuesta from '@/views/unidad-compuesta/routes'

Vue.use(Router)

const routes = [].concat(
  routesDemoVerificador,
  routesBase,
  routesAuth,
  routesPortal,
  routesCustomSettings,
  routesFirmador,
  routesAdmin,
  routesPerfil,
  routesDocumento,
  routesOrganizacion,
  // routesUsuario,
  routesRecibos,
  routesRecibo,
  routesOtrosDocumentos,
  routesRepositorio,
  routesCertificados,
  // routesRepositorioOld,
  routesUnidadCompuesta
)

const router = new Router({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  },
  linkActiveClass: 'is-active'
})

router.beforeEach((to, from, next) => {
  if (to.fullPath === '/demo-verificador') {
    console.info(`[ROUTER] - Route ${to.path} is demo-verificador`)
    next()
    return
  }
  // Check if token is expired
  if (store.state.session) {
    const today = new Date()
    const expirationDate = new Date(store.state.session.expiration * 1000)
    if (today > expirationDate) {
      store.commit('logout')
      next({
        name: 'login',
        query: {
          redirect: to.path
        }
      })
    }
  }
  // eslint-disable-next-line no-prototype-builtins
  const protectedRecord = to.matched.find(record => record.meta.hasOwnProperty('requiresAuth'))
  if (protectedRecord) {
    console.info(`[ROUTER] - Route ${to.path} is protected`)
    if (store.state.session) {
      console.info('[ROUTER] -- User logged. OK')
      // eslint-disable-next-line no-prototype-builtins
      if (protectedRecord.meta.hasOwnProperty('requiredRoles')) {
        console.info('[ROUTER] --- Route is protected by role')
        const userRoles = store.state.session.user.roles
        if (userRoles.some(role => protectedRecord.meta.requiredRoles.includes(role))) {
          console.info('[ROUTER] ---- User has required role. Continue...')
          next()
        } else {
          console.error('[ROUTER] ---- User does NOT have the required role. STOP')
          next(false)
        }
      } else {
        console.info('[ROUTER] -- Route is NOT protected by role. Continue...')
        next()
      }
    } else {
      if (to.name === 'logout') {
        console.info('[ROUTER] -- Going to Logout. Continue...')
        next({
          name: 'login'
        })
      } else {
        console.info('[ROUTER] - User not logged! Redirect to Login. Continue...')
        next({
          name: 'login',
          query: {
            redirect: to.fullPath
          }
        })
      }
    }
  } else {
    console.info(`[ROUTER] - Route ${to.path} NOT protected. Continue...`)
    next()
  }
})

export default router
