// import http from '@/http'
import store from '@/store'

import Organizacion from './Organizacion'
import Informacion from './Informacion'
import Editar from './Editar'
import OrganizacionUsuarios from './usuario/Listado'
import OrganizacionAuditorias from './auditorias/Listado'
import UsuarioCrear from './usuario/Crear'
import UsuarioRolesPorDefecto from './usuario/RolesPorDefecto'
import AreaCrear from './area/Crear'
import AreaListado from './area/Listado'
import AspectoCrear from './aspectos/Crear'
import AspectoEditar from './aspectos/Editar'
import AspectoListado from './aspectos/Listado'
// import SerieDocumentalCrear from './series-documentales/Crear'
// import SerieDocumentalVer from './series-documentales/Ver'
// import SerieDocumentalEditar from './series-documentales/Editar'
// import SerieDocumentalListado from './series-documentales/Listado'
import OrganizacionModulos from './modulos/Listado'

import routesUsuarios from './usuario/routes.js'
import routesSeriesDocumentales from './series-documentales/routes.js'
import routesAutoridadCertificante from './autoridad-certificante/routes.js'

const BASE_PATH = '/organizacion'

const routes = [
  {
    path: BASE_PATH + '/:idOrganization',
    component: Organizacion,
    props: true,
    children: [
      {
        path: '',
        component: Informacion,
        name: 'organizacionInfo',
        props: true
      },
      {
        path: 'editar',
        component: Editar,
        name: 'organizacionEditar',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'admin']
        }
      },
      {
        path: 'auditorias',
        component: OrganizacionAuditorias,
        name: 'organizacionAuditorias',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'auditor']
        }
      },
      {
        path: 'usuarios',
        component: OrganizacionUsuarios,
        name: 'organizacionUsuarios',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'admin']
        }
      },
      {
        path: 'usuarios/crear',
        component: UsuarioCrear,
        name: 'organizacionUsuariosCrear',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'admin']
        }
      },
      {
        path: 'usuarios/roles-defecto',
        component: UsuarioRolesPorDefecto,
        name: 'organizacionUsuariosRolesPorDefecto',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'admin']
        }
      },
      {
        path: 'area',
        component: AreaListado,
        name: 'organizacionAreas',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'admin']
        }
      },
      {
        path: 'areas/crear',
        component: AreaCrear,
        name: 'organizacionAreasCrear',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'admin']
        }
      },
      {
        path: 'aspectos',
        component: AspectoListado,
        name: 'organizacionAspectos',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'admin', 'aspec-admin']
        }
      },
      {
        path: 'aspectos/crear',
        component: AspectoCrear,
        name: 'organizacionCrearAspecto',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'admin', 'aspec-admin']
        }
      },
      {
        path: 'aspectos/:idAspecto/editar',
        component: AspectoEditar,
        name: 'organizacionEditarAspecto',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'admin', 'aspec-admin']
        }
      },
      ...routesUsuarios,
      ...routesSeriesDocumentales,
      ...routesAutoridadCertificante,
      // {
      //   path: 'serie-documental',
      //   component: SerieDocumentalListado,
      //   name: 'organizacionSerieDocumental',
      //   props: true,
      //   meta: {
      //     requiresAuth: true,
      //     requiredRoles: ['lyris', 'admin', 'serie-admin']
      //   }
      // },
      // {
      //   path: 'serie-documental/crear',
      //   component: SerieDocumentalCrear,
      //   name: 'organizacionCrearSerieDocumental',
      //   props: true,
      //   meta: {
      //     requiresAuth: true,
      //     requiredRoles: ['lyris', 'admin', 'serie-admin']
      //   }
      // },
      // {
      //   path: 'serie-documental/:idSerie',
      //   component: SerieDocumentalVer,
      //   name: 'organizacionVerSerieDocumental',
      //   props: true,
      //   meta: {
      //     requiresAuth: true,
      //     requiredRoles: ['lyris', 'admin', 'serie-admin']
      //   },
      //   beforeEnter: (to, from, next) => {
      //     console.log('TEST - Going to Serie')
      //     console.log(to.params)
      //     Promise.all([
      //       http.get(`/serie-documental/${to.params.idSerie}`)
      //     ])
      //       .then(responses => {
      //         // store.commit('setOrganizacion', response.data)
      //         // store.commit('organizacion/organizacion', responses[0].data)
      //         // store.commit('organizacion/areas', responses[1].data)
      //         // store.commit('organizacion/roles', responses[2].data)
      //         console.log('GET OK - Got Serie')
      //         console.log('serie')
      //         next()
      //       })
      //       .catch(error => {
      //         console.log('Fetching data serie failed.', error)
      //         next({ name: 'error' })
      //       })
      //     // http
      //     //   .get('/organizacion/' + to.params.idOrganization)
      //     //   .then(response => {
      //     //     // store.commit('setOrganizacion', response.data)
      //     //     store.commit('organizacion/organizacion', response.data)
      //     //     console.log('GET OK - Got organizacion')
      //     //     next()
      //     //   })
      //     //   .catch(error => {
      //     //     console.log('Fetching data failed', error)
      //     //     next({ name: 'error' })
      //     //   })
      //   }
      // },
      // {
      //   path: 'serie-documental/:idSerie/editar',
      //   component: SerieDocumentalEditar,
      //   name: 'organizacionEditarSerieDocumental',
      //   props: true,
      //   meta: {
      //     requiresAuth: true,
      //     requiredRoles: ['lyris', 'admin', 'serie-admin']
      //   }
      // },
      {
        path: 'modulos',
        component: OrganizacionModulos,
        name: 'organizacionModulos',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'admin']
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      console.info('[ROUTER] First time entering:', to.path)
      Promise.all([
        store.dispatch('organizacion/fetchOrganizacion', to.params.idOrganization)
      ])
        .then(responses => {
          console.info('[ROUTER] Data fetch success. Continue to route')
          next()
        })
        .catch(error => {
          console.error('[ROUTER] Data fetch failed', error)
          next({ name: 'error' })
        })
    }
  }
]

// routes.concat(routesUsuarios)

export default routes
