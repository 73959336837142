<template>
  <div>
    <h1 class="title is-5">Datos del usuario</h1>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label">Nombre</label>
          <div class="control">
            <input
              v-model="model.nombre"
              name="nombre del usuario"
              class="input"
              :class="{ 'is-danger': errors.has('nombre del usuario') }"
              v-validate="{ required: true }"
              data-vv-scope="userData"
            />
          </div>
          <span
            class="help is-danger"
            v-if="errors.has('nombre del usuario')"
            >{{ errors.first("nombre del usuario") }}</span
          >
        </div>
        <div class="field">
          <label class="label">Apellido</label>
          <div class="control">
            <input
              v-model="model.apellido"
              name="apellido del usuario"
              class="input"
              :class="{ 'is-danger': errors.has('apellido del usuario') }"
              v-validate="{ required: true }"
              data-vv-scope="userData"
            />
          </div>
          <span
            class="help is-danger"
            v-if="errors.has('apellido del usuario')"
            >{{ errors.first("apellido del usuario") }}</span
          >
        </div>
        <div class="field">
          <label class="label">CUIL</label>
          <div class="control">
            <the-mask
              mask="##-########-#"
              v-model="model.cuil"
              type="text"
              :masked="false"
              class="input"
              :class="{ 'is-danger': errors.has('cuil') }"
            ></the-mask>
            <input
              type="hidden"
              v-model="model.cuil"
              name="cuil"
              class="input"
              :class="{ 'is-danger': errors.has('cuil') }"
              v-validate="{ required: true }"
              data-vv-scope="userData"
            />
          </div>
          <span class="help is-danger" v-if="errors.has('cuil')">{{
            errors.first("cuil")
          }}</span>
        </div>
      </div>
    </div>
    <br />
    <h1 class="title is-5">Datos de acceso</h1>
     <div class="content">
      <p>Defina los datos de acceso a la plataforma</p>
    </div>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label">Email de acceso</label>
          <div class="control">
            <input
              v-model="model.email"
              type="email"
              name="email usuario"
              class="input is-mono"
              placeholder="Ej: jgimenez@email.com"
              :class="{ 'is-danger': errors.has('email usuario') }"
              v-validate="{ required: true, email: true }"
              data-vv-scope="userData"
            />
          </div>
          <span
            class="help is-danger"
            v-if="errors.has('nombre del usuario')"
            >{{ errors.first("nombre del usuario") }}</span
          >
        </div>
        <div class="field">
          <label class="label">Usuario  <span class="tag is-success is-rounded" v-show="!$store.getters.hasAnyRole(['lyris']) && edit">Lyris</span></label>
          <div class="control">
            <input
              v-model="model.acceso"
              name="nombre del usuario"
              class="input is-mono"
              :class="{ 'is-danger': errors.has('nombre del usuario') }"
              v-validate="{ required: true }"
              data-vv-scope="userData"
              placeholder="Ej: jgimenez"
              :disabled="!$store.getters.hasAnyRole(['lyris']) && edit"
            />
          </div>
          <span
            class="help is-danger"
            v-if="errors.has('nombre del usuario')"
            >{{ errors.first("nombre del usuario") }}</span
          >
          <span class="help" v-show="!$store.getters.hasAnyRole(['lyris']) && edit">
            <i class="fas fa-exclamation-triangle"></i>&nbsp;Solamente Lyris puede cambiar este campo
          </span>
        </div>
      </div>
    </div>
    <div v-if="!edit">
    <br>
    <h1 class="title is-5">Clave de acceso</h1>
         <div class="content">
      <p><i class="fas fa-info-circle"></i>&nbsp;Tenga en cuenta: Si no ingresa contraseña, se le generará al usuario una clave y se le notifica por email la misma. En el caso de ingresar una contraseña, el email no será enviado.</p>
    </div>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field is-expanded">
          <label class="label">Contraseña</label>
          <div class="control">
            <input
              placeholder="Contraseña"
              name="password"
              type="password"
              class="input"
              data-vv-name="password"
              v-model="model.clave"
              ref="password"
              v-validate="{ required: false, min: 6 }"
              data-vv-scope="userData"
            />
            <span class="help is-danger" v-show="errors.has('password')">{{
              errors.first("password")
            }}</span>
          </div>
        </div>
        <div class="field is-expanded">
          <label class="label">Repetir contraseña</label>
          <div class="control">
            <input
              placeholder="Re-escriba la password"
              type="password"
              class="input"
              data-vv-name="password_confirmation"
              v-model="repeatPassword"
              v-validate="rulesPassword"
              data-vv-as="contraseña"
              data-vv-scope="userData"
            />
            <span
              class="help is-danger"
              v-show="errors.has('password_confirmation')"
              >{{ errors.first("password_confirmation") }}</span
            >
          </div>
        </div>
      </div>
    </div>
    </div>
    <hr>
    <div class="buttons is-right">
        <button
          @click="submit()"
          :disabled="errors.any()"
          class="button is-primary"
        >
          Guardar
        </button>
    </div>
  </div>
</template>

<script>
import validatorUtils from '@/mixins/validatorUtils'

export default {
  props: ['model', 'error', 'edit'],
  mixins: [validatorUtils],
  data () {
    return {
      errorValidation: null,
      repeatPassword: null
    }
  },
  methods: {
    submit: function () {
      // TODO Hacer validacion.
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('submit')
          this.clearValidatorErrors()
          return
        }
        this.$buefy.snackbar.open({
          message: 'Hay errores en el formulario',
          type: 'is-danger'
        })
      })
    }
  },
  computed: {
    rulesPassword: function () {
      if (this.model.clave !== '') {
        return 'required|confirmed:password'
      }
      return 'confirmed:password'
    }
  }
}
</script>
