  <template>
  <section class="box">
        <h1 class="subtitle is-5">Avanzado</h1>
    <h1 class="title is-1">Nueva demo</h1>
    <div class="content">
      <p>Configure los parametros para la iniciar (o reiniciar) una demo en el sistema.</p>
      <p>El manual de la demo lo puede encontrar <a href="https://drive.google.com/open?id=1uajiugIhNf5RhnUtKywLFJaDPMzozlbX" target="_blank">haciendo clic aquí</a></p>
    </div>
    <hr>
    <b-field label="Nombre de la razon social de la organización DEMO" expanded>
      <b-input v-model="data.razon_social" placeholder="Ingrese aqui el nombre"></b-input>
    </b-field>
    <div class="message is-dark">
      <div class="message-body">

      <h1 class="title is-3">{{data.razon_social}}</h1>
      <table class="table is-narrow is-bordered is-fullwidth">
        <tbody>
          <tr>
            <th width="200">
              Administrador
            </th>
            <td>
              dmedina
            </td>
          </tr>
          <tr>
            <th width="200">
              Empleador
            </th>
            <td>
              vprieto
            </td>
          </tr>
          <tr>
            <th width="200">
              Empleados
            </th>
            <td>
              mlozano, adelgado, ocabrera
            </td>
          </tr>
          <tr>
            <th width="200">
              Manual y archivos
            </th>
            <td>
              <a href="https://drive.google.com/open?id=1uajiugIhNf5RhnUtKywLFJaDPMzozlbX" target="_blank">Clic aquí</a>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
    <div class="buttons is-right">
      <button @click="submit" :disabled="data.razon_social == ''" class="button is-primary">Activar demo</button>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      data: {
        razon_social: 'ACME SA'
      }
    }
  },
  methods: {
    submit: function () {
      this.startLoading()
      this.$http
        .request({
          method: 'post',
          url: '/start-demo',
          data: this.data
        })
        .then(response => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: 'La demo ha sido creada correctamente',
            type: 'is-success'
          })
          this.$router.push({ name: 'inicio' })
        })
        .catch(error => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: 'Error inesperado',
            type: 'is-danger',
            indefinite: true
          })
          console.log(error)
        })
    }
  }
}
</script>

<style>
</style>
