<template>
  <section class="box">
    <div class="card-content" v-if="!isLoading">
      <h1 class="title is-3">Filtrado por Aspecto</h1>
      <h1 class="subtitle is-5">Seleccione el aspecto</h1>
      <section v-if="aspectos.length">
        <div class="field">
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="aspectoSeleccionado">
                <option :value="null">- Elija el aspecto -</option>
                <option :value="aspecto" v-for="aspecto in aspectos" :key="aspecto.id">{{aspecto.nombre}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="buttons is-centered">
          <button class="button is-dark is-outlined" @click="$parent.close()">
            Cancelar
          </button>
          <button class="button is-primary" @click="agregarCondicion" :disabled="!aspectoSeleccionado">
            <i class="fas fa-plus"></i>&nbsp;Agregar cóndición
          </button>
        </div>
      </section>
       <div v-else>
        <div class="notification is-light">
          <i class="fas fa-info-circle"></i>&nbsp;No existen aspectos
        </div>
        <div class="buttons is-centered">
          <button class="button is-dark is-outlined" @click="$parent.close()">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false,
      aspectoSeleccionado: null
    }
  },
  mounted: function () {
    this.isLoading = true
    this.startLoading()
    Promise.all([
      this.$http.get(`/organizacion/${this.organizacion}/aspecto`),
      this.$http.get('/aspecto')
    ])
      .then(responses => {
        this.$store.commit('organizacion/aspectos', responses[0].data)
        this.$store.commit('organizacion/aspectosGlobales', responses[1].data)
      })
      .catch(err => {
        this.stopLoading()
        console.error(err)
        this.$buefy.snackbar.open({
          message: 'El servidor respondio con un error.',
          type: 'is-danger'
        })
      })
      .finally(() => {
        this.stopLoading()
        this.isLoading = false
      })
  },
  methods: {
    agregarCondicion: function () {
      if (!this.aspectoSeleccionado) return
      const aspecto = {
        name: this.aspectoSeleccionado.nombre,
        id: this.aspectoSeleccionado.id
      }
      this.$emit('addAspectoQuery', aspecto)
      this.$parent.close()
    }
  },
  computed: {
    aspectos: function () {
      return this.$store.getters['organizacion/allAspectos']
    },
    organizacion: function () {
      return this.$store.getters.getUser.organization
    }
  },
  watch: {
    aspectoSeleccionado: function (newVal, oldVal) {
      this.atributoSeleccionado = null
      this.operacionSeleccionada = null
    },
    atributoSeleccionado: function (newVal, oldVal) {
      this.operacionSeleccionada = null
    },
    operacionSeleccionada: function (newVal, oldVal) {
      this.value = null
    }
  }
}
</script>

<style>
</style>
