<template>
  <div class="box">
    <h1 class="subtitle is-5">Administrar organización</h1>
      <h1 class="title is-1">Usuarios</h1>
      <hr>
    <nav class="pagination is-small" role="navigation" aria-label="pagination">
      <ul class="pagination-list user-query">
        <li class="is-hidden-touch">Filtros:</li>
        <li>
          <input type="text" v-model="queryName" class="input text-input-query" placeholder="Nombre..." />
        </li>
        <li>
          <div class="select is-small">
            <select v-model="queryRole">
              <option :value="null">Todos los roles</option>
              <option :value="rol.id" v-for="(rol, i) in roles" :key="`rol${i}`">{{rol.descripcion}}</option>
            </select>
          </div>
        </li>
        <li>
          <div class="select is-small">
            <select v-model="queryArea">
              <option :value="null">Todas las areas</option>
              <option :value="area.id" v-for="(area, i) in areas" :key="`rol${i}`">{{area.nombre}}</option>
            </select>
          </div>
        </li>
        <li v-if="$store.getters.hasAnyRole(['lyris'])">
          <button class="button is-link is-small" @click="openModalCargaMasiva"><i class="fas fa-upload"></i>&nbsp;Carga masiva</button>
        </li>
      </ul>
    </nav>
    <hr />
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Usuario</th>
          <th>CUIL</th>
          <th class="has-text-centered"><i class="fas fa-cloud"></i> Cert.</th>
          <th>Email</th>
          <th width="100" class="has-text-right">Acciones</th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr>
          <td colspan="4" class="has-text-centered">
            <loading-spin></loading-spin>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr :id="usuario.id" v-for="usuario in usuarios" :key="usuario.id">
          <td>
            <i v-if="usuario.acceso_habilitado" class="fas fa-user fa-fw"></i>
            <i v-else class="fas fa-user-slash fa-fw"></i>
            {{usuario.apellido}}, {{usuario.nombre}}
          </td>
          <td>{{usuario.cuil}}</td>
          <td class="has-text-centered"><router-link :to="{ name: 'usuarioBackupCertificado', params: { idOrganization: idOrganization, idUser: usuario.id }}">{{usuario.keystore ? 'Si' : 'No'}}</router-link></td>
          <td>{{usuario.email}}</td>
          <td>
            <div class="field has-addons is-pulled-right">
              <p class="control">
                <router-link
                  :to="{ name: 'usuarioInfo', params: { idOrganization: idOrganization, idUser: usuario.id }}"
                  class="button is-small"
                >
                  <span class="icon is-small">
                    <i class="fas fa-fw fa-eye fa-lg"></i>
                  </span>
                </router-link>
              </p>
              <p class="control">
                <router-link
                  :to="{ name: 'usuarioEditar', params: { idOrganization: idOrganization, idUser: usuario.id }}"
                  class="button is-small"
                >
                  <span class="icon is-small">
                    <i class="fas fa-fw fa-edit fa-lg"></i>
                  </span>
                </router-link>
              </p>
              <p class="control">
                <button
                  class="button is-small is-danger is-outlined"
                  :disabled="!usuario.acceso_habilitado"
                  @click="openModalBajaUsuario(usuario)">
                  <span class="icon is-small">
                    <i class="fas fa-fw fa-user-minus fa-lg"></i>
                  </span>
                </button>
              </p>
            </div>
          </td>
        </tr>
        <tr v-if="usuarios.length==0">
          <td class="has-text-centered" colspan="4">
            <section class="section is-mono">
              <span class="tag is-rounded is-medium">
                <i class="fas fa-info fa-fw"></i>&nbsp;Sin usuarios para mostrar
              </span>
            </section>
          </td>
        </tr>
      </tbody>
    </table>
    <paginator :resource="url" ref="paginator" @update="updateResource" :loading.sync="loading" />

  </div>
</template>

<script>
import Paginator from '@/components/utils/Paginator'
import LoadingSpin from '@/components/utils/LoadingSpin'
import ModalCargaMasiva from '@/components/organizacion/usuario/ModalCargaMasiva'
import ModalBajaUsuario from '@/components/organizacion/usuario/ModalBajaUsuario'

export default {
  props: ['idOrganization', 'idUser'],
  components: {
    Paginator,
    LoadingSpin
  },
  data () {
    return {
      usuarios: [],
      loading: true,
      areas: [],
      roles: [],
      queryRole: null,
      queryArea: null,
      queryName: null,
      fetchingQueryParameters: true
    }
  },
  mounted: function () {
    this.fetchQueryParameters()
  },
  computed: {
    queryObject: function () {
      const obj = {}
      if (this.queryRole != null) {
        obj.rol = this.queryRole
      }
      if (this.queryArea != null) {
        obj.area = this.queryArea
      }
      if (this.queryName != null) {
        obj.s = this.queryName
      }
      return obj
    },
    url: function () {
      const queryString = Object.keys(this.queryObject)
        .map(i => {
          return i + '=' + this.queryObject[i]
        })
        .join('&')
      if (queryString.length) { return '/organizacion/' + this.idOrganization + '/usuario?' + queryString }
      return '/organizacion/' + this.idOrganization + '/usuario'
    }
  },
  methods: {
    updateResource (data) {
      this.usuarios = data
    },
    fetchQueryParameters: function () {
      this.fetchingQueryParameters = true
      Promise.all([
        this.$http.get(`organizacion/${this.idOrganization}/rol`),
        this.$http.get(`organizacion/${this.idOrganization}/area?size=100`)
      ])
        .then(responses => {
          this.roles = responses[0].data
          this.areas = responses[1].data.data
        })
        .catch(error => {
          console.log(error)
          this.$buefy.snackbar.open({
            message: 'Error en recuperar los roles y areas de la organización',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.fetchingQueryParameters = false
        })
    },
    openModalCargaMasiva: function () {
      if (!this.$store.getters.hasAnyRole(['lyris'])) {
        return false
      }
      this.$buefy.modal.open({
        parent: this,
        component: ModalCargaMasiva,
        events: {
          reload: (resource, file) => {
            this.loading = true
            this.$refs.paginator.fetchData()
          }
        },
        props: {
          idOrganization: this.idOrganization
        }
      })
    },
    openModalBajaUsuario: function (usuario) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalBajaUsuario,
        width: 720,
        props: {
          userData: usuario
        },
        events: {
          refresh: () => {
            this.loading = true
            this.$refs.paginator.fetchData()
          }
        }
      })
    }
  },
  watch: {
    queryArea: function (oldVal, newVal) {
      this.loading = true
      this.$refs.paginator.fetchData()
    },
    queryRole: function (oldVal, newVal) {
      this.loading = true
      this.$refs.paginator.fetchData()
    },
    queryName: function (oldVal, newVal) {
      this.loading = true
      this.$refs.paginator.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
.user-query {
  li {
    margin: 0 10px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.text-input-query {
  height: 22px;
  font-size: 0.75rem;
  border-radius: 3px;
  border-left: 1px solid #ccc;
  &:focus {
    border-left: 1px solid #2669a0;
  }
}
</style>
