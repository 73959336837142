<template>
  <div class="card">
    <header class="card-header">
        <p class="card-header-title">Crear carpeta</p>
    </header>
    <section class="card-content">
      <b-notification type="is-warning" :active.sync="isActive" @click="isActive = !isActive">
        {{error}}
      </b-notification>
        <!-- <b-field label="Nombre de la carpeta">
            <b-input
                type="text"
                v-model="nombre"
                required>
            </b-input>
        </b-field> -->
        <div class="field">
          <label for="" class="label">Nombre de la carpeta</label>
          <div class="control">
            <input type="text" v-model="nombre" name="Nombre de la carpeta" class="input" :class="{'is-danger': errors.has('nombre') }" v-validate="{required:true}" />
          </div>
          <span class="help is-danger" v-if="errors.has('Nombre de la carpeta')">{{errors.first('Nombre de la carpeta')}}</span>
        </div>
        <div class="field">
          <label class="label">Heredar permisos</label>
          <p class="is-size-7 has-text-primary mb-3" v-if="forceHerencia"><i class="fas fa-exclamation-triangle"></i>&nbsp;<b>Nota:</b> Los documentos y carpetas de la carpeta {{currentFolder.name}} heredarán los permisos por defecto</p>
          <div class="control">
          <b-switch size="is-small" v-model="permisos" :disabled="forceHerencia">Heredar permisos de la carpeta superior</b-switch>
          </div>

        </div>
    </section>
    <footer class="card-footer">
        <a class="card-footer-item" type="button" @click="$parent.close()">Cancelar</a>
        <a class="card-footer-item has-text-weight-bold is-uppercase" @click.prevent="submit()">Crear</a>
    </footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      nombre: null,
      permisos: true,
      isActive: false,
      error: ''
    }
  },
  computed: {
    currentFolder: function () {
      return this.$store.state.repository.currentFolder
    },
    forceHerencia: function () {
      return this.currentFolder.permisos_bloqueados
    }
  },
  methods: {
    submit: function () {
      const data = {
        nombre: this.nombre,
        origen_id: this.currentFolder.id,
        tipo_documento_id: 'carpeta',
        permisos_heredados: this.permisos
      }
      this.$http.request({
        method: 'post',
        url: '/documento',
        data: data
      })
        .then(response => {
          console.log(response.data)
          this.$router.push({ name: 'repositorio', params: { idFolder: response.data.documento.id } })
          this.$parent.close()
          this.$buefy.snackbar.open({
            message: 'Carpeta creada exitosamente.',
            type: 'is-success'
          })
        })
        .catch(error => {
          this.isActive = true
          this.error = 'Ya existe una carpeta con el nombre "' + this.nombre + '"'
          console.log(error)
        })
    }
  }
}
</script>
